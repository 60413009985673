import React from "react";


const BaseTitleChart = (props) => {
  return (<h3 className={`${props.custom ? props.custom : ""} 
                        ${props.extra ? props.extra : ""} `}
                        {...props}>
    {props.children}
  </h3 >);
};

export default BaseTitleChart;