import React from "react";
import BaseSelectButton from "../../sisu/button/BaseSelectButton.sisu";

export default function NeoSelectButton(props) {
  return (
    <>
      <BaseSelectButton {...props} />
    </>
  );
}
