import React, { useEffect, useState } from 'react';
import BaseInputChips from '../../sisu/form/BaseInputChip.sisu';


const NeoInputChip = (props) => {
  const [style, setStyle] = useState("")

  useEffect(() => {
    let mount = true
    if (mount) {
      if (props.error)
        setStyle(`${props.className} p-invalid p-mr-2`);
      else
        setStyle(props.className)
    }
    return () => {
      mount = false
    }

  }, [props.error, props.className])


  return (<>
    <div className={`p-field p-col ${props.extra ? props.extra : ""} ${props.col ? `p-col-${props.col}` : `p-col-12`} ${props.md ? `p-md-${props.md}` : ""} ${props.sm ? `p-sm-${props.sm}` : ""}`}>

      <span className="p-float-label">
        <BaseInputChips {...props} className={style} onKeyDown={(e) => { console.log(e) }} />
        <label htmlFor={props.id}>{props.label}</label>
        {
          props.error
            ? <small className="p-invalid p-d-block">
              {props.error}
            </small>
            : null
        }
        {props.children}
      </span>
    </div>
  </>);
};

export default NeoInputChip;
