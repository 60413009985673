/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import useInput from "hooks/useInput";
import NeoButtonMain from "design/design_components/neo/button/NeoButtonMain.base";
import NeoDialog from "design/design_components/neo/overlay/NeoDialog.base";
import NeoDropdown from "design/design_components/neo/form/NeoDropdown.base";
import NeoGridContainer from "design/design_components/neo/layout/NeoGridContainer.base";
import NeoColumn from "design/design_components/neo/layout/NeoColumn.base";
import NeoDataThird from "design/design_components/neo/data/NeoDataThird.base";
import NeoInnerSubtitle from "design/design_components/neo/title/NeoInnerSubtitle.base";
import NeoDataMain from "design/design_components/neo/data/NeoDataMain.base";
import NeoInputNumber from "design/design_components/neo/form/NeoInputNumber.base";
import { convierteAMoneda, capitalizar, formateaANumeroLocal } from "consts/generales";
import { useComponent } from 'hooks';

const FormularioAgregarProductoModal = (props) => {
    const componente = useComponent();
    const [producto, setProducto] = useInput();
    const [cantidad, setCantidad] = useInput();
    const [precio, setPrecio] = useInput();
    const [listaProductos, setListaProductos] = componente.useCustomState();
    const [precioUnitario, setPrecioUnitario] = componente.useCustomState();
    const [precioTotal, setPrecioTotal] = componente.useCustomState();
    const [errores, setErrores] = componente.useCustomState({})
    const [evalTiempoRealForm, setEvalTiempoRealForm] = componente.useCustomState(false);
    const [RangoPrecios, setRangoPrecios] = componente.useCustomState();
    const [limiteInferior, setLimiteInferior] = componente.useCustomState();
    const [limiteSuperior, setLimiteSuperior] = componente.useCustomState();

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []);

    const piePaginaModal = (<>
        <NeoButtonMain
            label="Cancelar"
            className="p-button-text"
            onClick={() => props.setMuestraModal(false)}
        />
        <NeoButtonMain
            label="Agregar"
            autoFocus
            onClick={() => manejaEnvio()}
        />
    </>);

    useEffect(() => {
        if (props && props.datosIniciales) {
            setRangoPrecios();
            setPrecioUnitario();
            setPrecioTotal();

            props.datosIniciales.tipoValidacion
                ? setProducto(props.datosIniciales.tipoValidacion)
                : setProducto()

            props.datosIniciales.cantidad
                ? setCantidad(props.datosIniciales.cantidad)
                : setCantidad()
        }
    }, [props.datosIniciales]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(props.autoPrecio) {            
            if (props.preciosProductos && props.preciosProductos[producto]) {
                const rangos = props.preciosProductos[producto];
                const ordenados = rangos.sort((a, b) => {
                    return a.limite_inferior - b.limite_inferior;
                });
                setLimiteInferior(ordenados[0].limite_inferior);
                setLimiteSuperior(ordenados[ordenados.length-1].limite_superior);
                
                if(cantidad < ordenados[0].limite_inferior) {
                    setCantidad(ordenados[0].limite_inferior);
                }
            }
        }
    }, [producto, cantidad])

    // setRangoPrecios(Object.entries(props.preciosProductos).map(registro => {
    //     const [tipoValidacion, rangos] = registro

    //     let rangosComponentes = [];
    //     for (let rango of rangos) {
    //         rangosComponentes.push(
    //             <NeoDataThird
    //                 dato={convierteAMoneda(rango.precio)}
    //                 etiqueta={`de ${rango.limInferior.toLocaleString("es-MX")} a ${rango.limSuperior.toLocaleString("es-MX")}`}
    //                 key={rango.id} />);
    //     }
    //     return (<>
    //         <NeoInnerSubtitle>{capitalizar(tipoValidacion)}</NeoInnerSubtitle>
    //         {rangosComponentes}
    //     </>);
    // }));


    useEffect(() => {
        if (props.preciosProductos) {
            let lista = Object.keys(props.preciosProductos).map(
                e => ({ label: capitalizar(e), value: e })
            )
            setListaProductos(lista);
        }
    }, [props.preciosProductos])


    useEffect(() => {
        if (!producto) {
            return;
        }

        if(props.autoPrecio) {
            if(cantidad < limiteInferior) {
                return;
            }
        }

        if(props.autoPrecio) {
            let rangosComponentes = []

            if (props.preciosProductos && props.preciosProductos[producto]) {
    
                for (let [indice, rango] of props.preciosProductos[producto].entries()) {
                    rangosComponentes.push(
                        <NeoDataThird
                            fact={convierteAMoneda(rango.precio)}
                            label={`de ${formateaANumeroLocal(rango.limite_inferior)} a ${formateaANumeroLocal(rango.limite_superior)}`}
                            key={indice} />);
                }
            }

            setRangoPrecios(() => {
                return (<>
                    <NeoInnerSubtitle>
                        Rango de precios por cantidad a comprar:
                    </NeoInnerSubtitle>
                    {rangosComponentes}
                </>);
            });

            if (cantidad) {
                let productos = props.preciosProductos[producto]
                let rango;
    
                productos.forEach((linea) => {
                    if (cantidad >= linea.limite_inferior) {
                        rango = linea;
                    }
                });
    
                if(rango) {
                    let total = Number(rango.precio) * cantidad
                    setPrecioUnitario(Number(rango.precio));
                    setPrecioTotal(total);
                }
            }
        } else {
            if(cantidad && precio > 0) {
                let total = Number(precio) * cantidad;
                setPrecioUnitario(Number(precio));
                setPrecioTotal(total);
            }
        }

    }, [producto, cantidad, precio])


    // Ejecuta evaluación del formulario en tiempo real
    useEffect(() => {
        if (evalTiempoRealForm) {
            evaluaFormulario();
        }
    }, [producto, cantidad]); // eslint-disable-line react-hooks/exhaustive-deps


    const evaluaFormulario = () => {
        let esValido = true;

        if (producto && producto.length) {
            esValido = esValido && true;
            setErrores((valor) => {
                delete valor.producto
                return valor
            });
        } else {
            esValido = false;
            setErrores((valor) => {
                return {
                    ...valor,
                    producto: "Debes elegir un producto"
                }
            });
        }

        if (cantidad >= 1) {
            esValido = esValido && true;
            setErrores((valor) => {
                delete valor.cantidad
                return valor
            });
        } else {
            esValido = false;
            setErrores((valor) => {
                return {
                    ...valor,
                    cantidad: "La cantidad debe ser un número válido"
                }
            });
        }

        if(!props.autoPrecio) {
            if (precio) {
                esValido = esValido && true;
                setErrores((valor) => {
                    delete valor.precio
                    return valor
                });
            } else {
                esValido = false;
                setErrores((valor) => {
                    return {
                        ...valor,
                        precio: "El precio debe ser un número válido"
                    }
                });
            }
        }

        return esValido;
    }

    const manejaEnvio = (event) => {
        setEvalTiempoRealForm(true);
        if (!evaluaFormulario()) {
            return false;
        }

        props
            && props.onSubmit
            && props.onSubmit({
                tipoValidacion: producto,
                cantidad: cantidad,
                precioUnitario: precioUnitario,
                precioTotal: precioTotal
            });

        props.setMuestraModal(false);
        setEvalTiempoRealForm(false);

        setProducto();
        setCantidad(1);
        setRangoPrecios();
        setPrecioUnitario();
        setPrecioTotal();
    };


    return (<>
        <NeoDialog
            header={props.mensaje}
            visible={props.muestraModal}

            footer={piePaginaModal}
            onHide={() => props.setMuestraModal(false)}
        >
            <NeoGridContainer col="12" >
                <NeoDropdown col="12"
                    value={producto}
                    options={listaProductos}
                    onChange={(e) => {
                        if (Array.isArray(props.carrito)) {
                            props.carrito.forEach((elemento) => {
                                if (elemento.tipoValidacion === e.value) {
                                    setCantidad(elemento.cantidad);
                                }
                            })
                        }
                        setProducto(e.value)
                    }}
                    error={errores.producto}
                    label="Tipo de validacion" />

                {producto &&                
                    <NeoInputNumber col="12"
                        value={cantidad}
                        onValueChange={setCantidad}
                        label="Cantidad"
                        error={errores.cantidad}
                        min={limiteInferior}
                        max={limiteSuperior}
                    />
                }



                <NeoColumn col="6" >
                    {props.autoPrecio &&
                        <NeoDataMain
                            fact={
                                precioUnitario
                                    ? convierteAMoneda(precioUnitario)
                                    : "-"
                            }
                            label="Precio por validación" />
                    }

                    {!props.autoPrecio &&
                        <NeoInputNumber 
                            value={precio}
                            mode="currency" currency="MXN" locale="es-MX"
                            onValueChange={setPrecio}
                            label="Precio por validación"
                            minFractionDigits={1}
                            maxFractionDigits={4}
                            min={0}
                            max={1000}
                            col="12"
                            error={errores.precio}
                        />
                    }
                </NeoColumn>

                <NeoColumn col="6" >
                    <NeoDataMain
                        fact={precioTotal
                            ? `$${formateaANumeroLocal(precioTotal)}`
                            : "-"
                        }
                        label="Precio total" />
                </NeoColumn>

                {/* {props.autoPrecio &&
                    <NeoColumn col="12">
                        {RangoPrecios || "Seleccionar un producto para ver precios"}
                    </NeoColumn>
                } */}

            </NeoGridContainer>

        </NeoDialog>

    </>)
}

export default FormularioAgregarProductoModal;
