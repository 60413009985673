import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import useUsuario from "hooks/useUsuario";
import Inicio from "components/views/Inicio";
import Loading from "components/views/Loading";
import RUTAS from 'components/router/Rutas.json';

const Acceso = (_props) => {
    const history = useHistory();
    const usuario = useUsuario();

    useEffect(() => {
        if(usuario.estaLogueado()) {
            // Valida si es la primera vez que estan iniciando sesión en el sistema
            if(usuario.data.loggins === 1) {
                const from = history?.location?.state?.from;
                const url = `${from?.pathname}${from?.search}`;

                if (url.startsWith('/clientes') && usuario.esCliente()) {
                    history.push(url);
                } else if (url.startsWith('/administracion') && usuario.esEmpleado()) {
                    history.push(url);
                } else {
                    const rutaDashboard = usuario.obtieneRutaDashboard();
                    if(rutaDashboard === null && !Boolean(_props.path)) {
                        history.push(RUTAS.clientes.tablero);
                    } else {
                        history.push(rutaDashboard);
                    }
                }
            } else {
                history.push(usuario.obtieneRutaDashboard());
            }
        }

    }, [usuario.persona]) // eslint-disable-line react-hooks/exhaustive-deps

    return (<>
        {usuario.data.loading 
            ? <Loading />
            : <Inicio {..._props} />
        }
        </>);
};

export default Acceso;