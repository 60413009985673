import React from "react";
import { Card } from 'primereact/card';


const BaseCardMain = (props) => {
  return (<>
    <Card
    className={`${props.custom ? props.custom : ""} 
    ${props.extra ? props.extra : ""} 
    ${props.col ? `p-col-${props.col}` : `p-col-12`} 
    ${props.md ? `p-md-${props.md}` : ""} 
    ${props.sm ? `p-sm-${props.sm}` : ""}`} >
      {props.children}
    </Card>
  </>);
};

export default BaseCardMain;