import React from "react";

const BaseGridContainer = (props) => {
  const clases = () => {
    const clases = [];
    if(props.custom) clases.push(props.custom);
    if(props.extra) clases.push(props.extra);
    if(props.col) clases.push(`p-col-${props.col}`);
    if(props.xs) clases.push(`p-xs-${props.xs}`);
    if(props.sm) clases.push(`p-sm-${props.sm}`);
    if(props.md) clases.push(`p-md-${props.md}`);
    if(props.lg) clases.push(`p-lg-${props.lg}`);
    return clases.join(" ");
  }

  const subClases = () => {
    const clases = [];
    if(props.subCustom) clases.push(props.subCustom);
    if(props.subExtra) clases.push(props.subExtra);
    return clases.join(" ");
  }

  const className = clases();
  const subClassName = subClases();
  return (<div className={className}>

    <div className={subClassName}>

      {props.children}

    </div>

  </div>);
};

export default BaseGridContainer;