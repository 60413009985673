import React from "react";


const BaseInnerTitle = (props) => {
  return (<h2 
    className={`${props.custom ? props.custom : ""} 
                ${props.extra ? props.extra : ""} `} 
                {...props}>
      {props.children}
      </h2>);
};

export default BaseInnerTitle;