/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, Fragment } from "react";
import { useComponent, useUsuario } from 'hooks';
import { useHistory } from "react-router-dom";
import NeoNavBarSecondaryClient from "design/design_components/neo/menu/NeoNavBarSecondaryClient.base";
import NeoHeaderData from "design/design_components/neo/data/NeoHeaderData.base";
import NeoColumn from "design/design_components/neo/layout/NeoColumn.base";
import NeoFlexContainer from "design/design_components/neo/layout/NeoFlexContainer.base";
import NeoButtonOutlined from "design/design_components/neo/button/NeoButtonOutlined.base";
import NeoTooltip_icon from "design/design_components/neo/overlay/NeoTooltip_icon.base";
import RUTAS from "components/router/Rutas.json"
import { capitalizar, formateaANumeroLocal } from "../../consts/generales";

const NavBarSecundarioCliente = (props) => {
    const usuario = useUsuario();
    const persona = usuario.persona;
    const componente = useComponent();
    const [irAComprarValidaciones, setIrAComprarValidaciones] = componente.useCustomState(false);
    let historial = useHistory();
    const [saldosYProductos, setSaldosYProductos] = componente.useCustomState([]);

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []);

    useEffect(() => {
        obtenerValidaciones();
    }, [persona.cliente?.saldo])

    useEffect(() => {
        if (irAComprarValidaciones) {
            historial.push(RUTAS.clientes.comprarValidaciones)
            setIrAComprarValidaciones(false);
        }
    }, [irAComprarValidaciones]);


    const obtenerValidaciones = () => {
        const productos = [];

        for (let validacion of persona.cliente.saldo) {
            const esSobregiro = validacion?.sobregiroUsado > 0;

            productos.push(
                <Fragment key={validacion.id}>
                    <NeoHeaderData
                        label={formateaANumeroLocal(validacion.cantidad)}
                        subLabel={`Disponibles ${capitalizar(validacion.tipoValidacion)}`}
                        key={`header-${validacion.id}`}
                    />

                    {esSobregiro &&
                        <>
                            <NeoTooltip_icon
                                text="Validaciones excedentes"
                                icon="fas fa-exclamation-triangle excedentes"
                                key={`tooltip-${validacion.id}`}
                            />
                            <NeoHeaderData
                                label={formateaANumeroLocal(validacion.sobregiroUsado)}
                                subLabel={`Excedentes ${capitalizar(validacion.tipoValidacion)}`}
                                key={`header-${validacion.id}`}
                                className="sobregiro"
                            />


                        </>
                    }
                </Fragment>
            );
        }

        setSaldosYProductos(productos);
    };


    return (
        <NeoNavBarSecondaryClient>
            <NeoFlexContainer extra="" col="auto" sm="" md="" ai="center">
                <span className="title-navbar">
                    Validaciones
                </span>

                {saldosYProductos}

            </NeoFlexContainer>
            {props.botonAdquirirValidaciones &&
                <NeoColumn
                    extra="header-btn-container "
                >
                    <NeoButtonOutlined
                        alternative="secondary"
                        label="Adquirir validaciones"
                        onClick={() => setIrAComprarValidaciones(true)}
                    />
                </NeoColumn>
            }

        </NeoNavBarSecondaryClient>
    )
};


export default NavBarSecundarioCliente;