import { TabPanel } from 'primereact/tabview';

const BaseTabPanel = (props) => {
    return (
        <TabPanel {...props} 
        className={`${props.custom ? props.custom : ""} ${props.extra ? props.extra : ""}`}>
            {props.children}
        </TabPanel>
    );
}

export default BaseTabPanel;