import React from "react";
import BaseNavBarSecondaryClient from "../../sisu/menu/BaseNavBarSecondaryClient.sisu";

const NeoNavBarSecondaryClient = (props) => {
  
  return (<BaseNavBarSecondaryClient custom="p-mt-0 p-grid navbar-secundario" {...props}/>)
};


export default NeoNavBarSecondaryClient;
