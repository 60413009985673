import { cloneElement } from "react";
import { Route, Redirect } from 'react-router-dom';

const isRedirect = (props) => {
    switch (typeof props.redirect) {
        case 'function':
            return props.redirect();
        case 'boolean':
            return props.redirect;
        case 'object':
            return Object.entries(props.redirect).length !== 0;
        default:
            break;
    }

    let isRedirect = false;
    if (Array.isArray(props.redirect)) {
        props.redirect.forEach(verify => {
            if(typeof verify === 'function') {
                if (verify()) {
                    isRedirect = true;
                }
            } else if(verify) {
                isRedirect = true;
            }
        });
    }

    return isRedirect;
}

const toPath = (props) => {
    switch (typeof props.toPath) {
        case 'function':
            return props.toPath();
        case 'string':
            return props.toPath;
        default:
            return '/';
    }
}

const RuterRender = (props) => {
    return (
        <Route exact={props.exact} path={props.path} render={(routeProps) => {
            if (Boolean(isRedirect(props)) === props.redirectValue) {
                return <Redirect to={{ pathname: toPath(props), state: { from: routeProps.location } }} />;
            } else if (props.children || props.component) {
                let component;

                if(props.children) {
                    component = cloneElement(props.children, {...routeProps});
                } else if(props.component) {
                    component = <props.component {...routeProps} />;
                }
                
                if(typeof props.onLoad === 'function') {
                    props.onLoad(routeProps);
                }

                return component;
            }

            return null;
        }} />
    );
}

RuterRender.defaultProps = {
    exact: true,
    redirectValue: true
}

export default RuterRender;