import { createContext, useEffect } from 'react';
import { useComponent } from 'hooks';

const StateInit = {
    lista: []
}

const NotificacionesContext = createContext([StateInit, () => {}]);

export const NotificacionesProvider = ({ children }) => {
    const componente = useComponent({
        data: StateInit
    });

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []);

    return (
        <NotificacionesContext.Provider value={componente.data}>
            {children}
        </NotificacionesContext.Provider>
    );
}

export default NotificacionesContext;