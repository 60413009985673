import NeoCardPrincipal from "./NeoCardPrincipal.base";
import NeoInnerBlankCardMainText from "../table/NeoInnerBlankCardMainText.base";
import NeoInnerBlankCardSecondText from "../table/NeoInnerBlankCardSecondText.base";


const NeoCardMessage = (props) => {
    return (<>
        <NeoCardPrincipal {...props}>

            <NeoInnerBlankCardMainText>
                {props.title}
            </NeoInnerBlankCardMainText>

            <NeoInnerBlankCardSecondText>
                {props.message}
            </NeoInnerBlankCardSecondText>

        </NeoCardPrincipal>
    </>);
};


export default NeoCardMessage;