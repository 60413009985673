import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';
import "firebase/storage";


firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
});

// export const functions = firebase.app().functions('us-east1');
export const functions = firebase.app().functions();
export const storage = firebase.storage();


if (process.env.REACT_APP_USE_FIREBASE_EMULATORS === "true"
        && process.env.NODE_ENV === "development") {

    firebase.auth().useEmulator("http://localhost:9099");
    storage.useEmulator("localhost", 9199);
    functions.useEmulator("localhost", 5001);
    storage.useEmulator("localhost", 9199);

}
