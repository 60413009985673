import { useState } from 'react';

const useInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    e ? e.hasOwnProperty("target")
          ? setValue(e.target.value)
          : setValue(e)
      : setValue("")
  }

  return [value, handleChange];
}

export default useInput;