import { useState, useEffect, useRef } from 'react';
import { generarID } from 'consts/generales';

const useComponent = (propsComponent) => {
    const ref  = useRef(0);
    ref.current = ref.current + 1;
    
    const  [requests, setRequests] = useState([]);
    const [calls, setCalls] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    let isMount = true;

    const desmontar = () => {
        isMount = false;
    }

    useEffect(() => {
        if(Array.isArray(requests)) {
            if(requests.length === 0) {
                setIsLoading(false);
            } else {
                setIsLoading(true);
            }
        }
    }, [requests])

    const useCustomState = (valorInicial) => {
        const [valor, setValor] = useState(valorInicial);

        const customSetValor = (valor) => {
            if(isMount) {
                setValor(valor);
            }
        }

        return [
            valor,
            customSetValor
        ]
    }

    const useAsync = (fn, options = {}) => {
        if (options?.name) {
            fnCalls({
                name: options.name,
                state: 'init'
            });
        }

        if (options?.delay === undefined) {
            options.delay = 0;
        }

        return async function () {
            const id = generarID();

            setRequests(prevRequests => [...prevRequests, {
                id
            }]);

            const data = {}

            if (options?.name) {
                data.name = options?.name;

                fnCalls({
                    name: options.name,
                    state: 'sending'
                });
            }

            if (Object.keys(arguments).length !== 0) {
                data.props = arguments;
            }

            if (options?.logger) {
                fnLogger({
                    data,
                    state: 'sending'
                });
            }

            try {
                const result = await new Promise((resolve, reject) => {
                    setTimeout(async () => {
                        try {
                            const result = await fn.apply(this, data.props);
                            resolve(result);
                        } catch (error) {
                            reject(error);
                        }
                    }, options.delay);
                });

                if (options?.logger) {
                    fnLogger({
                        data,
                        state: 'completed',
                        response: result
                    });
                }

                if (options?.name) {
                    fnCalls({
                        name: options.name,
                        state: 'completed',
                        response: result
                    });
                }

                return result;
            } catch (error) {
                if (options?.logger) {
                    fnLogger({
                        data,
                        state: 'failed',
                        error
                    });
                }

                if (options?.name) {
                    fnCalls({
                        name: options.name,
                        state: 'failed'
                    });
                }

                throw error;
            } finally {
                setRequests(prevRequests => prevRequests.filter(request => request.id !== id));
            }
        };
    }

    const fnCalls = (params) => {
        if(params.state === 'init') {
            const call = calls[params.name];
            if(call === undefined) {
                setCalls(prevCalls => {
                    prevCalls[params.name] = {
                        name: params.name,
                        state: params.state,
                        calls: 0
                    }
                    return prevCalls;
                })
            }
        } else {
            setCalls(prevCalls => {
                prevCalls[params.name].state = params.state;

                if(params.state === 'sending') {
                    prevCalls[params.name].calls++;
                }

                if(params.state === 'completed') {
                    prevCalls[params.name].response = params.response;
                }

                return prevCalls;
            })
        }
    }

    const fnLogger = (params) => {
        const obj = {
            ...params.data,
            date: new Date().toLocaleString(),
            state: params.state
        };

        if(params.response) {
            obj.response = params.response;
        }

        if(params.error) {
            obj.error = params.error;
        }

        console.log(obj);
    }

    const dataToProxy = typeof propsComponent?.data === 'object' ? propsComponent?.data : {};

    const createProxyObject = (data) => {
        return new Proxy(data, {
            get: (target, name) => {
                return target[name];
            },
            set: (obj, prop, value) => {
                obj[prop] = value;
    
                setData((oldData) => {
                    const newData = {...oldData};
                    newData[prop] = value;
                    return createProxyObject(newData);
                })
    
                return true;
            }
        })
    }

    const [data, setData] = useCustomState(createProxyObject(dataToProxy));

    return {
        useCustomState,
        useAsync,
        calls,
        isLoading,
        desmontar,
        renders: ref.current,
        data
    }
}

export default useComponent;