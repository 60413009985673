import React, { useState } from "react";
import BaseInputSwitch from "../../sisu/form/BaseInputSwitch.sisu";

export default function NeoInputSwitch(props) {
  return (
    <>
      <BaseInputSwitch {...props} custom=''/>
    </>
  );
}
