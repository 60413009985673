/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import NeoCard from "design/design_components/neo/panel/NeoCard.base";
import NeoInnerTitle from "design/design_components/neo/title/NeoInnerTitle.base";
import NeoGridContainer from "design/design_components/neo/layout/NeoGridContainer.base";
import { COLORES, capitalizar } from "../../consts/generales";
import NeoDataSection from "design/design_components/neo/data/NeoDataSection.base";
import NeoDataMain from "design/design_components/neo/data/NeoDataMain.base";
import { useComponent } from 'hooks';
import NeoPieChartCustom from "design/design_components/neo/chart/NeoPieChartCustom.base";



const CardResumenResultados = (props) => {
    const componente = useComponent();
    const [valorFiltro, setValorFiltro] = componente.useCustomState();
    const [detallePrincipal, setDetallePrincipal] = componente.useCustomState();
    const [graficas, setGraficas] = componente.useCustomState();
    const [detalleAhorro, setDetalleAhorro] = componente.useCustomState();
    const [graficaAhorro, setGraficaAhorro] = componente.useCustomState();
    const [detallesSecundarios, setDetallesSecundarios] = componente.useCustomState();

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []);

    useEffect(() => {
        if(props.detallePrincipal)
            setDetallePrincipal(props.detallePrincipal);
        
        if(Array.isArray(props.graficas)) {
            setGraficas(props.graficas.map((grafica) => {
                if(Array.isArray(grafica?.datos)) {
                    grafica.datos = grafica.datos.map((dato) => {
                        dato.nombre = capitalizar(dato.nombre);
                        return dato;
                    });
                }

                return grafica;
            }))
        }

        if(props.detalleAhorro)
            setDetalleAhorro(props.detalleAhorro);
        if(props.graficaAhorro)
            setGraficaAhorro(props.graficaAhorro);
        if(props.detallesSecundarios)
            setDetallesSecundarios(props.detallesSecundarios);
    }, [props])

    // Los valores disponibles para el DropDown
    useEffect(() => {
        if (!valorFiltro)
            return

        props.filtro
            && props.filtro.onChange
            && props.filtro.onChange(valorFiltro.valor)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valorFiltro])


    //Carga la segunda opción del DropDown por omisión
    useEffect(() => {
        if (!props?.filtro?.opciones) {
            return;
        }
        let seleccion = props.filtro?.seleccionIncial
            ? props.filtro.seleccionIncial
            : 0

        setValorFiltro(props.filtro.opciones[seleccion]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.filtro?.opciones])


    return (<>
        <NeoCard
            valign="p-ai-start p-ai-center p-jc-center"
            extra={props.extra}>

            <NeoInnerTitle extra="primero">
                {props.titulo}
            </NeoInnerTitle>


            {
                detallePrincipal?.length
                    ? detallePrincipal.map((e, index) => (
                        <NeoGridContainer
                            col={e?.cols ? e.cols : 6}
                            extra="p-col"
                            key={index}
                        >
                            <NeoDataMain
                                fact={e.datos !== undefined
                                    ? e.datos.toLocaleString('es-MX')
                                    : "0"}
                                label={e.titulo}
                                extra="p-text-center"
                            />
                        </NeoGridContainer >))
                    : null
            }
            {
                graficas?.length
                    ? graficas.map((e, indice) => {
                        if (e?.datos?.length) {
                            return <NeoGridContainer
                                extra="p-col p-ai-center"
                                col={e.cols
                                    ? e.cols
                                    : 6
                                }
                                key={indice}>
                                <NeoPieChartCustom
                                    key={Math.random()}
                                    data={e.datos}
                                    dataKey="cantidad"
                                    formatNumber={true}
                                    contractNumber={true}
                                    dataName="nombre"
                                    title={e.titulo}
                                    colors={e.colores || COLORES} 
                                    colorsLabelByKey="porNombre"
                                    colorsCharByKey="porCodigo" />
                            </NeoGridContainer>
                        }
                        return null;
                    })
                    : null
            }

            {
                detalleAhorro?.length
                    ? detalleAhorro.map((e, index) => (
                        <NeoGridContainer col="12"
                            extra="p-col" key={index}>
                            <NeoDataMain
                                fact={e.datos
                                    ? e.datos.toLocaleString('es-MX')
                                    : "0"}
                                label={e.titulo}
                                extra="p-text-center"
                            />
                        </NeoGridContainer >
                    ))
                    : null
            }

            {
                graficaAhorro?.length
                    ? graficaAhorro.map((e, indice) => {
                        if (e?.datos?.length) {
                            return <NeoGridContainer
                                extra="p-col p-ai-center"
                                col={props?.graficaAhorroAncho
                                    ? props?.graficaAhorroAncho
                                    : 6
                                }
                                key={indice}>
                                <NeoPieChartCustom
                                    key={Math.random()}
                                    data={e.datos}
                                    dataKey="cantidad"
                                    formatNumber={true}
                                    contractNumber={true}
                                    dataName="nombre"
                                    title={e.titulo}
                                    colors={e.colores || COLORES} 
                                    colorsLabelByKey="porNombre"
                                    colorsCharByKey="porCodigo" />
                            </NeoGridContainer>
                        }
                        return null;
                    })
                    : null
            }

            {
                detallesSecundarios && props.detallesSecundarios.length
                    ? detallesSecundarios.map((e, index) => (
                        <NeoGridContainer extra="p-col" key={index}>
                            <NeoDataSection
                                data={e.datos}
                                label="nombre"
                                fact="cantidad"
                                title={e.titulo}
                                key={index} />
                        </NeoGridContainer >
                    ))
                    : null
            }


        </NeoCard>

    </>)
}


export default CardResumenResultados;