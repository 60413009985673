import { functions } from "config/firebase";
import { useComponent } from "hooks";
import { downloadBase64File } from "consts/generales";
import useMensajeGlobal from "hooks/useMensajeGlobal";
import NeoButtonMain from "design/design_components/neo/button/NeoButtonMain.base";
import NeoTooltip from "design/design_components/neo/overlay/NeoTooltip.base";

const BotonDescargaValidaciones = (props) => {
    const componente = useComponent();
    const obtieneReporte = componente.useAsync(functions.httpsCallable('validacionArchivo-generaReporteResultados'));
    const { lazyParams } = props;
    const despliegaMensaje = useMensajeGlobal();

    const generaReporte = () => {
        const resolvedOptions = Intl.DateTimeFormat().resolvedOptions();
        const timeZone = resolvedOptions.timeZone

        obtieneReporte({
            lazyParams: JSON.stringify(lazyParams),
            clienteId: props.clienteId,
            timeZone
        }).then((resp) => {
            if (resp?.data?.base64) {
                downloadBase64File(
                    "application/vnd.openxmlformats",
                    resp.data.base64,
                    "reporteResultados.xlsx"
                )
            }
        }).catch((error) => {
            console.log("Error: ", error);
            despliegaMensaje({
                severity: "error",
                summary: "Error:",
                detail: error.message,
            });
        })
    };

    return (<>
        <NeoTooltip target=".descarga-resultado" tooltipOptions={{ position: 'left' }} />
        <NeoButtonMain
            data-pr-position="left"
            data-pr-tooltip="Genera un archivo con las validaciones realizadas. Límite últimos 5000 registros."
            extra="descarga-resultado"
            loading={componente.isLoading}
            label="Descarga resultados unitarios"
            onClick={generaReporte}
        />
    </>)
}

export default BotonDescargaValidaciones;