import React from "react";

const BaseInnerBlankCardSecondText = (props) => {
  return (<p {...props} 
  className={`${props.custom ? props.custom : ""}
            ${props.extra ? props.extra : ""}`}>
    {props.children}
  </p>);
};

export default BaseInnerBlankCardSecondText;