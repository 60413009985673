/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";
import { useComponent } from 'hooks';
import { useRouteMatch, Redirect } from "react-router-dom";
import NeoTable from "design/design_components/neo/table/NeoTable.base";
import { capitalizar, aDivisaLocal, aCadenaFechaConFormatoLocal, mapearConceptoOrden } from "../../consts/generales";
import { DateTime } from 'luxon';
import NeoTableColumn from 'design/design_components/neo/table/NeoTableColumn.base';
import NeoCalendar from "design/design_components/neo/form/NeoCalendar.base";
import NeoMultiSelect from "design/design_components/neo/form/NeoMultiSelect.base";
import NeoColumn from 'design/design_components/neo/layout/NeoColumn.base';
import { ORDEN } from 'consts/db.const';

const TablaOrdenes = (props) => {
    const componente = useComponent();
    const { url } = useRouteMatch();
    const [datosIniciales, setDatosIniciales] = componente.useCustomState();
    const [tiposOrdenes, setTiposOrdenes] = componente.useCustomState([]);
    const [tiposPagoEstado, setTiposPagoEstado] = componente.useCustomState([]);
    const [tiposValidaciones, setTiposValidaciones] = componente.useCustomState([]);
    const [tipoOrdenSeleccionada, setTipoOrdenSeleccionada] = componente.useCustomState([]);
    const [tipoPagoEstadoSeleccionado, setTipoPagoEstadoSeleccionado] = componente.useCustomState([]);
    const [tipoValidacionSeleccionada, setTipoValidacionSeleccionada] = componente.useCustomState([]);
    const [fechaCreacionSeleccionada, setFechaCreacionSeleccionada] = componente.useCustomState();
    const [fechaExpiracionSeleccionada, setFechaExpiracionSeleccionada] = componente.useCustomState();
    const dt = useRef(null);
    const [irADetalles, setIrADetalles] = componente.useCustomState(false);

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []);

    useEffect(() => {
        if (Array.isArray(props.datos)) {
            const datos = props.datos.map((orden) => {
                orden.orden_tipo_nombre = mapearConceptoOrden(orden.orden_tipo_nombre);
                return orden;
            })
            setDatosIniciales(datos);
        }

        if (Array.isArray(props.tiposOrdenes)) {
            const tiposOrdenes = props.tiposOrdenes.map((tipo_orden) => {
                tipo_orden.nombre = mapearConceptoOrden(tipo_orden.nombre);
                return tipo_orden;
            })
            setTiposOrdenes(tiposOrdenes);
        }

        if (Array.isArray(props.tiposPagoEstado)) {
            setTiposPagoEstado(props.tiposPagoEstado);
        }

        if (Array.isArray(props.tiposValidaciones)) {
            setTiposValidaciones(props.tiposValidaciones);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    const idBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title"># Orden</span>
            {rowData.id}
        </>);
    }

    const fechaCreacionBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Fecha creación</span>
            {rowData.fecha_creacion !== null &&
                aCadenaFechaConFormatoLocal(rowData.fecha_creacion)
            }
        </>);
    }

    const fechaExpiracionBodyTemplate = (rowData) => {
        let fecha_expiracion = rowData.fecha_expiracion;

        if((rowData.orden_tipo_id === ORDEN.TIPO.AJUSTE_MANUAL.ID && rowData?.productos_cantidad <= 0) ||
            (rowData.orden_tipo_id === ORDEN.TIPO.VALIDACIONES_SOBREGIRO.ID)
        ) {
            fecha_expiracion = '';
        }

        return (<>
            <span className="p-column-title">Fecha vencimiento</span>
            {rowData.fecha_creacion !== null &&
                aCadenaFechaConFormatoLocal(fecha_expiracion)
            }
        </>);
    }

    const conceptoBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Concepto</span>
            {mapearConceptoOrden(rowData?.orden_tipo_nombre)}
        </>);
    }

    const productoBodyTemplate = (rowData) => {
        let productos = rowData.productos.split(', ');
        productos = productos.map((producto) => {
            return capitalizar(producto);
        });

        return (<>
            <span className="p-column-title">Validaciones</span>
            {productos.join(', ')}
        </>);
    }

    const cantidadBodyTemplate = (rowData) => {
        let productos = rowData.productos?.split(', ');
        if(Array.isArray(productos)) {
            const cantidades = rowData.productos_cantidades?.split(', ');
            const saldos = rowData.productos_saldos?.split(', ');
            productos = productos.map((producto, index) => {
                return {
                    nombre: producto,
                    cantidad: Array.isArray(cantidades) ? cantidades[index] : null,
                    saldo: Array.isArray(saldos) ? saldos[index] : null
                }
            })
        }

        return (<>
            <span className="p-column-title">Restante / Total</span>
            {productos.map((producto, index) => {
                return (<>
                    <NeoColumn col="12">
                        {producto.saldo &&
                            <span>{aDivisaLocal(producto.saldo)} / </span>
                        }

                        {aDivisaLocal(producto.cantidad)}
                        {` `}
                        {producto.nombre === 'correo' 
                            ? <i className="pi pi-envelope"></i>
                            : <i className="pi pi-mobile"></i>
                        }
                    </NeoColumn>
                </>)
            })}
        </>);
    }

    const estatusBodyTemplate = (rowData) => {
        let status = rowData.orden_pago_estado_nombre;

        if(rowData.orden_pago_estado_id === ORDEN.PAGO.PAGADA.ID && (
                rowData.orden_tipo_id === ORDEN.TIPO.VALIDACIONES_INICIALES.ID ||
                rowData.orden_tipo_id === ORDEN.TIPO.AJUSTE_MANUAL.ID
            )
        ) {
            status = 'Aplicado';
        }
        return (<>
            <span className="p-column-title">Estado</span>
            {capitalizar(status)}
        </>);
    }

    const onRowSelect = (event) => {
        if (event?.originalEvent?.target?.nodeName === 'TD') {
            setIrADetalles(event.data.id)
        }
    }

    const conceptoFilterTemplate = (option) => {
        const nombre = option?.nombre || option || 'Todos';

        return (
            <div className="p-multiselect-representative-option">
                <span className="image-text">
                    {nombre}
                </span>
            </div>
        );
    };

    const onCambiaConcepto = (e) => {
        if (e.value.length === 0) {
            e.value = null;
        }
        dt.current.filter(e.value, 'orden_tipo_nombre', 'in');
        setTipoOrdenSeleccionada(e.value);
    }

    const filtroConcepto = <NeoMultiSelect
        value={tipoOrdenSeleccionada}
        options={tiposOrdenes}
        itemTemplate={conceptoFilterTemplate}
        selectedItemTemplate={conceptoFilterTemplate}
        onChange={onCambiaConcepto}
        optionLabel="nombre"
        optionValue="nombre"
        placeholder="Todos"
        className="p-column-filter" />;

    const productoFilterTemplate = (option) => {
        const nombre = option?.nombre || option || 'Todos';

        return (
            <div className="p-multiselect-representative-option">
                <span className="image-text">
                    {option === undefined ?
                        nombre
                        :
                        `Validaciones de ${capitalizar(nombre)}`
                    }
                </span>
            </div>
        );
    };

    const onCambiaProducto = (e) => {
        if (e.value.length === 0) {
            e.value = null;
        }
        dt.current.filter(e.value, 'productos', 'in');
        setTipoValidacionSeleccionada(e.value);
    }

    const filtroProducto = <NeoMultiSelect
        value={tipoValidacionSeleccionada}
        options={tiposValidaciones}
        itemTemplate={productoFilterTemplate}
        selectedItemTemplate={productoFilterTemplate}
        onChange={onCambiaProducto}
        optionLabel="nombre"
        optionValue="nombre"
        placeholder="Todos"
        className="p-column-filter" />;

    const pagoEstadoFilterTemplate = (option) => {
        const nombre = option?.nombre || option || 'Todos';

        return (
            <div className="p-multiselect-representative-option">
                <span className="image-text">
                    {nombre}
                </span>
            </div>
        );
    };

    const onCambiaPagoEstado = (e) => {
        if (e.value.length === 0) {
            e.value = null;
        }
        dt.current.filter(e.value, 'orden_pago_estado_nombre', 'in');
        setTipoPagoEstadoSeleccionado(e.value);
    }

    const filtroPagoEstado = <NeoMultiSelect
        value={tipoPagoEstadoSeleccionado}
        options={tiposPagoEstado}
        itemTemplate={pagoEstadoFilterTemplate}
        selectedItemTemplate={pagoEstadoFilterTemplate}
        onChange={onCambiaPagoEstado}
        optionLabel="nombre"
        optionValue="nombre"
        placeholder="Todos"
        className="p-column-filter" />;

    const onCambiaFecha = (e, field, updater) => {
        // 2021-10-28
        let fecha;
        if (e.value) {
            fecha = DateTime.fromJSDate(e.value).toFormat('yyyy-LL-dd');
            console.log(fecha);
        }

        dt.current.filter(fecha, field, 'equals');
        updater(e.value);
    }

    const filtroFechaCreacion = <NeoCalendar
        value={fechaCreacionSeleccionada}
        onChange={(e) => onCambiaFecha(e, 'fecha_creacion', setFechaCreacionSeleccionada)}
        dateFormat="dd MM yy"
        placeholder="Fecha"
        mask="99/99/9999"
    />

    const filtroFechaExpiracion = <NeoCalendar
        value={fechaExpiracionSeleccionada}
        onChange={(e) => onCambiaFecha(e, 'fecha_expiracion', setFechaExpiracionSeleccionada)}
        dateFormat="dd MM yy"
        placeholder="Fecha"
        mask="99/99/9999"
    />

    return (<>
        <NeoTable selectionMode="single" value={datosIniciales}
            paginator rows={10} removableSort ref={dt}
            emptyMessage="No hay ordenes registradas"
            onRowSelect={onRowSelect} >

            <NeoTableColumn field="id" header="# Orden" filter sortable
                body={idBodyTemplate}
                filterMatchMode="contains" />

            <NeoTableColumn
                field="fecha_creacion"
                header="Creación"
                filter
                sortable
                filterElement={filtroFechaCreacion}
                body={fechaCreacionBodyTemplate}
            />

            <NeoTableColumn
                field="orden_tipo_nombre"
                header="Concepto"
                filter
                sortable
                filterElement={filtroConcepto}
                body={conceptoBodyTemplate}
            />

            <NeoTableColumn
                field="productos"
                header="Validaciones"
                filter
                sortable
                filterElement={filtroProducto}
                body={productoBodyTemplate}
            />
            <NeoTableColumn
                field="productos_cantidad"
                header="Restante / Total"
                filter
                sortable
                body={cantidadBodyTemplate}
                filterMatchMode="contains"
            />

            <NeoTableColumn
                field="orden_pago_estado_nombre"
                header="Estado"
                filter
                sortable
                filterElement={filtroPagoEstado}
                body={estatusBodyTemplate}
            />

            <NeoTableColumn
                field="fecha_expiracion"
                header="Expiración"
                filter
                sortable
                filterElement={filtroFechaExpiracion}
                body={fechaExpiracionBodyTemplate}
            />

        </NeoTable>
        {
            irADetalles
                ? <Redirect push to={{
                    pathname: `${url}/detalle`,
                    state: { id: irADetalles }
                }} />
                : null
        }
    </>)
}


export default TablaOrdenes;