import React from "react";
import BaseTooltip from "../../sisu/overlay/BaseTooltip.sisu";


const NeoTooltip_icon = (props) => {

  const {
    extra,
    icon,
    text,
    position,
    ...otherProps
  } = props;

  return (<>
    <BaseTooltip target=".custom-target-icon" {...otherProps} />
    <div className={`tooltip-icon p-field p-col p-col-fixed ${extra}`} >
        <i className={`${icon} custom-target-icon`}
            data-pr-tooltip={text}
            data-pr-position={position}
        />
    </div>
</>);
};

NeoTooltip_icon.defaultProps = {
extra: '',
icon: 'pi pi-question-circle',
text: '',
position: 'left'
}

export default NeoTooltip_icon;
