import React from "react";
import BaseSpinner from "../../sisu/overlay/BaseSpinner.sisu";

const NeoSpinner = (props) => {

  return (<div className={`progress-spinner ${props.custom ? props.custom : ""} ${props.extra ? props.extra : ""}`}>
    <BaseSpinner />
  </div>);
};

export default NeoSpinner;