import { useEffect } from "react";
import useProgressSpinner from "hooks/useProgressSpinner"
import useMensajeGlobal from "hooks/useMensajeGlobal";
import firebase from "firebase/app";
import EstadisticasGraficas from "components/common/EstadisticasGraficas";
import NeoCardPrincipal from "design/design_components/neo/panel/NeoCardPrincipal.base";
import NeoInnerBlankCardMainText from "design/design_components/neo/table/NeoInnerBlankCardMainText.base";
import NeoInnerBlankCardSecondText from "design/design_components/neo/table/NeoInnerBlankCardSecondText.base";
import { useComponent } from "hooks";
import NeoMessageLoading from "design/design_components/neo/messages/NeoMessageLoading.base";
import useCliente from "hooks/useCliente";

const ClientesTablero = () => {
    const componente = useComponent();
    const cliente = useCliente();
    const [, setSpinnerCargando] = useProgressSpinner(false)
    const despliegaMensaje = useMensajeGlobal();
    const [mensualPorMetodo, setMensualPorMetodo] = componente.useCustomState();
    const [mensualPorTipo, setMensualPorTipo] = componente.useCustomState();
    const [mensualPorResultado, setMensualPorResultado] = componente.useCustomState([]);
    const [mensualPorPrevalidacion, setMensualPorPrevalidacion] = componente.useCustomState([]);
    const [mensualTotal, setMensualTotal] = componente.useCustomState();
    const [mensualAhorro, setMensualAhorro] = componente.useCustomState();
    const [datosAnuales, setDatosAnuales] = componente.useCustomState();
    const [promedioAnual, setPromedioAnual] = componente.useCustomState();
    const [totalAnual, setTotalAnual] = componente.useCustomState();
    const [mesesDisponibles, setMesesDisponibles] = componente.useCustomState();

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        obtieneMesesDisponibles();
        obtieneResumenAnual();

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const resumen = componente.useAsync(firebase.functions().httpsCallable('resumenMensualPorCliente'));
    const resumenAnual = componente.useAsync(firebase.functions().httpsCallable('resumenAnualPorCliente'));

    const obtieneMesesDisponibles = () => {
        const fechasConDatosValidaciones = firebase.functions().httpsCallable('fechasConDatosValidaciones');

        fechasConDatosValidaciones({ clienteId: cliente.id }).then((resp) => {
            if (Array.isArray(resp.data) && resp.data.length) {
                resp.data.unshift({
                    valor: 'anual',
                    nombre: 'Últimos 12 meses'
                });
            }
            setMesesDisponibles(resp.data)
        }).catch((error) => {
            console.log("Error: ", error);
            despliegaMensaje({
                severity: "error",
                summary: "Error:",
                detail: error.message,
            });
        })
    }

    const obtieneResumenPorMes = (fecha) => {
        resumen({ mes: fecha, clienteId: cliente.id }).then((resp) => {
            setMensualPorMetodo(resp.data.listaPorMetodo)
            setMensualPorTipo(resp.data.listaPorTipo)
            setMensualPorResultado(resp.data.listaPorResultado)
            setMensualPorPrevalidacion(resp.data.listaPorPrevalidacion)

            let total = 0;
            resp.data.listaPorTipo.forEach((item) => total += item.cantidad);
            setMensualTotal(total);

            let prevalidadas = 0;
            resp.data.listaPorPrevalidacion.forEach((item) => prevalidadas += item.cantidad);
            setMensualAhorro(prevalidadas);

            // setMensualPorMetodo(resp.data.listaPorMetodo)
            // setMensualPorTipo(resp.data.listaPorTipo)

            // let total = 0;
            // resp.data.listaPorTipo.forEach((item) => total += item.cantidad)
            // setMensualTotal(total)
        }).catch((error) => {
            console.log("Error: ", error);
            despliegaMensaje({
                severity: "error",
                summary: "Error:",
                detail: error.message,
            });
        });
    }

    const obtieneResumenAnual = () => {
        resumenAnual({ clienteId: cliente.id })
            .then((respuesta) => {
                setDatosAnuales(respuesta.data.resumen)
                setPromedioAnual(respuesta.data.promedio)
                setTotalAnual(respuesta.data.total)
            }).catch((error) => {
                console.log("Error: ", error);
                despliegaMensaje({
                    severity: "error",
                    summary: "Error:",
                    detail: error.message,
                });
            });
    }

    return (<>
        {
            mesesDisponibles
                ? mesesDisponibles.length
                    ? <EstadisticasGraficas
                        mesesDisponibles={mesesDisponibles}
                        obtieneResumenPorMes={obtieneResumenPorMes}
                        mensualPorMetodo={mensualPorMetodo}
                        mensualPorTipo={mensualPorTipo}
                        mensualPorResultado={mensualPorResultado}
                        mensualPorPrevalidacion={mensualPorPrevalidacion}
                        mensualTotal={mensualTotal}
                        mensualAhorro={mensualAhorro}
                        datosAnuales={datosAnuales}
                        promedioAnual={promedioAnual}
                        totalAnual={totalAnual}
                        filtroSeleccionInicial={1}
                        isLoading={componente.isLoading}
                    />
                    : <NeoCardPrincipal>
                        <NeoInnerBlankCardMainText>
                            El usuario aún no tiene validaciones
                        </NeoInnerBlankCardMainText>
                        <NeoInnerBlankCardSecondText>
                            Se mostrarán las estadísticas mensuales
                            tan pronto como existan datos.
                        </NeoInnerBlankCardSecondText>
                    </NeoCardPrincipal>
                : <NeoMessageLoading />
        }

    </>);
};


export default ClientesTablero;