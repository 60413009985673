import React from "react";
import BaseButtonMain from "../../sisu/button/BaseButtonMain.sisu"


const NeoButtonMain = (props) => {
    const { subtitle, ...params } = props;
    
    return (<>
        <BaseButtonMain custom="p-button-rounded"  {...params} />
        {subtitle &&
            <span className="small">{subtitle}<p></p></span>
        }
    </>);
};

export default NeoButtonMain;