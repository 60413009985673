import React from "react";
import { Button } from 'primereact/button';


const BaseButtonImage = (props) => {
  return (<>
    <Button className={`
      ${props.custom ? props.custom : ""} 
      ${props.extra ? props.extra : ""}
      ${props.alternative
        ? `p-button-${props.alternative}`
        : "p-button-primary"}`}
      {...props} >
      <img src={props.image} alt={props.label} />
      <span>{props.label}</span>
    </Button>
  </>);
};

export default BaseButtonImage;