import 'design/assets/styles/mnv/style.scss';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';

import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import "./config/firebase"
import Ruter from "./components/router/Ruter"
import { TriggerProvider } from "./components/context/TriggerContext";
import { EstadoGlobalProvider } from "./components/context/EstadoGlobal";
import { UsuarioProvider } from "./components/context/UsuarioContext";
import { NotificacionesProvider } from "./components/context/NotificacionesContext";

const App = () => {
    return (<>
        <TriggerProvider>
            <EstadoGlobalProvider>
                <UsuarioProvider>
                    <NotificacionesProvider>
                        <Ruter />
                    </NotificacionesProvider>
                </UsuarioProvider>
            </EstadoGlobalProvider>
        </TriggerProvider>
    </>);
}

export default App;