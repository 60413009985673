/**
 * Retorna true en caso de encontrar el correo en la lista blanca.
 * @param {Array<import("../FormularioCuentaUsuario").RegistroListaBlanca>} lista 
 * @param {string} correo 
 */
export function correoEstaPresenteEnListaBlanca(lista, correo) {
    return lista.find((registro) => registro.dato_validado === correo) !== undefined;
}

/**
 * Retorna true en caso de encontrar el registro en lista negra.
 * @param {Array<string>} lista 
 * @param {string} registro 
 */
export function registroEstaPresenteEnListaNegra(lista, registro) {
    return lista.find((elemento) => elemento === registro) !== undefined;
}