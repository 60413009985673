import { functions } from "config/firebase";
import { useHistory } from "react-router-dom";
import { useContext } from 'react';
import NotificacionesContext from 'components/context/NotificacionesContext';
import exception from './exception';
import Notificacion from './notificacion';
import { ACCION } from './constantes';

const Notificaciones = () => {
    const state = useContext(NotificacionesContext);
    const history = useHistory();

    const fetch = async () => {
        try {
            const resp = await functions.httpsCallable("notificaciones-listar")();

            if(Array.isArray(resp.data)) {
                resp.data.forEach((notificacion) => {
                    if(!existInLocal(notificacion)) {
                        añadir({
                            id: notificacion.id,
                            titulo: notificacion.titulo,
                            descripcion: notificacion.descripcion,
                            datos: notificacion.datos,
                            codigo: notificacion.codigo,
                            fechaCreacion: notificacion.fechaCreacion,
                            tipo: notificacion.tipo_id,
                            nivel: notificacion.nivel_id,
                            estado: notificacion.estado_id,
                            accion: notificacion.accion_id,
                            onClick: (notificacion) => {
                                if(notificacion.accion === ACCION.LINK) {
                                    if(typeof notificacion.datos === 'object') {
                                        if(notificacion.datos.url) {
                                            if(notificacion.datos.newTab) {
                                                window.open(
                                                    notificacion.datos.url, 
                                                    '_blank'
                                                )
                                            } else {
                                                const notificacionURL = notificacion.datos.url;
                                                const notificacionDomain = new URL(notificacionURL);
                                                const notificacionHostname = notificacionDomain.hostname;
                                                const currentHostname = window.location.hostname;
                                                
                                                if(notificacionHostname === currentHostname) {
                                                    const urlWithoutHost = notificacionDomain.pathname + notificacionDomain.search;
                                                    history.push(urlWithoutHost);
                                                } else {
                                                    window.location.replace(notificacionURL);
                                                }
                                            }
                                        }
                                    }
                                } else if(notificacion.accion === ACCION.CONTACTO) {
                                    if(typeof notificacion.datos === 'object') {
                                        if(notificacion.datos.correo) {
                                            window.location = `mailto:${notificacion.datos.correo}`;
                                        } else if(notificacion.datos.whatsapp) {
                                            const whatsapp = String(notificacion.datos.whatsapp).replace(/[^0-9]+/g, "");
                                            let url = `https://wa.me/${whatsapp}`;

                                            if(notificacion.datos.text) {
                                                url += `?text=${encodeURIComponent(notificacion.datos.text)}`;
                                            }

                                            window.open(url, '_blank');
                                        }
                                    }
                                }
                            }
                        });
                    }
                })
            }
        } catch(error) {
            if(error instanceof exception) {
                console.log(error);
            } else {
                console.error("Error al obtener la lista de notificaciones");
            }
        }
    }

    const añadir = (params) => {
        try {
            const notificacion = new Notificacion(params, {actualizar, eliminar});

            state.lista = [notificacion, ...state.lista];

            return notificacion;
        } catch(error) {
            throw error;
        }
    }

    const listar = (filtros) => {
        let lista = state.lista || [];
        if(typeof filtros === 'object') {
            for (const [key, valor] of Object.entries(filtros)) {
                if(lista.length > 0) {
                    lista = filtrar(lista, key, valor);
                } else {
                    return [];
                }
            }
        }

        return lista;
    }

    const actualizar = (notificacion, property, old, updateDB = true) => {
        state.lista = state.lista.map(item => {
            if(item.codigo === notificacion.codigo) {
                item = notificacion;
            }

            return item;
        });

        if(existInDB(notificacion) && updateDB) {
            switch (property) {
                case 'estado': {
                    const datos = {};
                    datos.id = notificacion.id;
                    datos.estado_id = notificacion.estado;
        
                    functions.httpsCallable("notificaciones-actualizar")(datos)
                    .catch(() => {
                        actualizar(old, property, old, false);
                        console.error("Error al actualizar la notificación");
                    });

                    break;
                }
                default:
                    break;
            }
        }
    }

    const eliminar = (notificacion) => {
        state.lista = state.lista.filter(item => item.codigo !== notificacion.codigo);
    }

    const filtrar = (notificaciones, filtro, valor) => {
        if(Array.isArray(notificaciones)) {
            if(typeof filtro === 'string' && valor !== undefined) {
                return notificaciones.filter(item => item[filtro] === valor);
            }
        }
        return [];
    }

    const clear = () => {
        state.lista = [];
    }

    const existInDB = (notificacion) => {
        return notificacion.id !== null;
    }

    const existInLocal = (notificacion) => {
        return listar({codigo: notificacion.codigo}).length > 0;
    }

    return {
        fetch,
        añadir,
        listar,
        clear,
        ...state
    }
}

export default Notificaciones;