import { useEffect, useRef } from 'react';
import { functions } from "config/firebase";
import useMensajeGlobal from "hooks/useMensajeGlobal";
import useProgressSpinner from "hooks/useProgressSpinner"
import { aCadenaFechaConFormatoLocal } from "consts/generales";
import NeoTitleMain from "design/design_components/neo/title/NeoTitleMain.base";
import NeoTable from "design/design_components/neo/table/NeoTable.base";
import NeoButtonSection from "design/design_components/neo/layout/NeoButtonSection.base";
import NeoGridContainer from "design/design_components/neo/layout/NeoGridContainer.base";
import NeoTableColumn from 'design/design_components/neo/table/NeoTableColumn.base';
import FormularioNombreApiModal from 'components/common/FormularioNombreApiModal';
import Icono from 'design/assets/img/mnv/icons/icon-apis.svg';
import { Redirect, useRouteMatch } from "react-router-dom";
import { DateTime } from 'luxon';
import NeoCalendar from "design/design_components/neo/form/NeoCalendar.base";
import NeoMultiSelect from "design/design_components/neo/form/NeoMultiSelect.base";
import { useComponent } from 'hooks';
import NeoButtonMain from "design/design_components/neo/button/NeoButtonMain.base";

const ApiKeys = () => {
    const componente = useComponent();
    const listaApis = componente.useAsync(functions.httpsCallable('listaApiKey'));
    const [, setSpinnerCargando] = useProgressSpinner(true);
    const despliegaMensaje = useMensajeGlobal();
    const [datos, setDatos] = componente.useCustomState([]);
    const [tipoStatusSeleccionado, setTipoStatusSeleccionado] = componente.useCustomState([]);
    const [fechaSeleccionada, setFechaSeleccionada] = componente.useCustomState();
    const [muestraModal, setMuestraModal] = componente.useCustomState(false);
    let { url } = useRouteMatch();
    const [irADetalle, setIrADetalle] = componente.useCustomState(false);
    const dt = useRef(null);
    const tiposStatus = [{
        valor: 0,
        nombre: 'Inactiva'
    }, {
        valor: 1,
        nombre: 'Activa'
    }
    ]

    useEffect(() => {
        return () => {
            componente.desmontar();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        obtieneListaApiKeys();
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps

    const creaApi = componente.useAsync(functions.httpsCallable('creaApiKey'));

    const obtieneListaApiKeys = () => {
        listaApis().then((respuesta) => {
            if (Array.isArray(respuesta.data)) {
                const datos = respuesta.data.map((elemento) => {
                    elemento.correo = elemento.correo || 0;
                    elemento.telefono = elemento.telefono || 0;
                    elemento.validaciones = elemento.correo + elemento.telefono;

                    return elemento;
                })

                setDatos(datos)
            }
        }).catch((error) => {
            console.log("Al obtener datos: ", error);
            despliegaMensaje({
                severity: "error",
                summary: `Error: ${error.code}`,
                detail: error.message
            });
        }).finally(() => {
            setSpinnerCargando(false);
        });
    };

    const manejaEnviarForm = (props) => {
        creaApi(props.nombre).then((respuesta) => {
            obtieneListaApiKeys();
            despliegaMensaje({
                severity: "success",
                summary: "",
                detail: "Se ha creado la API Key con éxito"
            });
        }).catch((error) => {
            console.log("Al obtener datos: ", error);
            despliegaMensaje({
                severity: "error",
                summary: `Error: ${error.code}`,
                detail: error.message
            });
        });
    };

    const nombreBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Nombre</span>
            {rowData.nombre}
        </>);
    }
    const validacionesBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Total validaciones</span>
            {
                (rowData?.validaciones).toLocaleString("es-MX")}
        </>);
    }
    const correosBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Correos</span>
            {
                rowData.correo
                    ? rowData.correo.toLocaleString("es-MX")
                    : 0
            }
        </>);
    }
    const telefonosBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Teléfonos</span>
            {
                rowData.telefono
                    ? rowData.telefono.toLocaleString("es-MX")
                    : 0
            }
        </>);
    }
    const creacionBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Creación</span>
            {aCadenaFechaConFormatoLocal(rowData.fecha_creacion)}
        </>);
    }
    const estatusBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Estatus</span>
            {rowData.activa ? "Activa" : "Inactiva"}
        </>);
    }

    const onRowSelect = (event) => {
        setIrADetalle(event.data.id)
    }

    const statusFilterTemplate = (option) => {
        let nombre = option?.nombre || option;

        if (Number.isInteger(nombre)) {
            nombre = tiposStatus[nombre].nombre;
        } else if (nombre === undefined) {
            nombre = 'Todos';
        }

        return (
            <div className="p-multiselect-representative-option">
                <span className="image-text">
                    {nombre}
                </span>
            </div>
        );
    };

    const onCambiaStatus = (e) => {
        if (e.value.length === 0) {
            e.value = null;
        }
        dt.current.filter(e.value, 'activa', 'in');
        setTipoStatusSeleccionado(e.value);
    }

    const filtroStatus = <NeoMultiSelect
        value={tipoStatusSeleccionado}
        options={tiposStatus}
        itemTemplate={statusFilterTemplate}
        selectedItemTemplate={statusFilterTemplate}
        onChange={onCambiaStatus}
        optionLabel="nombre"
        optionValue="valor"
        placeholder="Todos"
        className="p-column-filter" />;

    const onCambiaFecha = (e) => {
        let fecha;
        if (e.value) {
            fecha = DateTime.fromJSDate(e.value).toFormat('yyyy-LL-dd');
        }

        dt.current.filter(fecha, 'fecha_creacion', 'equals');
        setFechaSeleccionada(e.value);
    }

    const filtroFecha = <NeoCalendar
        value={fechaSeleccionada}
        onChange={onCambiaFecha}
        dateFormat="dd MM yy" 
        placeholder="Fecha" 
        mask="99/99/9999"
    />

    return (<>
        <NeoGridContainer>
            <NeoTitleMain icon={Icono}
                title="Integración por API" />

            <NeoButtonSection align="right" col="12" md="6"
                extra="p-col-align-center p-flex-sm-row p-flex-column">
                <NeoButtonMain 
                    label="Crear nueva API key"
                    onClick={() => setMuestraModal(true)} 
                    loading={componente.isLoading}
                />
            </NeoButtonSection>
        </NeoGridContainer>

        {
            <NeoTable
                extra="tabla-m"
                selectionMode="single"
                value={datos}
                paginator
                rows={10}
                loading={componente.isLoading}
                removableSort
                ref={dt}
                onRowSelect={onRowSelect}
                emptyMessage="No tienes API's registradas" >
                <NeoTableColumn
                    field="nombre"
                    header="Nombre"
                    filter
                    sortable
                    filterPlaceholder="Filtrar por nombre"
                    body={nombreBodyTemplate}
                    filterMatchMode="contains"
                />

                <NeoTableColumn
                    field="validaciones"
                    header="Validaciones"
                    filter
                    sortable
                    filterPlaceholder="Filtrar por validaciones"
                    body={validacionesBodyTemplate}
                    filterMatchMode="contains"
                />

                <NeoTableColumn
                    field="correo"
                    header="Correos"
                    filter
                    sortable
                    filterPlaceholder="Filtrar por correos"
                    body={correosBodyTemplate}
                    filterMatchMode="contains"
                />

                <NeoTableColumn
                    field="telefono"
                    header="Teléfonos"
                    filter
                    sortable
                    filterPlaceholder="Filtrar por teléfono"
                    body={telefonosBodyTemplate}
                    filterMatchMode="contains"
                />

                <NeoTableColumn
                    field="fecha_creacion"
                    header="Creación"
                    filter
                    sortable
                    filterElement={filtroFecha}
                    body={creacionBodyTemplate}
                />

                <NeoTableColumn
                    field="activa"
                    header="Estado"
                    filter
                    sortable
                    filterElement={filtroStatus}
                    body={estatusBodyTemplate}
                />
            </NeoTable>

        }

        <FormularioNombreApiModal mensaje="Crear nueva llave API"
            onSubmit={manejaEnviarForm}
            muestraModal={muestraModal}
            setMuestraModal={setMuestraModal} />

        {
            irADetalle
                ? <Redirect push to={
                    {
                        pathname: `${url}/detalle`, state: {
                            id: irADetalle
                        }
                    }
                } />
                : null
        }
    </>);

};


export default ApiKeys;