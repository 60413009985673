import React from "react";


const NeoTitleMain = (props) => {
  return (<div {...props} className={`p-ai-center p-col  header-titulo-pagina p-d-flex ${props.extra ? props.extra : ""}`}>
    {
      props.icon
        ?
        <img width="45" src={props.icon} alt={props.alt} />
        :
        null
    }
    <div className="p-d-flex p-flex-column ">
      {
        props.title
          ? <h1 className="p-col-align-center titulo-pagina-principal">{props.title}</h1>
          : null
      }
      {
        props.subtitle
          ? <h2>{props.subtitle}</h2>
          : null
      }</div>

    {props.children}
  </div>);
};

export default NeoTitleMain;