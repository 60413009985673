import React from "react";
import { ConfirmDialog } from 'primereact/confirmdialog';

const BaseDialogConfirm = (props) => {

  return (
    <ConfirmDialog {...props}
    className={`${props.custom ? props.custom : ""} ${props.extra ? props.extra : ""}`}>
      {props.children}
    </ConfirmDialog>
  )
};


export default BaseDialogConfirm;