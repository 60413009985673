import * as object from './object';
import * as app from './app';
import * as utils from './utils';

const web = {
    object,
    app,
    utils,
}

export default web;