export const TIPO = {
    BELL: 1,
    DASHBOARD: 2,
    TOAST: 3,
    EMAIL: 4
}

export const NIVEL = {
    EMERGENCIA: 1,
    MUY_URGENTE: 2,
    URGENTE: 3,
    MENOR_URGENCIA: 4,
    NO_URGENTE: 5
}

export const ESTADO = {
    PENDIENTE: 1,
    VISTA: 2,
    ABIERTA: 3,
    DESCONOCIDO: 4
}

export const ACCION = {
    LINK: 1,
    CONTACTO: 2
}