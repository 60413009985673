/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useRouteMatch, Redirect, useHistory } from "react-router-dom";
import { functions } from "config/firebase";
import useMensajeGlobal from "hooks/useMensajeGlobal";
import useProgressSpinner from "hooks/useProgressSpinner"
import NeoCardPrincipal from "design/design_components/neo/panel/NeoCardPrincipal.base";
import NeoInnerBlankCardMainText from "design/design_components/neo/table/NeoInnerBlankCardMainText.base";
import NeoInnerBlankCardSecondText from "design/design_components/neo/table/NeoInnerBlankCardSecondText.base";
import TablaHistoricoValidaciones from "components/common/TablaHistoricoValidaciones";
import NeoMessageLoading from "design/design_components/neo/messages/NeoMessageLoading.base";
import { useComponent, useTrigger } from 'hooks';

const HistoricoValidaciones = (props) => {
    const componente = useComponent();
    const trigger = useTrigger();

    // Peticiones a functions
    const getHistorial = componente.useAsync(functions.httpsCallable('validacion-historialValidaciones'), {
        name: 'getHistorial'
    });

    let { url } = useRouteMatch();
    const despliegaMensaje = useMensajeGlobal();
    const [cargando, setSpinnerCargando] = useProgressSpinner(true);
    const [historico, setHistorico] = componente.useCustomState([]);
    const [totalRegistros, setTotalRegistros] = componente.useCustomState();
    const [irADetalle, setIrADetalle] = componente.useCustomState(false);
    const {lazyParams, setLazyParams} = props;
    
    let historial = useHistory();

    useEffect(() => {
        if (componente.calls['getHistorial'].calls === 0
        ) {
            setSpinnerCargando(true);
        } else {
            setSpinnerCargando(false);
        }
    }, [historico])

    useEffect(() => {
        if (trigger.evento.nombre === 'nueva validacion unitaria'
        || trigger.evento.nombre === 'nueva validacion archivo') {
            obtieneHistorialValidaciones();
        }
    }, [trigger.evento])

    useEffect(() => {
        if (lazyParams) {
            obtieneHistorialValidaciones();
            historial.replace(url, { lazyParams: lazyParams })
        }
    }, [lazyParams]) // eslint-disable-line react-hooks/exhaustive-deps

    const obtieneHistorialValidaciones = () => {
        if(lazyParams) {
            const resolvedOptions = Intl.DateTimeFormat().resolvedOptions();
            const timeZone = resolvedOptions.timeZone

            getHistorial({
                lazyParams: JSON.stringify(lazyParams),
                clienteId: props.clienteId,
                timeZone
            }).then((resp) => {
                setHistorico(resp.data?.listaResultados)
                setTotalRegistros(resp.data?.totalRegistros)
            }).catch((error) => {
                console.log("Error: ", error);
                despliegaMensaje({
                    severity: "error",
                    summary: "Error:",
                    detail: error.message
                });
            })
        }
    };

    return (<>
        {(componente.isLoading
            && componente.calls['getHistorial']?.calls === 1
            && historico.length === 0)
            ?
                <NeoMessageLoading />
            :
            <>
                {(!componente.isLoading
                    && componente.calls['getHistorial']?.calls === 1
                    && historico.length === 0) ?
                    <NeoCardPrincipal>
                        <NeoInnerBlankCardMainText>
                            Bienvenido a tu espacio de validaciones
                        </NeoInnerBlankCardMainText>
                        <NeoInnerBlankCardSecondText>
                            Empieza a validar tus listas de correos electrónicos o teléfonos por el método que mejor se ajuste a tus necesidades.
                        </NeoInnerBlankCardSecondText>
                    </NeoCardPrincipal>
                    :
                    <>
                        <TablaHistoricoValidaciones
                            totalRegistros={totalRegistros}
                            datos={historico}
                            enlaceADetalles={setIrADetalle}
                            loading={componente.isLoading}
                            lazyParams={lazyParams}
                            setLazyParams={setLazyParams}
                        />
                        {
                            irADetalle &&
                            <Redirect push to={{
                                pathname: `${url}/detalle`,
                                state: { id: irADetalle }
                            }} />
                        }
                    </>
                }
            </>}
    </>);
};


export default HistoricoValidaciones;