/**
 * Establece la información pertinente a cada snippet y regresa un objeto con dichos snippets configurados
 * @param  {object} validacion - con los datos a validar
 * @param {string} validacion.url - Url a la cual se enviara la petición
 * @param {string} validacion.contentType - Tipo de Content-Type a enviar
 * @param {object} validacion.datos - Objeto con los datos a enviar en el json
 * @param {string} validacion.datos.key - Api Key
 * @param {string} validacion.datos.dato - Correo a validar
 * @param {string} validacion.datos.tipo - Tipo de validacion. Solo se permite correo.
 * @return Objeto que contiene como propiedades los snippets
 */
export const cargarSnippets = (validacion) => {
const snippet = {};

/**
 * PHP
 */
snippet.php = {
lenguaje: "php",
snippet:
`# Establece la dirección url
$peticion = curl_init("${validacion.url}");

# Configura los datos a enviar
$datos = json_encode([
    "key" => "${validacion.datos.key}",
    "dato" => "${validacion.datos.dato}",
    "tipo" => "${validacion.datos.tipo}"
]);

# Define el tipo de petición http como post y establece los valores a enviar
curl_setopt($peticion, CURLOPT_POSTFIELDS, $datos);

# Añade las cabeceras
curl_setopt($peticion, CURLOPT_HTTPHEADER, ["Content-Type: ${validacion.contentType}"]);

# Retorna la respuesta de la petición
curl_setopt($peticion, CURLOPT_RETURNTRANSFER, true);

# Envía la petición
$resultado = curl_exec($peticion);

# Cierra la conexión
curl_close($peticion);

# Muestra el resultado en pantalla
print_r($resultado);`};


/**
 * JavaScript
 */
// snippet.javascript = {
// lenguaje: "javascript",
// snippet:
// `// Configura los datos a enviar
// const datos = {
//     key: "${validacion.datos.key}",
//     dato: "${validacion.datos.dato}",
//     tipo: "${validacion.datos.tipo}"
// };

// // Envía la petición
// fetch("${validacion.url}", {
//     method: "POST",
//     body: JSON.stringify(datos),
//     headers: {
//         "Content-Type": "${validacion.contentType}"
//     }
// })
// // Muestra el resultado en consola
// .then(peticion => console.log(peticion))

// // Muestra si hay errores en consola
// .catch(error => console.error("Error:", error))`};

/**
 * Node
 */
snippet.node = {
lenguaje: "node",
snippet:
`// Importa la libreria axios
const axios = require("axios");

// Envía la petición
axios.post("${validacion.url}", {
    key: "${validacion.datos.key}",
    dato: "${validacion.datos.dato}",
    tipo: "${validacion.datos.tipo}"
}, {
    headers: {
        "Content-Type": "${validacion.contentType}"
    }
})
// Muestra el resultado en consola
.then(peticion => console.log(peticion))

// Muestra si hay errores en consola
.catch(error => console.error("Error:", error))`};

/**
 * Shell Curl
 */
snippet.curl = {
lenguaje: "shell",
snippet: `curl -X POST -H "Content-Type: ${validacion.contentType}" -d '{"key": "${validacion.datos.key}", "dato": "${validacion.datos.dato}", "tipo":"${validacion.datos.tipo}"}' ${validacion.url}`
};

return snippet;
};