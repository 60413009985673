import { DateTime } from 'luxon';
import { Address4, Address6 } from 'ip-address';
const locale = "es-MX"
const divisa = "MXN"
export const BD_TABLA_TIPO_RESULTADO_VALIDACION = {
    valido: { nombre: "válido", id: 1 },
    invalido: { nombre: "inválido", id: 2 },
    riesgoso: { nombre: "riesgoso", id: 3 },
    desconocido: { nombre: "desconocido", id: 4 }
}

export const COLORES = {

    porNombre: ["secondary-color-2", "main-color-2", "yellow2", "gray"],
    porCodigo: ["#17468D", "#FF9B35", "#fdd850", "#BAC3C3"]
};


export const aCadenaFechaConFormatoLocal = (fechaOriginal, formato = "d LLLL yyyy") => {
    if (!fechaOriginal) {
        return "";
    };

    let fecha = DateTime.fromISO(fechaOriginal);
    fecha = fecha.toFormat(formato, { locale });
    return capitalizar(fecha);
}

export const aCadenaFechaConFormatoLocalYHora = (fechaOriginal) => {
    if (!fechaOriginal) {
        return "";
    }
    const fecha = DateTime.fromISO(fechaOriginal)
    return fecha.toFormat('d LLLL yyyy hh:mm a', { locale })
}

export const convierteAMoneda = (cadena, digitos = 3) => {
    const numero = Number(cadena).toLocaleString(
        locale, {
        style: 'currency',
        currency: divisa,
        maximumSignificantDigits: digitos
    }
    );
    return numero;
}

export const formateaANumeroLocal = (dato) => {
    return Number(dato).toLocaleString(locale)
}

export const capitalizar = (palabra) => {
    let upper = true
    let nueva = ""

    if (!palabra)
        return

    palabra = palabrasAcentuadas(palabra)
    for (let i = 0; i < palabra.length; i++) {
        // Note that you can also check for all kinds of spaces  with
        // str[i].match(/\s/)
        if (palabra[i] === " ") {
            // upper = true
            nueva += palabra[i]
            continue
        }
        //   nueva += upper ? palabra[i].toUpperCase() : palabra[i].toLowerCase()
        nueva += upper ? palabra[i].toUpperCase() : palabra[i];
        upper = false;
    }

    return nueva
};

export const palabrasAcentuadas = (original) => {
    const palabras = [
        { original: "telefono", nueva: "teléfono" },
        { original: "telefonos", nueva: "teléfonos" }
    ]

    for (let i = 0; i < palabras.length; i++) {
        if (palabras[i].original === original)
            return palabras[i].nueva;
    }

    return original
};

/**
 * Agrupa los objetos de un array a partir de una propiedad
 * @param  {array} datos - Array con los objetos
 * @param {string} propiedad - Propiedad por la que los objetos se agruparan
 * @return object Devuelve un objeto que tiene como propiedades los valores de la propiedad enviada como parametro
 */
export const agrupaPor = (datos, propiedad) => {
    if (Array.isArray(datos)) {
        return datos.reduce((acc, objeto) => {
            const key = objeto[propiedad];
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(objeto);
            return acc;
        }, {});
    }
    return {};
}

/**
 * Convierte un valor a divisa local
 * @param  {string|number} valor - Valor a convertir
 * @return Valor convertido a divisa local
 */
export const aDivisaLocal = (valor) => {
    try {
        valor = String(valor).replace(/[^0-9.-]/g, "");
        return valor === ""
            ? valor
            : Number(valor).toLocaleString("es-MX", { currency: divisa });
    } catch (error) {
        console.error(error);
        throw error;
    }
}

/**
 * Valida si una dirección ip es tipo IPV4 o IPV6
 * @param  {string} ip - Ip a validar
 * @return Objeto con el tipo de IP, en caso de no ser una dirección IP regresa null
 */
export const obtieneTipoIp = (ip) => {
    try {
        new Address4(ip);
        return {
            nombre: 'IPV4'
        };
    } catch {
        try {
            new Address6(ip);
            return {
                nombre: 'IPV6'
            };
        } catch {
            return null;
        }
    }
}


export const downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
}

/**
 * Genera un ID
 * @return String Id
 */
export const generarID = () => {
    const ID_LENGTH = 36;
    const START_LETTERS_ASCII = 97;
    const ALPHABET_LENGTH = 26;

    return [...new Array(ID_LENGTH)]
        .map(() => String.fromCharCode(START_LETTERS_ASCII + Math.random() * ALPHABET_LENGTH))
        .join('');
}

/**
 * Inserta un script tag en el documento
 * @param  {string} src - Url del script
 * @param  {function} onLoad - Función que se eejcutara al cargarse el script
 * @return Objeto tipo script
 */
export const mountScript = (src, onLoad) => {
    const isLoaded = () => {
        return document.querySelector(`script[src='${src}']`);
    }

    const scriptLoaded = isLoaded();
    if (scriptLoaded) {
        return scriptLoaded;
    }

    const script = document.createElement('script');
    script.type = 'text/javascript';
    if (typeof onLoad === 'function') {
        script.onload = onLoad;
    }
    script.src = src;
    script.async = true;
    document.body.appendChild(script);
    return script;
}

/**
 * Elimina un script tag en el documento
 * @param  {script} script - Script a remover
 */
export const unmountScript = (script) => {
    if (script.nodeName === 'SCRIPT') {
        document.body.removeChild(script);
    }
}

export const contraerNumero = (numero, precision = 1) => {
    const abbrev = ['K', 'M', 'B'];
    let base = Math.floor(Math.log(Math.abs(numero)) / Math.log(1000));
    const suffix = abbrev[Math.min(2, base - 1)];
    base = abbrev.indexOf(suffix) + 1;

    const round = (numero, precision) => {
        const prec = Math.pow(10, precision);
        return Math.round(numero * prec) / prec;
    }

    return suffix ? round(numero / Math.pow(1000, base), precision) + suffix : '' + numero;
}

export const cache = {
    KEY: 'Cache',
    get: (field) => {
        const appCache = localStorage.getItem(cache.KEY);
        if (!appCache) {
            return undefined;
        } else {
            const data = JSON.parse(appCache);
            return data[field];
        }
    },
    set: (field, data) => {
        const appCache = localStorage.getItem(cache.KEY);
        if (!appCache) {
            const cached = {};
            cached[field] = data;
            localStorage.setItem(cache.KEY, JSON.stringify(cached));
        } else {
            const cached = JSON.parse(appCache);
            cached[field] = data;
            localStorage.setItem(cache.KEY, JSON.stringify(cached));
        }
    },
    clear: (field) => {
        const appCache = localStorage.getItem(cache.KEY);
        if (appCache) {
            const cached = JSON.parse(appCache);
            delete cached[field];
            localStorage.setItem(cache.KEY, JSON.stringify(cached));
        }
    }
}

export const abrirCorreo = (correo) => {
    if (correo) {
        window.location = `mailto:${correo}`;
    }
};

export const abrirWhatsApp = (whatsapp, texto) => {
    const soloNumeros = whatsapp.replace(/[^0-9]+/g, "");
    if (whatsapp) {
        window.open(`https://wa.me/${soloNumeros}?text=${encodeURIComponent(texto)}`, '_blank');
    }
};

export const abrirTelefono = (telefono) => {
    const soloNumeros = telefono.replace(/[^0-9]+/g, "");
    if (telefono) {
        window.open(`tel:${soloNumeros}`, '_blank');
    }
};

export const abrirUrl = (url) => {
    if (url) {
        window.open(url, '_blank');
    }
};

export const mapearConceptoOrden = (concepto) => {
    switch (concepto) {
        case 'Contrato':
            return 'Pago mensual';
        case 'Paquete general':
            return 'Bloque de validaciones';
        case 'Paquete especial':
            return 'Bloque de validaciones especial';
        default:
            return concepto;
    }
}