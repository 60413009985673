import { useState, useEffect, useContext } from 'react';
import { EstadoGlobal } from "components/context/EstadoGlobal";


const useProgressSpinner = (estadoInicial) => {
    const [, setEstadoGlobal] = useContext(EstadoGlobal);
    const [cargando, setCargando] = useState(estadoInicial);

    useEffect(() => {
        if (cargando !== null && cargando !== undefined)
            setEstadoGlobal({cargando: cargando});

    }, [cargando]) // eslint-disable-line react-hooks/exhaustive-deps

    return [cargando, setCargando];
}

export default useProgressSpinner;
