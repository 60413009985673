import { useEffect } from "react";
import { functions } from "config/firebase";
import useProgressSpinner from "hooks/useProgressSpinner"
import NeoTitleSecond from "design/design_components/neo/title/NeoTitleSecond.base";
import NeoButtonSection from "design/design_components/neo/layout/NeoButtonSection.base";
import useMensajeGlobal from "hooks/useMensajeGlobal";
import NeoGridContainer from "design/design_components/neo/layout/NeoGridContainer.base";
import TablaDetalleOrden from "components/common/TablaDetalleOrden";
import NeoButtonMain from "design/design_components/neo/button/NeoButtonMain.base";
import DetalleOrden from "components/common/DetalleOrden";
import ModalPagoManual from "components/common/ModalPagoManual";
import ModalOrdenActualizar from "components/common/ModalOrdenActualizar";
import Icono from 'design/assets/img/mnv/icons/icon-contracts.svg';
import { useComponent } from "hooks";
import useCliente from "hooks/useCliente";
import { ORDEN } from "consts/db.const";

const ClientesOrdenDetalle = (props) => {
    const componente = useComponent({
        data: {
            mostrarModalOrdenActualizar: false
        }
    });
    const cliente = useCliente();
    const marcarComoPagadaManual = componente.useAsync(functions.httpsCallable('ordenes-marcarComoPagadaManual'));
    const obtenerDetallesOrden = componente.useAsync(functions.httpsCallable('ordenes-listarDetalles'));
    const ordenActualizar = componente.useAsync(functions.httpsCallable('ordenes-actualizar'));
    const [, setSpinnerCargando] = useProgressSpinner(true)
    const despliegaMensaje = useMensajeGlobal();
    const [ordenId, setOrdenId] = componente.useCustomState();
    const [orden, setOrden] = componente.useCustomState({});
    const [mostrarModalPagoManual, setMostrarModalPagoManual] = componente.useCustomState(false);

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        props?.location?.state
            && setOrdenId(props.location.state.id);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        ordenId && obtieneDatos()
    }, [ordenId]) // eslint-disable-line react-hooks/exhaustive-deps

    const obtieneDatos = async () => {
        obtenerDetallesOrden({ clienteId: cliente.id, ordenId })
            .then((respuesta) => {
                setOrden(respuesta.data);
            }).catch((error) => {
                despliegaMensaje({
                    severity: "error",
                    summary: `Error:`,
                    detail: error.message
                });
            }).finally(() => {
                setSpinnerCargando(false);
            });
    }

    const marcarComoPagada = async (data) => {
        setMostrarModalPagoManual(false);
        marcarComoPagadaManual({
            ordenId: ordenId,
            referencia: data.referencia,
            nota: data.nota
        }).then((respuesta) => {
            obtieneDatos();
            cliente.recargar();
            despliegaMensaje({
                severity: "success",
                summary: ``,
                detail: "Orden marcada como pagada"
            });
        }).catch((error) => {
            despliegaMensaje({
                severity: "error",
                summary: `Error:`,
                detail: error.message
            });
        });
    }

    const actualizarOrden = async (data) => {
        componente.data.mostrarModalOrdenActualizar = false
        ordenActualizar(data)
        .then((respuesta) => {
            obtieneDatos();
            despliegaMensaje({
                severity: "success",
                summary: ``,
                detail: "Vigencia actualizada"
            });
        }).catch((error) => {
            despliegaMensaje({
                severity: "error",
                summary: `Error:`,
                detail: error.message
            });
        });
    }

    return (<>
        <NeoGridContainer>
            <NeoTitleSecond icon={Icono} title={`Detalles de la orden #${orden?.id ? orden.id : ''}`} />
            <NeoButtonSection
                align="right"
                col="12"
                md="6"
                extra="p-col-align-center p-flex-sm-row p-flex-column">

                {(orden?.orden_estado_id === ORDEN.ESTADO.PENDIENTE_ACTIVACION.ID
                    || orden?.orden_estado_id === ORDEN.ESTADO.VIGENTE.ID
                ) && (
                    orden.productos_cantidad > 0
                ) &&
                    <NeoButtonMain
                        label="Cambiar vigencia"
                        onClick={() => componente.data.mostrarModalOrdenActualizar = true} />
                }

                {orden?.orden_pago_estado_id === ORDEN.PAGO.PENDIENTE.ID &&
                    <NeoButtonMain
                        label="Marcar como pagada"
                        onClick={() => setMostrarModalPagoManual(true)} />
                }
            </NeoButtonSection>
        </NeoGridContainer>

        <ModalPagoManual
            visible={mostrarModalPagoManual}
            onClickBtnCerrar={() => setMostrarModalPagoManual(false)}
            onEnviarFormulario={marcarComoPagada}
        />

        {componente.data.mostrarModalOrdenActualizar &&
            <ModalOrdenActualizar
                visible={componente.data.mostrarModalOrdenActualizar}
                onHide={() => componente.data.mostrarModalOrdenActualizar = false}
                onEnviarFormulario={actualizarOrden}
                orden={orden}
                header="Cambiar vigencia"
            />
        }

        {orden &&
            <>
                <DetalleOrden
                    verDetalles={true}
                    orden={orden} />

                <TablaDetalleOrden orden={orden} />
            </>
        }

        {orden?.orden_estado_id === 4 &&
            <NeoButtonSection align="right">
                <NeoButtonMain
                    label="Cancelar orden"
                    onClick={() => { }}
                />
            </NeoButtonSection>
        }
    </>);
};


export default ClientesOrdenDetalle;