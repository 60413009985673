import { mountScript } from 'consts/generales';
class Cliengo {
    constructor() {
        this.src = process.env.REACT_APP_FRESHWORKS_URL;
        this.isLoaded = false;
        this.fnOnWait = [];
        this.elementId = 'freshworks-container';

        if(this.src) {
            const _this = this;
            const observer = new MutationObserver((mutations, observer) => {
                if(!_this.isLoaded) {
                    mutations.forEach((mutation) => {
                        if(mutation.type === 'childList') {
                            const node = mutation.addedNodes[0];
                            if (node?.id === _this.elementId) {
                                _this.load();
                            }
                        }
                    });
                }
            });
            
            observer.observe(document, { childList: true, subtree: true });
            
            mountScript(this.src);
        } else {
            console.error('Url freshworks no esta definida en .env');
        }
    }

    load() {
        this.element = {
            container: null,
            frame: null
        };

        const found = document.getElementById('freshworks-container');
        if(found) {
            this.isLoaded = true;
            this.element.container = found;
            this.element.frame = found;
            this.hide();

            const _this = this;
            this.fnOnWait.forEach((execute) => {
                _this[execute.fn]();
            });
        } else {
            console.error(`El elemento ${this.elementId} no fue encontrado en el dom`);
        }
    }

    hide() {
        if(!this.isLoaded) {            
            this.fnOnWait.push({
                fn: 'hide'
            });
            return;
        }

        this.element.frame.style.setProperty('display', 'none', 'important');
    }

    show() {
        if(!this.isLoaded) {
            this.fnOnWait.push({
                fn: 'show'
            });
            return;
        }

        this.element.frame.style.setProperty('display', 'block', 'important');
    }
}

export default new Cliengo();