import { useEffect } from 'react';
import { functions } from "config/firebase";
import { useHistory } from "react-router-dom";
import useMensajeGlobal from "hooks/useMensajeGlobal";
import useProgressSpinner from "hooks/useProgressSpinner"
import NeoButtonSection from "design/design_components/neo/layout/NeoButtonSection.base";
import NeoGridContainer from "design/design_components/neo/layout/NeoGridContainer.base";
import NeoTitleMain from "design/design_components/neo/title/NeoTitleMain.base";
import Icono from 'design/assets/img/mnv/icons/icon-user.svg';
import TablaClientesListado from "components/common/TablaClientesListado";
import NeoDropdown from "design/design_components/neo/form/NeoDropdown.base";
import NeoColumn from "design/design_components/neo/layout/NeoColumn.base";
import { useComponent, useUsuario } from 'hooks';
import useCliente from 'hooks/useCliente';
import RUTAS from "components/router/Rutas.json";
import NeoMessageLoading from "design/design_components/neo/messages/NeoMessageLoading.base";

const Clientes = (props) => {
    const componente = useComponent();
    const usuario = useUsuario();
    const cliente = useCliente();
    const history = useHistory();
    
    // Funciones
    const listadoClientes = componente.useAsync(functions.httpsCallable("listadoClientes"));
    const listadoPlanes = componente.useAsync(functions.httpsCallable("listadoPlanes"));
    const listadoDns = componente.useAsync(functions.httpsCallable("listaDns"));
    const getMesesConDatos = componente.useAsync(functions.httpsCallable('fechasParaResumenMensualGlobal'));


    const [, setSpinnerCargando] = useProgressSpinner(true)
    const despliegaMensaje = useMensajeGlobal();
    const [listaDeClientes, setListaDeClientes] = componente.useCustomState();
    const [listaPlanes, setListaPlanes] = componente.useCustomState([]);
    const [listaDns, setListaDns] = componente.useCustomState();
    const [mesesDisponibles, setMesesDisponibles] = componente.useCustomState();
    const [mesSeleccionado, setMesSeleccionado] = componente.useCustomState();
    const [clickCliente, setClickCliente] = componente.useCustomState(false);

    useEffect(() => {
        if (props?.location?.state?.id) {
            seleccionarCliente(props.location.state.id);
        } else {
            const urlPath = window.location.href;
            var url = new URL(urlPath);
            const id = Number(url.searchParams.get('id'));
            if (id) {
                window.history.replaceState(null, null, window.location.pathname);
                seleccionarCliente(id);
            }
        }
        
        return () => { componente.desmontar(); };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setSpinnerCargando(cliente.data.loading || componente.isLoading);
    }, [cliente.data.loading, componente.isLoading])

    useEffect(() => {
        if(cliente.hayCliente() && clickCliente) {
            history.push(RUTAS.admin.clientes.tablero);
        }
    }, [cliente, clickCliente])

    useEffect(() => {
        obtieneMesesDisponibles();
        obtienePlanes();
        obtieneDns();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!mesSeleccionado) {
            return;
        };

        obtieneClientes(mesSeleccionado.valor);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mesSeleccionado])


    useEffect(() => {
        if (!mesesDisponibles) {
            return;
        };

        if (mesesDisponibles && mesesDisponibles.length > 0) {
            setMesSeleccionado(mesesDisponibles[0])
        }

    }, [mesesDisponibles]) // eslint-disable-line react-hooks/exhaustive-deps

    const obtienePlanes = () => {
        listadoPlanes().then((resp) => {
            setListaPlanes(resp.data);
        }).catch((error) => {
            console.log("Error: ", error);
        });
    }

    const obtieneDns = () => {
        listadoDns().then((resp) => {
            setListaDns(resp.data);
            // datasource.current = resp.data
        }).catch((error) => {
            console.log("Error: ", error);
        });
    }

    const obtieneClientes = (fecha) => {
        listadoClientes({ fecha: fecha }).then((resp) => {
            let datos = resp.data;

            if(usuario.esDn()) {
                datos = resp.data.filter((cliente) => {
                    return Boolean(cliente.activo);
                })
            }

            setListaDeClientes(datos);
        }).catch((error) => {
            console.log("Error: ", error);
        });
    }

    const obtieneMesesDisponibles = () => {
        getMesesConDatos().then((resp) => {
            setMesesDisponibles(() => resp.data)
            if (!resp.data || !resp.data.length) {
                obtieneClientes((new Date()).toISOString().slice(0, 10));
            }
        }).catch((error) => {
            console.log("Error: ", error);
            despliegaMensaje({
                severity: "error",
                summary: `Error:`,
                detail: error.message
            });
        })
    }

    const seleccionarCliente = (clienteId) => {
        setClickCliente(true);
        cliente.montar(clienteId);
    }

    return (<>
        <NeoGridContainer>
            <NeoTitleMain icon={Icono} title="Clientes" />
            <NeoButtonSection align="right" col="12" md="6"
                extra="p-col-align-center p-flex-sm-row p-flex-column">
            </NeoButtonSection>
        </NeoGridContainer>

        {componente.isLoading ?
            <NeoMessageLoading />
            :
            <TablaClientesListado
                clientes={listaDeClientes}
                planes={listaPlanes}
                dns={listaDns}
                onSelect={seleccionarCliente}
                cargando={componente.isLoading} />
        }

    </>);

};


export default Clientes;