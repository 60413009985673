import { useEffect } from "react";
import firebase from "firebase/app";
import useMensajeGlobal from "hooks/useMensajeGlobal";
import useProgressSpinner from "hooks/useProgressSpinner"
import { useLocation } from "react-router-dom";
import { aCadenaFechaConFormatoLocal } from "consts/generales";
import NeoButtonSection from "design/design_components/neo/layout/NeoButtonSection.base";
import NeoGridContainer from "design/design_components/neo/layout/NeoGridContainer.base";
import NeoDataSecond from "design/design_components/neo/data/NeoDataSecond.base";
import NeoCard from "design/design_components/neo/panel/NeoCard.base";
import NeoColumn from "design/design_components/neo/layout/NeoColumn.base";
import NeoTitleSecond from "design/design_components/neo/title/NeoTitleSecond.base";
import CardResumenResultados from 'components/common/CardResumenResultados';
import TablaResultadoValidacion from "components/common/TablaResultadoValidacion"
import NeoButtonToggle from "design/design_components/neo/button/NeoButtonToggle.base";
import NeoInnerTitle from "design/design_components/neo/title/NeoInnerTitle.base";
import NeoInnerSubtitle from "design/design_components/neo/title/NeoInnerSubtitle.base";
import NeoButtonMain from "design/design_components/neo/button/NeoButtonMain.base";
import Icono from 'design/assets/img/mnv/icons/icon-validacion.svg';
import { useComponent, useUsuario } from "hooks";
import useCliente from "hooks/useCliente";
import customStorage from "consts/customStorage";

const ClientesValidacionesDetalle = (props) => {
    const usuario = useUsuario();
    const componente = useComponent();
    const cliente = useCliente();
    const despliegaMensaje = useMensajeGlobal();
    const [, setSpinnerCargando] = useProgressSpinner(false);
    const [validacionId, setValidacionId] = componente.useCustomState();
    const [resumenGeneral, setResumenGeneral] = componente.useCustomState();
    const [resumenCorreos, setResumenCorreos] = componente.useCustomState();
    const [resumenTelefonos, setResumenTelefonos] = componente.useCustomState();
    const [resultadoValidacion, setResultadoValidacion] = componente.useCustomState();
    const [toggleDetalleResumen, setToggleDetalleResumen] = componente.useCustomState(true);
    const [archivoResultados, setArchivoResultados] = componente.useCustomState();
    const [urlArchivoResultados, setUrlArchivoResultados] = componente.useCustomState();
    const [nombreArchivoResultados, setNombreArchivoResultados] = componente.useCustomState();
    let location = useLocation();

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        location
            && location.state
            && setValidacionId(location.state.id);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!validacionId) {
            return;
        }

        obtieneResumenValidacion();
    }, [validacionId]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (resumenGeneral) {
            if(usuario.esAdmin()) {
                if(resumenGeneral?.referencia) {
                    obtenerArchivoDeDescarga();
                }
            }

            if(resumenGeneral?.cantidad <= 40000) {
                obtieneResultadoValidacion();
            }
        }
    }, [resumenGeneral])

    const obtieneResumenValidacion = () => {
        const getResumenValidacion = firebase.functions().httpsCallable("getResumenValidacion");

        getResumenValidacion({
            validacionId: validacionId,
            clienteId: cliente.id
        }).then((resp) => {
            setResumenGeneral(resp.data.general);
            setResumenCorreos(resp.data.correos);
            setResumenTelefonos(resp.data.telefonos);
        }).catch((error) => {
            console.log("Error: ", error);
            despliegaMensaje({
                severity: "error",
                summary: "Error: ",
                detail: error.message
            });
        }).finally(() => {
            setSpinnerCargando(false)
        });
    };

    const obtieneResultadoValidacion = () => {
        const getResultadoValidacionUnitaria = firebase.functions().httpsCallable('getResultadoValidacionUnitaria');

        getResultadoValidacionUnitaria({
            validacionId: validacionId,
            clienteId: cliente.id
        }).then((resp) => {
            resp.data.length && setResultadoValidacion(resp.data)
        }).catch((error) => {
            console.log("Error: ", error);
            despliegaMensaje({
                severity: "error",
                summary: "Error: ",
                detail: error.message
            });
        }).finally(() => {
            setSpinnerCargando(false)
        });
    };

    const obtenerArchivoDeDescarga = async () => {
        let archivoResultados;
        const path =
            "clientes/"
            + cliente.id
            + "/validaciones/"
            + validacionId;
        
        const storageInstance = customStorage(path);
        archivoResultados = await storageInstance.buscar("/Resultado.csv");
        
        if(!archivoResultados) {
            archivoResultados = await storageInstance.buscar("/Resultado.xlsx");
        }

        if(archivoResultados) {
            const infoArchivo = await archivoResultados.obtenerDetalles();
            const nombre = resumenGeneral.referencia.split('.').slice(0, -1).join('.');
            const renombrado = `${nombre}_resultados.${infoArchivo.extension}`;
            const urlDescarga = await archivoResultados.obtenerUrlDescarga();

            setUrlArchivoResultados(urlDescarga);
            setNombreArchivoResultados(renombrado);
            setArchivoResultados(archivoResultados);
            setToggleDetalleResumen(true);
        }
    }

    return (<>
        <NeoGridContainer >
            <NeoTitleSecond col="12" md="6"
                title={resumenGeneral && resumenGeneral.id
                    ? `Validación: #${resumenGeneral.id}`
                    : "Validación: "} icon={Icono} />

            <NeoButtonSection align="right" col="12" md="6"
                extra="p-col-align-center p-flex-sm-row p-flex-column">


                {archivoResultados &&
                    <NeoButtonMain
                        label="Descargar resultados"
                        onClick={() => {
                            const win = window.open(urlArchivoResultados, '_blank');
                            win.focus();
                        }} />
                }

                {
                    resultadoValidacion
                        ? <NeoButtonToggle extra="no-toggle"
                            onLabel="Ver detalles"
                            offLabel="Ver resumen"
                            checked={toggleDetalleResumen}
                            onChange={(e) => setToggleDetalleResumen(e.value)} />
                        : null
                }
            </NeoButtonSection>
        </NeoGridContainer>

        {
            toggleDetalleResumen && resumenGeneral
                ? <NeoCard>
                    <NeoColumn col="auto" >
                        <NeoDataSecond
                            label="Validaciones realizadas"
                            fact={resumenGeneral.cantidad
                                ? resumenGeneral.cantidad.toLocaleString('es-MX')
                                : 0
                            } />
                    </NeoColumn>
                    <NeoColumn col="auto">
                        <NeoDataSecond
                            label="Origen"
                            fact={resumenGeneral.referencia || ""} />
                    </NeoColumn>
                    <NeoColumn col="auto">
                        <NeoDataSecond
                            label="Fecha de creación"
                            fact={aCadenaFechaConFormatoLocal(resumenGeneral.fecha_creacion)} />
                    </NeoColumn>
                    <NeoColumn col="auto">
                        <NeoDataSecond
                            label="Fecha de expiración"
                            fact={aCadenaFechaConFormatoLocal(resumenGeneral.fecha_expiracion)} />
                    </NeoColumn>
                </NeoCard>
                : null
        }

        {
            resumenGeneral && resumenGeneral.estado === "procesando"
                ? <NeoCard>
                    <NeoInnerTitle extra="primero">
                        Archivo en proceso
                    </NeoInnerTitle>
                    <NeoInnerSubtitle>
                        Le enviaremos un correo cuando se finalicen las validaciones.
                    </NeoInnerSubtitle>
                </NeoCard>
                : null
        }

        {
            toggleDetalleResumen
                && resumenCorreos
                && resumenCorreos.tipoResultado.length
                ? <CardResumenResultados extra="mail-card"
                    titulo="Resultado correos electrónicos"
                    graficas={[
                        {
                            datos: resumenCorreos.tipoResultado,
                            titulo: "Tipo de resultados"
                        },
                    ]}
                    detallesSecundarios={[
                        {
                            datos: resumenCorreos.tipoDescripcion,
                            titulo: "Detalle de validaciones"
                        }
                    ]}
                />
                : null
        }


        {
            toggleDetalleResumen
                && resumenTelefonos
                && resumenTelefonos.tipoResultado.length
                ? <CardResumenResultados extra="phone-card"
                    titulo="Resultado teléfonos celulares"
                    graficas={[
                        {
                            datos: resumenTelefonos.tipoResultado,
                            titulo: "Tipo de resultados"
                        },
                    ]}
                    detallesSecundarios={[
                        {
                            datos: resumenTelefonos.tipoDescripcion,
                            titulo: "Detalle de validaciones"
                        }
                    ]}
                />
                : null
        }


        {
            !toggleDetalleResumen && resultadoValidacion
                ? <TablaResultadoValidacion datos={resultadoValidacion} />
                : null
        }

    </>);
};


export default ClientesValidacionesDetalle;
