import NeoDialog from "design/design_components/neo/overlay/NeoDialog.base";
import { DateTime } from "luxon";
import NeoButtonMain from "design/design_components/neo/button/NeoButtonMain.base";
import NeoInputNumber from "design/design_components/neo/form/NeoInputNumber.base";
import NeoDialogConfirmMethod from 'design/design_components/neo/overlay/NeoDialogConfirmMethod.base';
import { useComponent } from 'hooks';

const ModalOrdenActualizar = (props) => {
    const component = useComponent({
        data: {
            meses_vigencia: props?.orden?.meses_vigencia
        }
    });

    const validaFormulario = () => {
        let ok = true;

        if(!component.data.meses_vigencia) {
            component.data.meses_vigencia_error = 'Campo requerido';
            ok = false; 
        } else {
            const fecha_inicio = DateTime.fromFormat(
                props.orden?.fecha_inicio,
                'yyyy-MM-dd'
            );


            if(fecha_inicio.isValid) {
                const new_fecha_vigencia = fecha_inicio.plus({months: component.data.meses_vigencia});
                const now = DateTime.now();
                if(new_fecha_vigencia < now) {
                    component.data.meses_vigencia_error = 'Ingresa un valor mayor a hoy';
                    ok = false; 
                }
            } else {
                component.data.meses_vigencia_error = '';
            }
        }

        return ok;
    }

    const onEnviarFormulario = () => {
        if (validaFormulario()) {
            if (props.confirmDialog) {
                NeoDialogConfirmMethod({
                    message: '¿Deseas actualizar la vigencia de esta orden?',
                    header: 'Confirmación',
                    icon: 'pi pi-exclamation-triangle',
                    acceptLabel: 'Si',
                    rejectLabel: 'No',
                    accept: () => enviarFormulario()
                });
            } else {
                enviarFormulario();
            }
        }
    }

    const enviarFormulario = () => {
        if (typeof props.onEnviarFormulario === 'function') {
            props.onEnviarFormulario({
                id: props?.orden?.id,
                meses_vigencia: component.data.meses_vigencia
            });
        }
    }

    return (
        <NeoDialog
            style={{width: '25vw'}}
            breakpoints={{'960px': '75vw', '640px': '100vw'}} 
            visible={props.visible}
            onHide={props.onHide}
            header={props.header ? props.header : 'Actualizar orden'}
            footer={
                <NeoButtonMain label="Guardar" onClick={onEnviarFormulario} />
            }
        >

            <NeoInputNumber col="12"
                value={component.data.meses_vigencia}
                onValueChange={(e) => component.data.meses_vigencia = e.target?.value}
                label="Meses de vigencia"
                error={component.data.meses_vigencia_error}
                min={1}
                max={12}
            />
        </NeoDialog>
    );
}

ModalOrdenActualizar.defaultProps = {
    confirmDialog: true
}

export default ModalOrdenActualizar;