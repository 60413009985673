/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useRef } from "react";
import { useComponent, useInput } from 'hooks';
import { capitalizar, agrupaPor, abrirCorreo, abrirWhatsApp } from "consts/generales";
import NeoCard from "design/design_components/neo/panel/NeoCard.base";
import NeoInnerTitle from "design/design_components/neo/title/NeoInnerTitle.base";
import NeoInputText from "design/design_components/neo/form/NeoInputText.base";
import NeoButtonMain from "design/design_components/neo/button/NeoButtonMain.base";
import NeoInputChip from "design/design_components/neo/form/NeoInputChip.base";
import NeoDataSecond from "design/design_components/neo/data/NeoDataSecond.base";
import NeoDialog from "design/design_components/neo/overlay/NeoDialog.base";
import NeoButtonOutlined from "design/design_components/neo/button/NeoButtonOutlined.base";
import { validaNombre, validaCorreo, validaDominio, validaTelefono, validaRenovacionMensual, validaListaBlancaCorreo }
    from "consts/validacionDatosUsuario"
import NeoInputMask from "design/design_components/neo/form/NeoInputMask.base";
import NeoButtonSection from "design/design_components/neo/layout/NeoButtonSection.base";
import NeoDropdown from "design/design_components/neo/form/NeoDropdown.base";
import NeoTooltip_icon from "design/design_components/neo/overlay/NeoTooltip_icon.base";
import NeoColumn from "design/design_components/neo/layout/NeoColumn.base";
import TablaPreciosCliente from "components/common/TablaPreciosCliente";
import NeoMessageLoading from "design/design_components/neo/messages/NeoMessageLoading.base";
import NeoInputNumber from "design/design_components/neo/form/NeoInputNumber.base";
import NeoGridContainer from "design/design_components/neo/layout/NeoGridContainer.base";
import { SOBREGIRO } from "consts/db.const";
import NeoTabView from "design/design_components/neo/panel/NeoTabView.base";
import NeoTabPanel from "design/design_components/neo/panel/NeoTabPanel.base";
import NeoHeaderData from "design/design_components/neo/data/NeoHeaderData.base";
import NeoInputSwitch from "design/design_components/neo/form/NeoInputSwitch.base";
import NeoMessages from "design/design_components/neo/messages/NeoMessages.base";
import useMensajeGlobal from "hooks/useMensajeGlobal";
import { correoEstaPresenteEnListaBlanca, registroEstaPresenteEnListaNegra } from "./listas";

const opcionesHabilitado = [
    { opcion: "Modo privado", valor: true },
    { opcion: "Modo normal", valor: false }
]

const opcionesEstadoCliente = [
    { opcion: "Activo", valor: 'true' },
    { opcion: "Inactivo", valor: 'false' }
]

const listaBlancaOpcionesValidacionesPermitidas = [
    { opcion: "Ilimitado", valor: -1 },
    { opcion: 1, valor: 1 },
    { opcion: 2, valor: 2 },
    { opcion: 3, valor: 3 },
    { opcion: 4, valor: 4 },
    { opcion: 5, valor: 5 },
    { opcion: 6, valor: 6 },
    { opcion: 7, valor: 7 },
    { opcion: 8, valor: 8 },
    { opcion: 9, valor: 9 },
    { opcion: 10, valor: 10 },
];

const inputsTabs = {
    nombre: 0,
    apellido: 0,
    correo: 0,
    empresa: 0,
    telefono: 0,
    listaNotificaciones: 0,
    vigenciaDatos: 0,
    sobregiro: 0,
    renovacionMensualListaBlanca: 1,
    registroListaBlancaForm: 1,
    listaBlancaCorreo: 1,
    listaNegraCorreo: 2,
    listaNegraTelefono: 2,
}

/**
 * @typedef {{
 *  nombre: string,
 *  tiene_errores: boolean,
 * }} Tab
 * @type {{[index: string]: Tab }}
 */
const initialTabs = {
    0: {
        nombre: 'Configuración general',
        tiene_errores: false,
    },
    1: {
        nombre: 'Lista blanca',
        tiene_errores: false,
    },
    2: {
        nombre: 'Lista negra',
        tiene_errores: false,
    },
}

/**
 * @typedef {{
 *  dato_validado: string,
 *  validaciones_permitidas: number,
 *  validaciones_realizadas: number,
 * }} RegistroListaBlanca
 * 
 * @typedef {{ [index: string]: RegistroListaBlanca }} ListaBlancaMap
 * 
 * @param {{
 *  datos: {
 *      listaBlanca: Array<RegistroListaBlanca>,
 *      renovacion_mensual: boolean,
 *  }
 * }} props 
 * @returns 
 */
const FormularioCuentaUsuario = (props) => {
    const componente = useComponent();
    const [nombre, setNombre] = useInput("");
    const [apellido, setApellido] = useInput("");
    const [telefono, setTelefono] = useInput("");
    const [correo, setCorreo] = useInput("");
    const [empresa, setEmpresa] = useInput("");
    const [activo, setActivo] = componente.useCustomState();
    const [listaNotificaciones, setListaNotificaciones] = useInput([]);
    const [planPago, setPlanPago] = componente.useCustomState("");
    const [dnAsignado, setDnAsignado] = componente.useCustomState("");
    const [correoDnAsignado, setCorreoDnAsignado] = componente.useCustomState("");
    const [whatsAppDnAsignado, setWhatsAppDnAsignado] = componente.useCustomState("");
    const [sobregiro, setSobregiro] = componente.useCustomState();
    const [modoPrivado, setModoPrivado] = componente.useCustomState();
    const [vigenciaDatos, setVigenciaDatos] = componente.useCustomState('');
    const [errores, setErrores] = componente.useCustomState({})
    const [validar, setValidar] = componente.useCustomState(false);
    const [, setTipoPlan] = componente.useCustomState({});
    const mensajeForm = useRef(null);
    const [precios, setPrecios] = componente.useCustomState();
    const [mostrarSobregiro, setMostrarSobregiro] = componente.useCustomState(false);
    const [listaNegraCorreo, setListaNegraCorreo] = useInput([]);
    const [listaNegraTelefono, setListaNegraTelefono] = useInput([]);

    // const [contrasenaSFTP, setContrasenaSFTP] = useInput("");
    const [modalPrecio, setModalPrecio] = componente.useCustomState([]);

    /** @type {[{[index: string]: Tab}, import("react").Dispatch<import("react").SetStateAction<{[index: string]: Tab}>>]} */
    const tabs = useMemo(() => getTabsConErrores(initialTabs, errores), [errores]);

    /** @type {[string, import("react").Dispatch<import("react").SetStateAction<string>>]} */
    const [busquedaListaNegra, setBusquedaListaNegra] = componente.useCustomState('');

    const listaNegraCorreoFiltrada = useMemo(() => {
        return getListaNegraFiltrada(listaNegraCorreo, busquedaListaNegra);
    }, [listaNegraCorreo, busquedaListaNegra]);

    const listaNegraTelefonoFiltrada = useMemo(() => {
        return getListaNegraFiltrada(listaNegraTelefono, busquedaListaNegra);
    }, [listaNegraTelefono, busquedaListaNegra]);

    /** @type {[boolean, import("react").Dispatch<import("react").SetStateAction<boolean>>]} */
    const [visible, setVisible] = componente.useCustomState(false);

    /** @type {[boolean, import("react").Dispatch<import("react").SetStateAction<boolean>>]} */
    const [visibleCambioBN, setVisibleCambioBN] = componente.useCustomState(false);

    /** @type {[boolean, import("react").Dispatch<import("react").SetStateAction<boolean>>]} */
    const [visibleCambioNB, setVisibleCambioNB] = componente.useCustomState(false);

    /** @type {[boolean, import("react").Dispatch<import("react").SetStateAction<boolean>>]} */
    const [renovacionMensualListaBlanca, setRenovacionMensualListaBlanca] = componente.useCustomState(false);

    /** @type {[Array<RegistroListaBlanca>, import("react").Dispatch<import("react").SetStateAction<Array<RegistroListaBlanca>>>]} */
    const [listaBlancaCorreo, setListaBlancaCorreo] = componente.useCustomState([]);

    /** @type {[Array<RegistroListaBlanca>, import("react").Dispatch<import("react").SetStateAction<Array<RegistroListaBlanca>>>]} */
    const [listaBlancaCorreoAuxiliar, setListaBlancaCorreoAuxiliar] = componente.useCustomState([]);

    /** @type {[string, import("react").Dispatch<import("react").SetStateAction<string>>]} */
    const [busquedaListaBlanca, setBusquedaListaBlanca] = componente.useCustomState('');

    const listaBlancaCorreoFiltrada = getListaBlancaFiltrada(listaBlancaCorreo, busquedaListaBlanca, renovacionMensualListaBlanca);

    const contadorActivosListaBlancaCorreo = listaBlancaCorreoFiltrada.filter(registroListaBlancaTieneUsosDisponibles).length;

    /** @type {[RegistroListaBlanca, import("react").Dispatch<import("react").SetStateAction<RegistroListaBlanca>>]} */
    const [registroListaBlancaForm, setRegistroListaBlancaForm] = componente.useCustomState({});

    /** @type {[string, import("react").Dispatch<import("react").SetStateAction<string>>]} */
    const [registroListaBlancaActivoValor, setRegistroListaBlancaActivoValor] = componente.useCustomState('');

    // Mensajes de error cuando los registros son menores
    const msgs = useRef(null);

    // Mensaje global para el caso del Guardado del modal en una edición
    const setMensajeGlobal = useMensajeGlobal();

    // Manejo de habilitar o deshabilitar botón de guardar de modal
    const [botonHabilitado, setBotonHabilitado] = componente.useCustomState(true);

    function handleChipItemClick(item) {
        return () => {
            setRegistroListaBlancaActivoValor(item.dato_validado);
            setRegistroListaBlancaForm(item);
            setBotonHabilitado(true);
            setVisible(true);
        }
    }

    /**
     * @param {RegistroListaBlanca} item 
     * @returns 
     */
    function templateListaBlancaCorreo(item) {
        const status = registroListaBlancaTieneUsosDisponibles(item)
            ? 'available'
            : 'not-available';

        return (
            <div data-status={status} onClick={handleChipItemClick(item)}>
                <span>{item.dato_validado} - {item.validaciones_realizadas}/{item.validaciones_permitidas !== -1 ? item.validaciones_permitidas : '∞'}</span>
            </div>
        );
    }

    /**
     * @param {RegistroListaBlanca} registro
     * @returns {boolean}
     */
    function registroListaBlancaTieneUsosDisponibles(registro) {
        const usos_restantes = Number(registro.validaciones_permitidas) - Number(registro.validaciones_realizadas);

        return usos_restantes > 0 || registro.validaciones_permitidas === -1
    }

    /**
     * @param {Array<RegistroListaBlanca>} lista 
     * @param {string} busqueda 
     * @returns {Array<RegistroListaBlanca>}
     */
    function getListaBlancaFiltrada(lista, busqueda, mantenerInactivos = true) {
        /** @type {(a: RegistroListaBlanca, b: RegistroListaBlanca) => -1|1|0} */
        const ordenarPorEstatus = (a, b) => {
            if (registroListaBlancaTieneUsosDisponibles(a) && !registroListaBlancaTieneUsosDisponibles(b)) return -1;
            if (!registroListaBlancaTieneUsosDisponibles(a) && registroListaBlancaTieneUsosDisponibles(b)) return 1;
            return 0;
        }

        /** @type {(elemento: RegistroListaBlanca) => boolean} */
        const matchBusqueda = (elemento) => elemento.dato_validado.includes(busqueda);
        
        /** @type {(elemento: RegistroListaBlanca) => boolean} */
        const matchEstatus = (elemento) => registroListaBlancaTieneUsosDisponibles(elemento) || mantenerInactivos;

        if (!Array.isArray(lista)) return [];
        if (!String(busqueda).trim()) return lista.sort(ordenarPorEstatus);

        return lista.filter(matchBusqueda).sort(ordenarPorEstatus);
    }

    /**
     * @param {Array<string>} lista 
     * @param {string} busqueda 
     * @returns {Array<string>}
     */
    function getListaNegraFiltrada(lista, busqueda) {
        if (!String(busqueda).trim()) return lista;

        return lista.filter((elemento) => elemento.includes(busqueda))
    }

    function manejarCambioListaNegraABlanca() {
        const index = listaNegraCorreo.indexOf(registroListaBlancaActivoValor);
        
        // Verificar si ya estaba dentro de Lista Blanca al inicio,esto es el caso para cuando no seha guardado aún y debe regresar los valores como estaban
        const correoEncontradoAuxiliar = listaBlancaCorreoAuxiliar.find(elemento => elemento.dato_validado === registroListaBlancaActivoValor);
        let validaciones_permitidas;
        let validaciones_realizadas;

        if (correoEncontradoAuxiliar) {
            validaciones_permitidas = correoEncontradoAuxiliar.validaciones_permitidas;
            validaciones_realizadas = correoEncontradoAuxiliar.validaciones_realizadas;
        }
        else{
            validaciones_permitidas = 2;
            validaciones_realizadas = 0;
        }

        if (index !== -1) {
            // Eliminar el elemento del arreglo usando splice
            listaNegraCorreo.splice(index, 1);
            setListaBlancaCorreo([...listaBlancaCorreo, { dato_validado: registroListaBlancaActivoValor, validaciones_permitidas: validaciones_permitidas, validaciones_realizadas: validaciones_realizadas }]);
        } else {
            console.log(`El registro "${registroListaBlancaActivoValor}" no se encontró en la lista negra.`);
        }
           setVisibleCambioBN(false);
       
    }

    function manejarCambioListaBlancaANegra() {
        const index = listaBlancaCorreo.findIndex(elemento => elemento.dato_validado === registroListaBlancaActivoValor);
     
         // Hacer una copia superficial del arreglo
        const listaBlancaCorreoCopia = listaBlancaCorreo.slice();
        setListaBlancaCorreoAuxiliar(listaBlancaCorreoCopia);
    
        if (index !== -1) {
            // Hacer una copia superficial de listaBlancaCorreo antes de modificarla
            const listaBlancaCorreoModificada = listaBlancaCorreo.slice();
            // Eliminar el elemento del arreglo usando splice en la copia
            listaBlancaCorreoModificada.splice(index, 1);
            
            // Actualizar el estado de listaBlancaCorreo
            setListaBlancaCorreo(listaBlancaCorreoModificada);
    
            // Actualizar el estado de listaNegraCorreo
            setListaNegraCorreo([...listaNegraCorreo, registroListaBlancaActivoValor]);
    
      
        } else {
            console.log(`El registro "${registroListaBlancaActivoValor}" no se encontró en la lista blanca.`);
        }
    
        setVisibleCambioNB(false);
    }

    function manejarListaBlancaOnChange(event) {
       if (eventoChipsEstaRemoviendoElemento(event)) {
            setListaBlancaCorreo(event.value);
            return;
        }
      
        const nuevoElemento = event.value.pop();
        
        const esValido = validaCorreo(nuevoElemento).esValido;

        if (!esValido) {
            setErrores({ ...errores, listaBlancaCorreo: 'Los registros de lista blanca deben ser correos completos' });
            return;
        }

        if (correoEstaPresenteEnListaBlanca(listaBlancaCorreo, nuevoElemento)) {
            setErrores({ ...errores, listaBlancaCorreo: 'El correo está duplicado' });
            return;
        }

        setErrores({ ...errores, listaBlancaCorreo: null });
        const existsInListaNegra = listaNegraCorreo.includes(nuevoElemento);

        if (existsInListaNegra) {
            // Modal para mostrar el mensaje y decidir si cambia a esta lista o se elimina
            setRegistroListaBlancaActivoValor(nuevoElemento);
            setVisibleCambioBN(true);
        } else {
            // Si no está en lista negra verificar si ya estaba en el arreglo de lista blanca auxiliar
            const correoEncontradoAuxiliar = listaBlancaCorreoAuxiliar.find(elemento => elemento.dato_validado === nuevoElemento);
            let validaciones_permitidas;
            let validaciones_realizadas;
            if (correoEncontradoAuxiliar) {
                validaciones_permitidas = correoEncontradoAuxiliar.validaciones_permitidas;
                validaciones_realizadas = correoEncontradoAuxiliar.validaciones_realizadas;
            }
            else{
                validaciones_permitidas = 2;
                validaciones_realizadas = 0;
            }
            setListaBlancaCorreo([...listaBlancaCorreo, { dato_validado: nuevoElemento, validaciones_permitidas: validaciones_permitidas, validaciones_realizadas: validaciones_realizadas }]);
        }
        
    }

    function manejarRenovacionMensualListaBlancaOnChange(event) {
        setRenovacionMensualListaBlanca(event.value);
    }

    function manejarActualizarElementoListaBlanca() {
        const listaBlancaCorreoCopia = listaBlancaCorreo;
        const registroActivo = listaBlancaCorreoCopia.find((elemento) => elemento.dato_validado === registroListaBlancaActivoValor);

        const [registroEsValido, erroresRegistro] = validarActualizacionDeRegistroListaBlanca(registroListaBlancaForm);

        if (!registroEsValido) {
            setErrores({
                ...errores,
                registroListaBlancaForm: erroresRegistro,
            });

            return;
        }

        setErrores({
            ...errores,
            registroListaBlancaForm: {},
        });

        setMensajeGlobal({
            severity: "success",
            summary: "",
            detail: "Se actualizó el registro, guarde los cambios para aplicarlos"
        });

        Object.assign(registroActivo, registroListaBlancaForm);
        setListaBlancaCorreo(listaBlancaCorreoCopia);
        setVisible(false);
    }

    /**
     * @param {RegistroListaBlanca} registro 
     * @returns {[boolean, {dato_validado: string, validaciones_permitidas: string}]}
     */
    function validarActualizacionDeRegistroListaBlanca(registro) {
        const erroresRegistro = {};

        if (!validaCorreo(registro.dato_validado).esValido) {
            erroresRegistro['dato_validado'] = 'El correo es inválido';
        }

        /** Si el correo no se edita no hace falta validar que no esté duplicado. */
        if (registroListaBlancaActivoValor !== registro.dato_validado) {
            // Valida correos repetidos sobre la lista blanca
            if (correoEstaPresenteEnListaBlanca(listaBlancaCorreo, registro.dato_validado)) {
                erroresRegistro['dato_validado'] = 'El correo está duplicado';
            }
            // Valida que el correo no se encuentre repetido en lista negra, en caso de ser así solo marcar la validación
            if (registroEstaPresenteEnListaNegra(listaNegraCorreo, registro.dato_validado)) {
                erroresRegistro['dato_validado'] = 'El correo se encuentra en lista negra';
            }
            
        }

        

        if (
            registroListaBlancaForm.validaciones_permitidas === undefined
            || registroListaBlancaForm.validaciones_permitidas === null
            || isNaN(Number(registro.validaciones_permitidas))
            || Number(registro.validaciones_permitidas) < -1
            || (Number(registro.validaciones_permitidas) !== -1 && renovacionMensualListaBlanca==0 && Number(registro.validaciones_permitidas) < Number(registro.validaciones_realizadas))
        ) {
            erroresRegistro['validaciones_permitidas'] = 'Las validaciones permitidas son inválidas, no pueden ser menores a las realizadas en este momento';
        }

        return [Object.keys(erroresRegistro).length === 0, erroresRegistro];
    }

    function manejarBusquedaListaBlanca(e) {
        const busqueda = e.target.value;

        setBusquedaListaBlanca(String(busqueda));
    }

   

    function manejarBusquedaListaNegra(e) {
        const busqueda = e.target.value;

        setBusquedaListaNegra(String(busqueda));
    }

    function manejarGuardarListaNegraCorreo(event) {
        if (eventoChipsEstaRemoviendoElemento(event)) {
            setListaNegraCorreo(event.value);
            return;
        }

        const nuevoElemento = event.value.pop();

        if (registroEstaPresenteEnListaNegra(listaNegraCorreo, nuevoElemento)) {
            setErrores({ ...errores, listaNegraCorreo: 'El valor está duplicado' });
            return;
        }

        setErrores({ ...errores, listaNegraCorreo: null });
        const correoEncontrado = listaBlancaCorreo.find(elemento => elemento.dato_validado === nuevoElemento);

        if (correoEncontrado) {
            setRegistroListaBlancaActivoValor(nuevoElemento);
            setVisibleCambioNB(true);
        } else {
            setListaNegraCorreo([...listaNegraCorreo, nuevoElemento]);
        }
    }

    function manejarGuardarListaNegraTelefono(event) {
        if (eventoChipsEstaRemoviendoElemento(event)) {
            setListaNegraTelefono(event.value);
            return;
        }

        const nuevoElemento = event.value.pop();

        if (registroEstaPresenteEnListaNegra(listaNegraTelefono, nuevoElemento)) {
            setErrores({ ...errores, listaNegraTelefono: 'El teléfono está duplicado' });
            return;
        }

        setErrores({ ...errores, listaNegraTelefono: null });
        setListaNegraTelefono([...listaNegraTelefono, nuevoElemento]);
    }

    /**
     * Cuando el tipo de evento tiene code
     * 'backspace' o es de tipo 'click' significa que 
     * es un evento de remoción de elemento dentro
     * del chip.
     * 
     * @returns {boolean}
     */
    function eventoChipsEstaRemoviendoElemento(event) {
        return event.originalEvent.type === 'click' || event.originalEvent.code === 'Backspace';
    }

    /**
     * Selecciona el activeTab en función
     * de l
     * @param {{ [index: string]: Tab }} tabList
     * @param {{ [index: string]: string|null }} errores 
     */
    function getTabsConErrores(tabList, errores) {
        const tabsCopy = JSON.parse(JSON.stringify(tabList));

        for (const error in errores) {
            const isAnEmptyObject = (value) => Object.prototype.toString.call(value) === "[object Object]" && Object.keys(value).length === 0;
            const isAnEmptyArray = (value) => value.length === 0;
            const errorMessage = errores[error];

            if (!errorMessage) continue;

            if (isAnEmptyObject(errorMessage)) continue;

            if (isAnEmptyArray(errorMessage)) continue

            tabsCopy[inputsTabs[error]].tiene_errores = true;
        }

        return tabsCopy;
    }

    /**
     * @param {string} tabKey 
     */
    function getTabHeaderIcon(tabKey) {
        const tab = tabs[tabKey];

        return tab.tiene_errores ?
            'pi pi-exclamation-triangle'
            : '';
    }

    function getTooltipTemplateListaNegraCorreo() {
        return `Dominio: Correos que correspondan con el dominio. 
            Ej. "@temporal.com".
            
            Correo completo: Correos con un 100% de coincidencia.
            Ej. "no.existe@gmail.com".
            
            Contenido: Correos con una palabra definida.
            Ej. "temporal";
        `;
    }

    const TIPOS_SOBREGIRO = [{
        id: 2,
        nombre: "Fijo"
    }, {
        id: 1,
        nombre: "Promedio"
    }];

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []);

    useEffect(() => {
        estableceDatosIniciales()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.datos]);

    useEffect(() => {
        if (!props.precios) {
            return;
        }

        let tablas = Object.keys(props.precios).map((tipo, index) => {
            return <>
                <NeoColumn col="1">
                    <NeoButtonOutlined label={capitalizar(tipo)} onClick={() => {
                        setModalPrecio(tipo);
                    }} />
                </NeoColumn>

                <NeoDialog
                    header={`Validaciones de ${capitalizar(tipo)}`}
                    visible={modalPrecio === tipo}
                    onHide={() => {
                        setModalPrecio();
                    }}
                >
                    <TablaPreciosCliente
                        key={index}
                        titulo={capitalizar(tipo)}
                        datos={props.precios[tipo]}
                        vistaCliente={props.vistaCliente}
                    />
                </NeoDialog>
            </>
        });

        setPrecios(tablas);
    }, [props.precios, modalPrecio]);

    useEffect(() => {
        if (validar)
            validaFormulario();

        mensajeForm.current && mensajeForm.current.clear()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nombre, correo, telefono, listaNotificaciones]);

    useEffect(() => {
        if (planPago?.nombre === 'postpago') {
            setMostrarSobregiro(true);

            if (Array.isArray(props.sobregiroValidaciones)) {
                const sobregiroPermitidoDefecto = 500000;
                const sobregiroPorcentajeDefecto = 25;
                const sobregiro = props.sobregiroValidaciones.map((elemento, index) => {
                    const nodo = { ...elemento };
                    // No tiene registrado ningun sobregiro permitido
                    if (!elemento.clienteSobregiroId) {
                        nodo.cantidad_permitida = sobregiroPermitidoDefecto;
                        nodo.porcentaje_permitido = sobregiroPorcentajeDefecto;
                        nodo.tipo = 1;
                    }

                    return nodo;
                });
                setSobregiro(sobregiro);
            }
        } else {
            setMostrarSobregiro(false);

            if (Array.isArray(props.sobregiroValidaciones)) {
                const sobregiro = props.sobregiroValidaciones.map((elemento, index) => {
                    const nodo = { ...elemento };
                    if (elemento.clienteSobregiroId) {
                        nodo.permitido = 0;
                    }
                    return nodo;
                });
                setSobregiro(sobregiro);
            }
        }
    }, [planPago, props.sobregiroValidaciones])

    const estableceDatosIniciales = () => {
        if (!props.hasOwnProperty("datos")) {
            console.log("No hay datos del cliente");
            return
        }

        setNombre(props.datos?.nombre)
        setApellido(props.datos?.apellido)
        setTelefono(props.datos?.telefono)
        setCorreo(props.datos?.correo)
        setEmpresa(props.datos?.empresa);
        setListaNotificaciones(props.datos?.listaNotificaciones)
        // console.log(props.datos.listaNegra);
        const listaNegra = agrupaPor(props.datos.listaNegra, 'tipo_validacion_nombre');

        if (typeof listaNegra === 'object') {
            if (listaNegra?.correo) {
                const listaNegraCorreo = listaNegra?.correo.map(e => e.valor);
                setListaNegraCorreo(listaNegraCorreo);
            }
            if (listaNegra?.telefono) {
                const listaNegraTelefono = listaNegra?.telefono.map(e => e.valor);
                setListaNegraTelefono(listaNegraTelefono);
            }
        }

        /** INICIO: Lista blanca */
        setRenovacionMensualListaBlanca(Boolean(props.datos.renovacion_mensual));
        setListaBlancaCorreo(props.datos.listaBlanca ?? []);
        setListaBlancaCorreoAuxiliar(props.datos.listaBlanca ?? []);
        /** FIN: Lista blanca */

        setActivo(String(Boolean(props.datos.activo)))
        setSobregiro(props.sobregiroValidaciones);

        props.asignacionDnEditable
            ? props.datos.listaPlanes && setPlanPago(props.datos.plan)
            : props.datos.plan
            && props.datos.plan.nombre
            && setPlanPago(props.datos.plan.nombre)

        if (props.asignacionDnEditable) {
            props.datos.listaDns && setDnAsignado(props.datos?.dn?.id)
        } else {
            props.datos.dn && setDnAsignado(`${props.datos.dn.nombre} ${props.datos.dn.apellido}`)
            props.datos.dn && setCorreoDnAsignado(props.datos.dn.correo)
            props.datos.dn && setWhatsAppDnAsignado(props.datos.dn.telefono)
        }

        if (props.datos.plan)
            setTipoPlan(props.datos.plan);

        if (props.datos.modoPrivado !== undefined) {
            const modoPrivadoIncial = opcionesHabilitado.find(o => o.valor === props.datos.modoPrivado);
            setModoPrivado(modoPrivadoIncial)
        }

        if (props.datos.vigecia_validaciones) {
            setVigenciaDatos(`${props.datos.vigecia_validaciones}`);
        }
    };

    const validaListNotifs = () => {
        let esValido = true;
        let mensaje = null;

        listaNotificaciones.forEach((item) => {
            esValido = validaCorreo(item).esValido && esValido;
        });

        if (!esValido)
            mensaje = "Existe un correo no válido, favor de verificar"

        return { esValido: esValido, mensaje: mensaje }
    };

    const validaListNegraCorreo = () => {
        let esValido = true;
        let mensaje = null;

        listaNegraCorreo.forEach((item) => {
            if (!validaDominio(item).esValido) {
                if (!validaCorreo(item).esValido) {
                    const validaCoincidencia = /^[a-z0-9_\-\.]{3,}$/;
                    if (!validaCoincidencia.test(item)) {
                        esValido = false;
                    }
                }
            }
        });

        if (!esValido)
            mensaje = "Existe un dato no válido, favor de verificar"

        return { esValido: esValido, mensaje: mensaje }
    };

    const validaListNegraTelefono = () => {
        let esValido = true;
        let mensaje = null;

        listaNegraTelefono.forEach((item) => {
            if (esValido) {
                esValido = validaTelefono(item).esValido && esValido;
            }
        });

        if (!esValido)
            mensaje = "Existe un telefono no válido, favor de verificar"

        return { esValido: esValido, mensaje: mensaje }
    };

    const validaSobregiroDatos = () => {
        let esValido = true;
        let mensaje = {};

        if (Array.isArray(sobregiro)) {
            sobregiro.forEach((item) => {
                mensaje[item.nombre] = {};
                if (item.tipo === 1) {
                    if (item.porcentaje_permitido === null) {
                        mensaje[item.nombre].porcentaje_permitido = 'Este valor no es un valor valido';
                        esValido = false;
                    } else {
                        mensaje[item.nombre].porcentaje_permitido = '';
                    }
                } else if (item.tipo === 2) {
                    if (item.cantidad_permitida === null) {
                        mensaje[item.nombre].cantidad_permitida = 'Este valor no es un valor valido';
                        esValido = false;
                    } else {
                        mensaje[item.nombre].cantidad_permitida = '';
                    }
                }
            });
        }

        return { esValido: esValido, mensaje: mensaje }
    }

    const validaFormulario = () => {
        let esValido = true;
        setValidar(true);

        const resultadoNombre = validaNombre(nombre);
        const resultadoCorreo = validaCorreo(correo);
        const resultadoTelefono = validaTelefono(telefono);
        const resultadoListNotifs = validaListNotifs();
        const resultadoListNegraCorreo = validaListNegraCorreo();
        const resultadoListNegraTelefono = validaListNegraTelefono();
        const resultadoValidaVigencia = validaVigenciaDatos();
        const resultadoValidaSobregiro = validaSobregiroDatos();
        const resultadoRenovacionMensual = validaRenovacionMensual(renovacionMensualListaBlanca);
        const resultadoListaBlancaCorreo = validaListaBlancaCorreo(listaBlancaCorreo);

        setErrores({
            nombre: resultadoNombre.mensaje,
            correo: resultadoCorreo.mensaje,
            telefono: resultadoTelefono.mensaje,
            listaNotificaciones: resultadoListNotifs.mensaje,
            listaNegraCorreo: resultadoListNegraCorreo.mensaje,
            listaNegraTelefono: resultadoListNegraTelefono.mensaje,
            vigenciaDatos: resultadoValidaVigencia?.mensaje,
            sobregiro: resultadoValidaSobregiro.mensaje,
            listaBlancaCorreo: resultadoListaBlancaCorreo.mensaje,
            renovacionMensualListaBlanca: resultadoRenovacionMensual.mensaje
        });

        if (resultadoNombre.esValido === false
            || resultadoCorreo.esValido === false
            || resultadoTelefono.esValido === false
            || resultadoListNotifs.esValido === false
            || resultadoListNegraCorreo.esValido === false
            || resultadoListNegraTelefono.esValido === false
            || resultadoValidaVigencia.esValido === false
            || resultadoValidaSobregiro.esValido === false
            || resultadoListaBlancaCorreo.esValido === false
            || resultadoRenovacionMensual.esValido === false
        ) {
            esValido = false;
        }

        return esValido;
    };

    const manejaGuardar = () => {
        setValidar(true);
        if (!validaFormulario()) {
            return false;
        }

        //Formulario válido, listo para enviar datos

        props?.onSubmit && props.onSubmit({
            nombre: nombre,
            apellido: apellido,
            telefono: telefono,
            correo: correo,
            empresa: empresa,
            listaNotificaciones: listaNotificaciones,
            listaNegra: {
                correo: listaNegraCorreo,
                telefono: listaNegraTelefono,
            },
            listaBlanca: {
                correo: listaBlancaCorreo,
            },
            renovacion_mensual: renovacionMensualListaBlanca ? 1 : 0,
            plan: planPago,
            dnAsignado: dnAsignado,
            modoPrivado: modoPrivado.valor,
            vigenciaDatos: vigenciaDatos,
            activo: activo === 'true' ? 1 : 0,
            sobregiroValidaciones: sobregiro
        });
    };

    const validaVigenciaDatos = () => {
        if (vigenciaDatos.trim() === '') {
            return {
                esValido: false,
                mensaje: 'Este campo es necesario'
            };
        } else if (isNaN(vigenciaDatos)) {
            return {
                esValido: false,
                mensaje: 'Este valor no es un valor valido'
            };
        } else if (parseInt(vigenciaDatos) <= 0) {
            return {
                esValido: false,
                mensaje: 'Este valor no es un valor valido'
            };
        }

        return {
            esValido: true
        }
    }

    // const permiteEditarPrecios = () => {
    //     if (props.preciosEditables === true) {
    //         return (rowData, datos) => {
    //             const index = precios.findIndex(
    //                 (precio) => precio.id === rowData.id
    //             );

    //             if (index !== undefined) {
    //                 precios[index].precio = rowData.precio;
    //                 setPrecios(precios);
    //             }
    //         }
    //     }
    //     return false;
    // }

    const cambiaSobregiro = (valor, indice, prop) => {
        let nuevoSobregiro = [...sobregiro];
        nuevoSobregiro[indice][prop] = valor;
        setSobregiro(nuevoSobregiro);
    }

    const templateListaNegraCorreo = (item) => {
        let clase = '';

        const validaCoincidencia = /^[a-z0-9_\-\.]{3,}$/;

        if (validaCorreo(item).esValido) {
            clase = 'blacklist-data';
        } else if (validaDominio(item).esValido) {
            clase = 'blacklist-domain';
        } else if (validaCoincidencia.test(item)) {
            clase = 'blacklist-coincidence';
        } else {
            clase = 'blacklist-not-allowed';
        }

        return (
            <div className={clase}>
                <span>{item}</span>
            </div>
        );
    }

    const templateListaNegraTelefono = (item) => {
        let clase = '';

        if (validaTelefono(item).esValido) {
            clase = 'blacklist-data';
        } else {
            clase = 'blacklist-not-allowed';
        }

        return (
            <div className={clase}>
                <span>{item}</span>
            </div>
        );
    }

    const calcularPorcentajeValidacionesSobregiro = (sobregiro) => {
        return Math.ceil((
            sobregiro.total_validaciones_orden * sobregiro.porcentaje_permitido
        ) / 100);
    }

    return (
        <>
            <NeoTabView extra="formulario-cuenta-usuario-tab-view">
                <NeoTabPanel header="Configuración general" rightIcon={getTabHeaderIcon(0)}>
                    {props.asignacionDnEditable ? null : (
                        <NeoCard>
                            <NeoInnerTitle extra="primero">Detalle del plan</NeoInnerTitle>
                            <NeoColumn sm="3" col="12">
                                <NeoDataSecond
                                    id="planPago"
                                    fact={capitalizar(planPago)}
                                    label="Plan de pago"
                                />
                            </NeoColumn>
                            <NeoColumn sm="4" col="12">
                                <NeoDataSecond
                                    id="dnAsignado"
                                    fact={dnAsignado}
                                    label="Desarrollador de Negocio asignado"
                                />
                            </NeoColumn>
                            <NeoColumn extra="p-text-center" sm="5" col="12">
                                Contacta a {dnAsignado}
                                <NeoButtonSection>
                                    <NeoButtonMain
                                        label={correoDnAsignado}
                                        icon="pi pi-envelope"
                                        className="p-button-rounded"
                                        onClick={(event) => {
                                            abrirCorreo(correoDnAsignado);
                                            event.preventDefault();
                                        }}
                                    />
                                    <NeoButtonMain
                                        label={`+${whatsAppDnAsignado}`}
                                        icon="pi pi-whatsapp"
                                        className="p-button-rounded"
                                        onClick={(event) => {
                                            abrirWhatsApp(
                                                whatsAppDnAsignado,
                                                `¡Hola ${dnAsignado}!`
                                            );
                                            event.preventDefault();
                                        }}
                                    />
                                </NeoButtonSection>
                            </NeoColumn>
                        </NeoCard>
                    )}

                    <NeoCard>
                        <NeoInnerTitle extra="primero">Información general</NeoInnerTitle>

                        <NeoInputText
                            md="4"
                            id="nombre"
                            value={nombre}
                            onChange={setNombre}
                            label="Nombre"
                            error={errores.nombre}
                        />

                        <NeoInputText
                            md="4"
                            id="apellido"
                            value={apellido}
                            onChange={setApellido}
                            label="Apellido"
                            error={errores.apellido}
                        />

                        {props.asignacionDnEditable ? (
                            <NeoDropdown
                                value={activo}
                                options={opcionesEstadoCliente}
                                onChange={(e) => setActivo(e.value)}
                                placeholder=""
                                optionLabel="opcion"
                                optionValue="valor"
                                label="Estado"
                            />
                        ) : (
                            <NeoColumn sm="4" col="12">
                                <NeoDataSecond
                                    id="dnAsignado"
                                    fact={activo === "true" ? "Activa" : "Inactiva"}
                                    label="Estatus de cuenta"
                                />
                            </NeoColumn>
                        )}

                        <NeoInputText
                            md="4"
                            id="correo"
                            value={correo}
                            onChange={setCorreo}
                            label="Correo electrónico"
                            error={errores.correo}
                        />

                        <NeoInputText
                            md="4"
                            id="empresa"
                            value={empresa}
                            onChange={setEmpresa}
                            label="Empresa"
                            error={errores.empresa}
                        />

                        <NeoInputMask
                            md="4"
                            id="telefono"
                            mask="(99) 9999-9999"
                            unmask
                            value={telefono}
                            onChange={setTelefono}
                            label="Teléfono"
                            error={errores.telefono}
                        />
                    </NeoCard>
                    <NeoCard>
                        <NeoInnerTitle extra="primero">
                            Notificaciones por correo
                        </NeoInnerTitle>
                        <NeoInputChip
                            placeholder="Lista de correos que recibirán notificaciones"
                            value={listaNotificaciones}
                            onChange={setListaNotificaciones}
                            separator=","
                            allowDuplicate={false}
                            error={errores.listaNotificaciones}
                            md="11"
                        />

                        <NeoTooltip_icon text="Escribe un correo y agregalo a la lista usando «Enter» ó «,» (coma)" />
                    </NeoCard>

                    {props.asignacionDnEditable ? (
                        <NeoCard>
                            <NeoInnerTitle extra="primero">Plan de pago</NeoInnerTitle>
                            <NeoDropdown
                                md="6"
                                value={planPago}
                                options={props.datos.listaPlanes}
                                onChange={(e) => setPlanPago(e.value)}
                                placeholder=""
                                optionLabel="nombre"
                                label="Plan de pago"
                            />

                            <NeoDropdown
                                md="6"
                                value={dnAsignado}
                                options={props.datos.listaDns}
                                onChange={(e) => setDnAsignado(e.value)}
                                placeholder=""
                                optionLabel="userDetail.fullName"
                                optionValue="id"
                                label="Desarrollador de Negocio asignado"
                            />
                        </NeoCard>
                    ) : null}

                    {props.asignacionDnEditable && mostrarSobregiro ? (
                        <NeoCard>
                            <NeoInnerTitle extra="primero">
                                Sobregiro de validaciones permitido
                            </NeoInnerTitle>
                            {sobregiro &&
                                sobregiro.map((nodo, indice) => {
                                    return (
                                        <NeoGridContainer col="6" key={indice}>
                                            <NeoInnerTitle extra="primero">
                                                {capitalizar(nodo.nombre)}
                                            </NeoInnerTitle>
                                            <NeoDropdown
                                                md="4"
                                                value={nodo.tipo}
                                                options={TIPOS_SOBREGIRO}
                                                onChange={(e) =>
                                                    cambiaSobregiro(e.value, indice, "tipo")
                                                }
                                                placeholder=""
                                                optionValue="id"
                                                optionLabel="nombre"
                                                label="Tipo"
                                            />
                                            {nodo.tipo === SOBREGIRO.FIJO.ID && (
                                                <NeoInputNumber
                                                    md="4"
                                                    key={indice}
                                                    value={nodo.cantidad_permitida}
                                                    onChange={(e) =>
                                                        cambiaSobregiro(
                                                            e.value,
                                                            indice,
                                                            "cantidad_permitida"
                                                        )
                                                    }
                                                    placeholder=""
                                                    optionLabel="nombre"
                                                    label="Cantidad permitida"
                                                    min="0"
                                                    max="1000000"
                                                    error={
                                                        errores?.sobregiro?.[nodo.nombre]
                                                            ?.cantidad_permitida
                                                    }
                                                />
                                            )}
                                            {nodo.tipo === SOBREGIRO.PROMEDIO.ID && (
                                                <>
                                                    <NeoInputNumber
                                                        md="3"
                                                        key={indice}
                                                        value={nodo.porcentaje_permitido}
                                                        onChange={(e) =>
                                                            cambiaSobregiro(
                                                                e.value,
                                                                indice,
                                                                "porcentaje_permitido"
                                                            )
                                                        }
                                                        placeholder=""
                                                        optionLabel="nombre"
                                                        label="Promedio permitido"
                                                        min="0"
                                                        max="100"
                                                        suffix=" %"
                                                        error={
                                                            errores?.sobregiro?.[nodo.nombre]
                                                                ?.porcentaje_permitido
                                                        }
                                                    />

                                                    <NeoInputNumber
                                                        md="4"
                                                        value={calcularPorcentajeValidacionesSobregiro(
                                                            nodo
                                                        )}
                                                        placeholder=""
                                                        label="Cantidad permitida"
                                                        disabled={true}
                                                    />

                                                    <NeoTooltip_icon text="La cantidad permitida se calcula sumando el total de validaciones de las últimas 3 órdenes entre el porcentaje definido." />
                                                </>
                                            )}
                                        </NeoGridContainer>
                                    );
                                })}
                        </NeoCard>
                    ) : null}

                    <NeoCard>
                        <NeoInnerTitle extra="primero">Privacidad de datos</NeoInnerTitle>
                        <NeoDropdown
                            md="7"
                            value={modoPrivado}
                            options={opcionesHabilitado}
                            onChange={(e) => setModoPrivado(e.value)}
                            placeholder=""
                            optionLabel="opcion"
                            optionValue=""
                            label="Política de almacenamiento de datos"
                        />

                        <NeoTooltip_icon text="El modo privado no guardará el dato validado (ej. correo o teléfono), pero aún podrás consultar las estadísticas" />

                        <NeoInputText
                            md="3"
                            value={vigenciaDatos}
                            disabled={modoPrivado?.valor === true}
                            onChange={(event) => setVigenciaDatos(event.target.value)}
                            label="Días que se guardaran los datos validados"
                            error={errores.vigenciaDatos}
                        />
                        <NeoTooltip_icon text="Cantidad de días que se guardaran los datos en el modo normal" />
                    </NeoCard>

                    <NeoCard>
                        <NeoInnerTitle extra="primero">
                            Precios de validaciones
                        </NeoInnerTitle>

                        {precios ? precios : <NeoMessageLoading />}
                    </NeoCard>
                </NeoTabPanel>
                <NeoTabPanel header="Lista blanca" rightIcon={getTabHeaderIcon(1)}>
                    <div className="lista-container grid-no-margin">
                        <NeoCard>
                            <div className="p-d-flex p-flex-column p-flex-md-row p-jc-between p-col-12 p-p-0">
                                <div className="p-d-flex p-ai-center">
                                    <h2 className="text-secondary semibold fs-1-5">
                                        Lista blanca
                                    </h2>
                                </div>
                                <NeoHeaderData
                                    label={listaBlancaCorreoFiltrada.length.toString()}
                                    subLabel="Registros totales"
                                />
                                <NeoHeaderData
                                    label={contadorActivosListaBlancaCorreo.toString()}
                                    subLabel="Registros disponibles"
                                />
                                <div className="lista-blanca-search-container">
                                    <span className="fs-1">Buscar</span>
                                    
                                    <div className="lista-blanca-input-search-group">
                                        <span className="search-icon">
                                            <i className="pi pi-search !bold blacklist-data"></i>
                                        </span>
                                        <NeoInputText extra="lista-input-search" placeholder="Buscar en lista blanca" value={busquedaListaBlanca} onChange={manejarBusquedaListaBlanca} />
                                    </div>
                                </div>
                            </div>
                        </NeoCard>

                        <NeoCard extra="lista-blanca-container p-mb-0">
                            <div className="p-d-flex p-ai-center p-jc-between p-flex-wrap p-flex-lg-nowrap w-full">
                            <div className="p-d-flex p-ai-center renovacion-mensual-input-group">
                                <p className="fs-1 p-my-0">
                                    Añade los correos electrónicos que deben ser detectados como válidos, estos deben ser <span className="bold p-mr-0">correos completos</span>. 
                                </p>
                                <NeoTooltip_icon position="right" extra="p-m-0 p-col-fixed-lb" text={`Correo completo: Correos con un 100% de coincidencia. Ej. "correo@dominio.com".`} />
                                </div>
                                <div className="p-d-flex p-jc-end p-ai-center renovacion-mensual-input-group">
                                    <p className="fs-1 p-my-0">
                                        Renovación mensual
                                    </p>
                                    <NeoTooltip_icon extra="p-m-0 alinear-vertical" position="right" text="Al activar la renovación mensual, se restablecerá el uso de todos los registros al inicio de cada mes." />
                                    <NeoInputSwitch checked={renovacionMensualListaBlanca} onChange={manejarRenovacionMensualListaBlancaOnChange} />
                                </div>

                            </div>
                            <NeoInputChip
                                placeholder="Escribe un correo y presiona Enter"
                                value={listaBlancaCorreoFiltrada}
                                onChange={manejarListaBlancaOnChange}
                                separator=","
                                allowDuplicate={false}
                                error={errores.listaBlancaCorreo}
                                itemTemplate={templateListaBlancaCorreo}
                                extra="rounded-chip fixed-chip lista-blanca-chip"
                            />
                        </NeoCard>
                    </div>
                </NeoTabPanel>
                <NeoTabPanel header="Lista negra" rightIcon={getTabHeaderIcon(2)}>
                    <div className="lista-container grid-no-margin">
                        <NeoCard>
                            <div className="p-d-flex p-jc-between p-col-12 p-p-0">
                                <div className="p-d-flex p-ai-center">
                                    <h2 className="text-secondary semibold fs-1-5">
                                        Lista negra
                                    </h2>
                                </div>

                                <div className="lista-blanca-search-container">
                                    <span className="fs-1">Buscar</span>
                                    
                                    <div className="lista-blanca-input-search-group">
                                        <span className="search-icon">
                                            <i className="pi pi-search !bold blacklist-data"></i>
                                        </span>
                                        <NeoInputText extra="lista-input-search" placeholder="Buscar en las listas" value={busquedaListaNegra} onChange={manejarBusquedaListaNegra} />
                                    </div>
                                </div>
                            </div>
                        </NeoCard>

                        <NeoGridContainer>
                            <NeoColumn md="6" extra="p-d-flex p-pl-0 p-py-0">
                                <NeoCard extra="gap-1/4 p-mb-0">
                                    <div className="p-d-flex p-jc-between p-ai-center w-full">
                                        <h2 className="text-secondary semibold fs-1-5">
                                            Correos
                                        </h2>

                                        <NeoHeaderData
                                            label={String(listaNegraCorreoFiltrada.length)}
                                            subLabel="Registros"
                                            className="p-jc-end"
                                        />
                                    </div>
                                    <div className="dato-terciario p-p-0 p-m-0 p-col p-d-flex p-ai-center descripcion-lista-negra-correos">
                                    <div className="p-d-flex p-ai-center p-flex-wrap p-flex-lg-nowrap w-full">
                                        <p className="label p-my-0">
                                            Los registros pueden ser:
                                            <span className="bold blacklist-domain"> Dominio</span>,
                                            <span className="bold blacklist-data">
                                                {" "}
                                                Correo completo
                                            </span>{" "}
                                            o
                                            <span className="bold blacklist-coincidence">
                                                {" "}
                                                Contenido
                                            </span>
                                        </p>

                                        <NeoTooltip_icon extra="lista-negra-tooltip alinear-vertical alinear-vertical-lista-negra" text={getTooltipTemplateListaNegraCorreo()} position="right"/>
                                        </div>
                                    </div>
                                    <NeoInputChip
                                        placeholder="Escribe un valor y presiona Enter"
                                        value={listaNegraCorreoFiltrada}
                                        onChange={manejarGuardarListaNegraCorreo}
                                        separator=","
                                        allowDuplicate={false}
                                        error={errores.listaNegraCorreo}
                                        itemTemplate={templateListaNegraCorreo}
                                        extra="rounded-chip fixed-chip lista-negra-chip"
                                    />
                                </NeoCard>
                            </NeoColumn>

                            <NeoColumn md="6" extra="p-d-flex p-pr-0 p-py-0">
                                <NeoCard extra="gap-1/4 p-mb-0">
                                    <div className="p-d-flex p-jc-between p-ai-center w-full">
                                        <h2 className="text-secondary semibold fs-1-5">
                                            Teléfonos
                                        </h2>

                                        <NeoHeaderData
                                            label={String(listaNegraTelefonoFiltrada.length)}
                                            subLabel="Registros"
                                            className="p-jc-end"
                                        />
                                    </div>
                                    <div className="dato-terciario p-p-0 p-m-0 p-col p-d-flex p-ai-center descripcion-lista-negra-telefonos">
                                        <p className="label p-col p-m-0 p-p-0">
                                            Los registros deben ser:{" "}
                                            <span className="bold blacklist-data">Dato exacto</span> con
                                            100% de coincidencia
                                        </p>
                                    </div>

                                    <NeoInputChip
                                        placeholder="Escribe un teléfono y presiona Enter"
                                        value={listaNegraTelefonoFiltrada}
                                        onChange={manejarGuardarListaNegraTelefono}
                                        separator=","
                                        allowDuplicate={false}
                                        error={errores.listaNegraTelefono}
                                        itemTemplate={templateListaNegraTelefono}
                                        extra="rounded-chip fixed-chip lista-negra-chip"
                                    />
                                </NeoCard>
                            </NeoColumn>
                        </NeoGridContainer>
                    </div>
                </NeoTabPanel>
            </NeoTabView>

            <NeoButtonSection align="right">
                <NeoButtonMain
                    label="Guardar"
                    onClick={manejaGuardar}
                    loading={props.loading}
                />
            </NeoButtonSection>

            <NeoDialog
                extra="lista-blanca-dialog"
                header="Editar registro"
                contentClassName="p-pb-3"
                visible={visible}
                closable={false}
                onHide={() => setVisible(false)}
            >
                <div className="p-col-12">
                    <label>Correo electrónico</label>
                    <NeoInputText
                        value={String(registroListaBlancaForm.dato_validado)}
                        onChange={(e) => {
                            setRegistroListaBlancaForm({
                                ...registroListaBlancaForm,
                                dato_validado: e.target.value,
                            });
                        }}
                        error={errores.registroListaBlancaForm?.dato_validado}
                    />
                </div>

                <NeoGridContainer col="12">


                            
                                
                    
                    <div className="p-col-6">
                    <div className="p-d-flex p-ai-center p-jc-between p-flex-wrap p-flex-lg-nowrap w-full">
                        <label className="p-mr-2">Límite de usos permitidos</label>
                        <NeoTooltip_icon position="right" extra="p-ml-2" text={
                             renovacionMensualListaBlanca
                            ? "Número máximo de veces que el registro puede ser utilizado durante el mes."
                            : "Número máximo de veces que el registro puede ser utilizado."
                            } />
                        </div>
                        <NeoDropdown
                            editable
                            optionLabel="opcion"
                            optionValue="valor"
                            value={registroListaBlancaForm.validaciones_permitidas}
                            options={listaBlancaOpcionesValidacionesPermitidas}
                            onChange={(e) => {
                               
                                const selectedValue = e.value; 
                                setBotonHabilitado(true);
                                if (selectedValue !== -1 && selectedValue <= registroListaBlancaForm.validaciones_realizadas) {
                                    msgs.current.clear();
                                    let newMsg=`El registro supera el nuevo límite de usos permitidos. Al guardarlo no podrá usarse este mes.`;
                                    if (selectedValue == registroListaBlancaForm.validaciones_realizadas) {
                                        newMsg=`El registro es igual al nuevo límite de usos permitidos. Al guardarlo no podrá usarse este mes.`;
                                    }
                                    if (!renovacionMensualListaBlanca)
                                        {
                                            if (selectedValue == registroListaBlancaForm.validaciones_realizadas) {
                                                newMsg = `El registro es igual al nuevo límite de usos permitidos. Ya no podrá ser usado.`;
                                                
                                            }else {
                                                newMsg = `El límite de usos permitidos debe ser igual o superior al uso actual del registro`;
                                                setBotonHabilitado(false);
                                                 // Mostrar mensaje de error
                                                setTimeout(() => {
                                                    msgs.current.show({
                                                        sticky: true,
                                                        severity: 'error',
                                                        content: (
                                                            <div className="message-container">
                                                                <span className="pi pi-exclamation-triangle icon-with-margin-error"></span>
                                                                <span className="fuente-mensaje-error">{newMsg}</span>
                                                            </div>
                                                        ),
                                                        summary: 'Error',
                                                        closable: false
                                                    });
                                                }, 0);

                                                return;
                                            }
                                        }
                                    
                                        setTimeout(() => {
                                            msgs.current.show({
                                                sticky: true, 
                                                severity: 'info', 
                                                content: (
                                                    <div className="message-container">
                                                        <span className="pi pi-info-circle icon-with-margin"></span>
                                                        <span className="fuente-mensaje">{newMsg}</span>
                                                    </div>
                                                ),
                                                summary: 'Info', 
                                                closable: false 
                                            });
                                        }, 0);
                                    
                                } else {
                                    msgs.current.clear(); 
                                }
                                setRegistroListaBlancaForm({
                                    ...registroListaBlancaForm,
                                    validaciones_permitidas: selectedValue,
                                });
                            }}
                            error={errores.registroListaBlancaForm?.validaciones_permitidas}
                        />
                        
                    </div>
                 
                    <div className="p-col-6 p-grid p-flex-column ">
                        <div className="p-col-12">
                            <div className="p-d-flex p-ai-center p-flex-wrap p-flex-lg-nowrap w-full">
                            <label className="p-mr-2"> {renovacionMensualListaBlanca
                                                        ? "Uso mensual"
                                                        : "Uso"}
                            </label>
                            <NeoTooltip_icon extra="p-ml-2" position="right" text={
                             renovacionMensualListaBlanca
                            ? "Cantidad de veces que el registro fue usado durante el mes."
                            : "Cantidad de veces que el registro fue usado."
                            } />
                            </div>
                        </div>
                        <p className="mensaje-uso-mensual p-d-flex">{
                           registroListaBlancaForm.validaciones_realizadas
                                
                        }</p>
                    </div>
                    <div className="p-col-12 message-espacio fuente-mensaje">
                        <NeoMessages ref={msgs}/>
                    </div>
                    
                </NeoGridContainer>
                
                <NeoButtonSection
                    align="right"
                    col="12"
                    md="6"
                    extra="p-col-align-center p-flex-sm-row p-flex-column sd"
                >
                    <NeoButtonOutlined
                        label="Cancelar"
                        onClick={() => setVisible(false)}
                    />
                    <NeoButtonMain onClick={manejarActualizarElementoListaBlanca} className="p-button-rounded" label="Guardar" disabled={!botonHabilitado}/>
                </NeoButtonSection>
            </NeoDialog>
            <NeoDialog
                extra="lista-blanca-dialog"
                header="Correo duplicado"
                contentClassName="p-pb-3"
                visible={visibleCambioBN}
                closable={false}
                onHide={() => setVisibleCambioBN(false)}
            >
                <div className="p-col-12">
                    <label>El correo que quieres agregar a lista blanca, ya se encuentra en lista negra.</label>
              
                </div>
                <div className="p-col-12">
                <label>¿Cómo deseas continuar? Tu selección eliminará el correo de la lista no seleccionada</label>
                    
                </div>
                
                <NeoButtonSection
                    align="right"
                    col="12"
                    md="6"
                    extra="p-col-align-center p-flex-sm-row p-flex-column sd"
                >
                    <NeoButtonOutlined
                        label="Mantener en lista negra"
                        onClick={() => setVisibleCambioBN(false)}
                    />
                    <NeoButtonMain onClick={manejarCambioListaNegraABlanca} className="p-button-rounded" label="Agregar en lista blanca" />
                </NeoButtonSection>
            </NeoDialog>
            <NeoDialog
                extra="lista-blanca-dialog"
                header="Correo duplicado"
                contentClassName="p-pb-3"
                visible={visibleCambioNB}
                closable={false}
                onHide={() => setVisibleCambioNB(false)}
            >
                <div className="p-col-12">
                    <label>El correo que quieres agregar a lista negra, ya se encuentra en lista blanca.</label>
              
                </div>
                <div className="p-col-12">
                <label>¿Cómo deseas continuar? Tu selección eliminará el correo de la lista no seleccionada</label>
                    
                </div>
                <NeoButtonSection
                    align="right"
                    col="12"
                    md="6"
                    extra="p-col-align-center p-flex-sm-row p-flex-column sd"
                >
                    <NeoButtonOutlined
                        label="Mantener en lista blanca"
                        onClick={() => setVisibleCambioNB(false)}
                    />
                    <NeoButtonMain onClick={manejarCambioListaBlancaANegra} className="p-button-rounded" label="Agregar en lista negra" />
                </NeoButtonSection>
            </NeoDialog>
        </>
    );
};


export default FormularioCuentaUsuario;