import { useState, useEffect, useContext } from 'react';
import { EstadoGlobal } from "components/context/EstadoGlobal";


const useMensajeGlobal = () => {
    const [, setEstadoGlobal] = useContext(EstadoGlobal);
    const [mensajeToast, setMensajeToast] = useState();

    useEffect(() => {
        mensajeToast
            && setEstadoGlobal({mensaje: mensajeToast})

    }, [mensajeToast]) // eslint-disable-line react-hooks/exhaustive-deps

    const setMensaje = (valor) => {
        if (!valor)
            return

        // Por omisión, todos los errores se despliegan por 4.5 segundos
        if (!valor.life && valor.severity === "error")
            valor.life = 4500

        setMensajeToast(valor)
    };

    return setMensaje;
}

export default useMensajeGlobal;
