/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { storage } from "config/firebase";
import { capitalizar, aDivisaLocal, mapearConceptoOrden } from "consts/generales";
import NeoCard from "design/design_components/neo/panel/NeoCard.base";
import NeoColumn from "design/design_components/neo/layout/NeoColumn.base";
import NeoDataMain from 'design/design_components/neo/data/NeoDataMain.base';
import NeoDataSecond from "design/design_components/neo/data/NeoDataSecond.base";
import Async from "./Async";
import customStorage from "consts/customStorage";
import { useComponent } from 'hooks';
import NeoButtonOutlined from 'design/design_components/neo/button/NeoButtonOutlined.base';
import { ORDEN } from 'consts/db.const';
import NeoInnerTitle from 'design/design_components/neo/title/NeoInnerTitle.base';

const storageRef = storage.ref();

const DetalleOrden = (props) => {
    const componente = useComponent();
    const [orden, setOrden] = componente.useCustomState();
    const [dirFacturasRef, setDirFacturasRef] = componente.useCustomState();

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []);

    useEffect(() => {
        if (props.orden) {
            setOrden(props.orden);
            if(props.orden.pago_id) {
                setDirFacturasRef(
                    storageRef.child(
                    `clientes/${props.orden.cliente_id}/pagos/${props.orden.pago_id}/facturas/`
                ));
            }
        }
    }, [props.orden])

    const checarSiHayFacturas = async () => {
        const storageInstance = customStorage(dirFacturasRef);
        return storageInstance.obtenerDetalles();
    }

    const mostrarArchivosFacturas = (storage) => {
        if (storage.totalArchivos > 0) {
            return (
                <NeoDataSecond
                    fact={
                        storage.archivos.map((archivoStorage) => {
                            const renderButton = (archivo) => {
                                const extension = archivo.extension;
                                let icon;
            
                                switch (extension) {
                                    case 'pdf':
                                        icon = 'pi pi-file-pdf';
                                        break;
                                    case 'xml':
                                        icon = 'pi pi-file-o';
                                        break;
                                    default:
                                        break;
                                }
            
                                return (
                                    <NeoButtonOutlined 
                                        label={extension?.toUpperCase()} 
                                        icon={icon}
                                        onClick={() => {
                                            archivoStorage.descargar()
                                        }}
                                    />
                                )
                            }

                            return (
                                <Async
                                    promise={archivoStorage.obtenerDetalles()}
                                    then={renderButton} />
            
                            )
                        })
                    }
                    label="Factura asociada" />
            );
        }
        return null;
    }

    const obtieneEstadoOrden = (orden) => {
        if(orden?.orden_tipo_id === ORDEN.TIPO.AJUSTE_MANUAL.ID) {
            if(orden?.orden_estado_id === ORDEN.ESTADO.FINALIZADO.ID
                || orden?.orden_estado_id === ORDEN.ESTADO.EXPIRADO.ID
            ) {
                return capitalizar(orden?.orden_estado_nombre);
            }
            
            return 'Aplicado';
        } else {
            if(orden.orden_pago_estado_id === ORDEN.PAGO.PAGADA.ID) {
                return capitalizar(orden?.orden_estado_nombre);
            } else if(orden.orden_pago_estado_id === ORDEN.PAGO.PENDIENTE.ID) {
                return capitalizar(orden.orden_pago_estado_nombre);
            }
        }
    }

    return (<>
        <NeoCard>
            <NeoColumn md={3}>
                <NeoDataSecond 
                    fact={orden && `${mapearConceptoOrden(orden.orden_tipo_nombre)}`}
                    label="Concepto" />
            </NeoColumn>
            <NeoColumn md={3}>
                <NeoDataSecond 
                    fact={orden && obtieneEstadoOrden(orden)}
                    label="Estado" />
            </NeoColumn>
            <NeoColumn md={3}>
                {orden?.orden_tipo_id !== ORDEN.TIPO.AJUSTE_MANUAL.ID
                && orden?.orden_tipo_id !== ORDEN.TIPO.VALIDACIONES_INICIALES.ID &&
                    <NeoDataMain
                        fact={orden && `$${aDivisaLocal(orden.precio_total)}`}
                        label="Total" />
                }
            </NeoColumn>

            {orden?.productos_cantidad > 0 &&
            <>
                <NeoColumn md={3}>
                    {orden?.orden_pago_estado_id === ORDEN.PAGO.PAGADA.ID &&
                        <Async
                            promise={checarSiHayFacturas()}
                            then={mostrarArchivosFacturas} />
                    }
                </NeoColumn>

                {orden?.orden_pago_estado_id === ORDEN.PAGO.PAGADA.ID &&
                    <NeoColumn md={3}>
                        <NeoDataSecond fact={orden && orden.fecha_inicio}
                            label="Vigentes desde" />
                    </NeoColumn>
                }

                {(orden?.orden_pago_estado_id === ORDEN.PAGO.PENDIENTE.ID
                    || orden?.orden_pago_estado_id === ORDEN.PAGO.CANCELADA.ID
                ) &&
                    <NeoColumn md={3}>
                        <NeoDataSecond fact={orden && orden.fecha_creacion}
                            label="Fecha de creación" />
                    </NeoColumn>
                }

                {orden?.orden_tipo_id !== ORDEN.TIPO.VALIDACIONES_SOBREGIRO.ID &&
                    <>
                        <NeoColumn md={3}>
                            <NeoDataSecond fact={orden && orden.meses_vigencia}
                                label="Meses de duración" />
                        </NeoColumn>

                        {orden?.orden_pago_estado_id === ORDEN.PAGO.PAGADA.ID &&
                            <NeoColumn md={3}>
                                <NeoDataSecond fact={orden && orden.fecha_vigencia}
                                    label="Fecha de expiración" />
                            </NeoColumn>
                        }
                    </>
                }
            </>}
        </NeoCard>
        {orden?.nota &&
            <NeoCard>
                <NeoColumn md={12}>
                    <NeoInnerTitle extra="primero">
                            Comentario
                    </NeoInnerTitle>
                    <NeoDataSecond 
                        fact={''}
                        label={orden.nota}
                    />
                </NeoColumn>
            </NeoCard>
        }
        </>)
}

export default DetalleOrden;