import React, { useEffect } from "react";
import NeoTitleMain from "design/design_components/neo/title/NeoTitleMain.base";
import NeoGridContainer from "design/design_components/neo/layout/NeoGridContainer.base";
import Icono from 'design/assets/img/mnv/icons/icon-validacion.svg';

import ModalValidacionArchivo from 'components/common/ModalValidacionArchivo';
import HistoricoValidacionesAdmin from "components/common/HistoricoValidacionesAdmin";
import { useCliente, useComponent } from "hooks";


const Validaciones = () => {
    const componente = useComponent();
    const cliente = useCliente();
    const [iniciarValidacionArchivo, setIniciarValidacionArchivo] = componente.useCustomState(false);

    useEffect(() => {
        return () => {
            componente.desmontar();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const alFinalizarValidacionArchivo = () => {
        cliente.recargar();
    }

    return (<>
        {
            iniciarValidacionArchivo
            && <ModalValidacionArchivo
                mostrarModalProceso={setIniciarValidacionArchivo}
            />
        }

        <NeoGridContainer>
            <NeoTitleMain
                icon={Icono}
                col="12"
                md="6"
                title="Centro de validaciones global" />
        </NeoGridContainer>

        <HistoricoValidacionesAdmin 
            alFinalizarValidacionArchivo={alFinalizarValidacionArchivo}
        />

    </>);

};


export default Validaciones;