import { useState } from 'react';
import NeoCalendar from "design/design_components/neo/form/NeoCalendar.base";
import NeoButtonSection from "design/design_components/neo/layout/NeoButtonSection.base";
import NeoDialog from "design/design_components/neo/overlay/NeoDialog.base";
import NeoButtonMain from "design/design_components/neo/button/NeoButtonMain.base";
import { DateTime } from 'luxon';

const ModalFiltroFechaValidacion = (props) => {
    const hoy = DateTime.now().setLocale('es-MX');
    const fechaFiltroInicioInicial = new Date(hoy.minus({ month: 1 }));
    const [fechaFiltroInicio, setFechaFiltroInicio] = useState(
        props.fechaInicio || fechaFiltroInicioInicial
    );
    const fechaFiltroFinInicial = new Date();
    const [fechaFiltroFin, setFechaFiltroFin] = useState(
        props.fechaFin || fechaFiltroFinInicial
    );

    return (
        <NeoDialog
            style={{width: '25vw'}}
            breakpoints={{'960px': '75vw', '640px': '100vw'}} 
            visible={props.visible}
            onHide={props.onHide}
            header="Filtrar validaciones"
        >

            <NeoCalendar col="12"
                label="Fecha de inicio"
                value={fechaFiltroInicio}
                onChange={(e) => setFechaFiltroInicio(e.value)}
                locale="es-MX"
                dateFormat="dd/mm/yy"
                showButtonBar
                minDate={new Date(hoy.minus({ year: 1 }))}
                maxDate={new Date()}
            />

            <NeoCalendar col="12"
                label="Fecha de término"
                value={fechaFiltroFin}
                onChange={(e) => setFechaFiltroFin(e.value)}
                locale="es-MX"
                dateFormat="dd/mm/yy"
                showButtonBar
                minDate={new Date(hoy.minus({ year: 1 }))}
                maxDate={new Date()}
            />

            <NeoButtonSection align="center" col="12" md="6"
                extra="p-col-align-center p-flex-sm-row p-flex-column">
                <NeoButtonMain label="Reiniciar filtro"
                    onClick={() => {
                        setFechaFiltroInicio(fechaFiltroInicioInicial);
                        setFechaFiltroFin(fechaFiltroFinInicial);
                        
                        if(typeof props.onEstablece === 'function') {
                            props.onEstablece(fechaFiltroInicioInicial, fechaFiltroFinInicial);
                        }
                        if(typeof props.btnReiniciar === 'function') {
                            props.btnReiniciar(fechaFiltroInicioInicial, fechaFiltroFinInicial);
                        }
                    }} 
                />
                <NeoButtonMain label="Aplicar"
                    onClick={() => {
                        if(typeof props.onEstablece === 'function') {
                            props.onEstablece(fechaFiltroInicio, fechaFiltroFin);
                        }
                        if(typeof props.btnAplicar === 'function') {
                            props.btnAplicar(fechaFiltroInicio, fechaFiltroFin);
                        }
                    }}
                />
            </NeoButtonSection>
        </NeoDialog>
    )
}

export default ModalFiltroFechaValidacion;