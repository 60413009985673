import React from "react";
import NeoColumn from "../layout/NeoColumn.base";

import BaseTable from "../../sisu/table/BaseTable.sisu";

const NeoTable = React.forwardRef((props, ref) => {

    return (
        <NeoColumn extra="p-p-0 p-col-12">
            <BaseTable {...props} ref={ref}>
                {props.children}
            </BaseTable>
            {
                props.error
                    ? <small className="p-invalid p-d-block">
                        {props.error}
                    </small>
                    : null
            }
        </NeoColumn>
    );
});

export default NeoTable;