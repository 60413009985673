import { useEffect, useRef } from "react";
import { useComponent } from 'hooks';
import NeoTable from "design/design_components/neo/table/NeoTable.base";
import { capitalizar } from "consts/generales";
import NeoTableColumn from 'design/design_components/neo/table/NeoTableColumn.base';


const TablaResultadoValidacion = (props) => {
    const componente = useComponent();
    const [datosIniciales, setDatosIniciales] = componente.useCustomState([]);
    const dt = useRef(null);
    const [hayAdicional, setHayAdicional] = componente.useCustomState(false);
    const [hayAsesor, setHayAsesor] = componente.useCustomState(false);

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []);

    useEffect(() => {
        props && props.datos
            && setDatosIniciales(props.datos);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);


    useEffect(() => {
        if (datosIniciales && datosIniciales.length) {
            setHayAdicional(Boolean(
                datosIniciales.find(o => o.dato_adicional !== null)
            ));

            setHayAsesor(Boolean(
                datosIniciales.find(o => o.asesor !== null)
            ));
        }
    }, [datosIniciales])


    const registroBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Registro</span>
            {rowData.dato_validado}
        </>);
    }

    const resultadoBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Resultado</span>
            {rowData.resultado && capitalizar(rowData.resultado)}
        </>);
    }

    const descripcionBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Descripción</span>
            {rowData.descripcion}
        </>);
    }

    const adicionalBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Datos adicionales</span>
            {rowData.dato_adicional}
        </>);
    }

    const asesorBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Asesor</span>
            {rowData.asesor}
        </>);
    }

    return (<>
        <NeoTable
            value={datosIniciales}
            paginator
            rows={10}
            removableSort
            ref={dt}
            emptyMessage="No hay registros">

            <NeoTableColumn
                field="registro"
                header="Registro"
                filter
                sortable
                body={registroBodyTemplate}
                filterMatchMode="contains" />

            <NeoTableColumn
                field="resultado"
                header="Resultado"
                filter
                sortable
                body={resultadoBodyTemplate}
                filterMatchMode="contains" />

            <NeoTableColumn
                field="descripcion"
                header="Descripción"
                filter
                sortable
                body={descripcionBodyTemplate}
                filterMatchMode="contains" />

            {
                hayAdicional
                && <NeoTableColumn
                    field="dato_adicional"
                    header="Datos adicionales"
                    filter
                    sortable
                    body={adicionalBodyTemplate}
                    filterMatchMode="contains" />
            }

            {
                hayAsesor
                && <NeoTableColumn
                    field="asesor"
                    header="Asesor"
                    filter
                    sortable
                    body={asesorBodyTemplate}
                    filterMatchMode="contains" />
            }

        </NeoTable>
    </>)
}


export default TablaResultadoValidacion;