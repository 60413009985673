import React, { useEffect, useState } from "react";
import NeoTitleChart from "../title/NeoTitleChart.base";
import NeoColumn from "../layout/NeoColumn.base";
import NeoChart from "./NeoChart.base";
import NeoChartDataLegend from "./NeoChartDataLegend.base";

const NeoPieChartCustom = (props) => {
    const [data, setData] = useState();
    const [title, setTitle] = useState();
    const [colors, setColors] = useState();

    useEffect(() => {
        props.data && setData(props.data);
        props.title && setTitle(props.title);
        props.colors && setColors(props.colors);
    }, [props])

    return (<>
        <NeoColumn sm="8" md="8" col="8" extra=" testlines">
            <NeoChart
                data={data}
                formatNumber={props.formatNumber}
                dataKey={props.dataKey}
                dataName={props.dataName}
                colors={props.colors[props.colorsCharByKey]} />
        </NeoColumn>

        <NeoColumn sm="4" md="4" col="4"
            extra="p-d-flex p-jc-center p-flex-column ">

            {
                data && colors && data.map((e, index) => {
                    return <NeoChartDataLegend
                        color={colors[props.colorsLabelByKey][index]}
                        fact={e[props.dataKey]}
                        contractNumber={props.contractNumber}
                        label={e[props.dataName]}
                        key={index}
                        icon={e[props.dataStatus]} />
                })
            }

        </NeoColumn>

        {props.noChartTitle ?
            null
            :
            <NeoTitleChart extra="p-md-8">{title}</NeoTitleChart>
        }


    </>);
}

export default NeoPieChartCustom;