import { SelectButton } from "primereact/selectbutton";
import React from "react";

export default function BaseSelectButton(props) {
  return (
    <>
      <SelectButton
        className={`
            ${props.custom ? props.custom : ""}
            ${props.extra ? props.extra : ""}
            `}
        {...props}
      />
    </>
  );
}
