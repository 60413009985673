import React from "react";

const BaseSingleContainer = (props) => {
  return (<div className={`${props.custom ? props.custom : ""} ${props.extra ? props.extra : ""}`}>
    <div className={props.subCustom}>
      {props.children}
    </div>
  </div>);
};

export default BaseSingleContainer;