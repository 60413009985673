import NeoDialog from "design/design_components/neo/overlay/NeoDialog.base";
import NeoGridContainer from "design/design_components/neo/layout/NeoGridContainer.base";
import NeoButtonMain from "design/design_components/neo/button/NeoButtonMain.base";
import NeoInputText from "design/design_components/neo/form/NeoInputText.base";
import NeoInputTextarea from "design/design_components/neo/form/NeoInputTextarea.base";
import NeoDialogConfirmMethod from 'design/design_components/neo/overlay/NeoDialogConfirmMethod.base';
import { useComponent } from 'hooks';

const ModalPagoManual = (props) => {
    const componente = useComponent();
    const [referencia, setReferencia] = componente.useCustomState('');
    const [nota, setNota] = componente.useCustomState('');
    const [errores, setErrores] = componente.useCustomState({});

    const validaFormulario = () => {
        const errores = {
            referencia: "",
            nota: ""
        };

        let hayErrores = false;

        if (!referencia) {
            errores.referencia = 'Campo requerido';
            hayErrores = true;
        }
        if (!nota) {
            errores.nota = 'Campo requerido';
            hayErrores = true;
        }

        setErrores(errores);
        return !hayErrores;
    }

    const onEnviarFormulario = () => {
        if (validaFormulario()) {
            if (props.confirmDialog) {
                NeoDialogConfirmMethod({
                    message: '¿Deseas marcar esta orden como pagada?',
                    header: 'Confirmación',
                    icon: 'pi pi-exclamation-triangle',
                    acceptLabel: 'Si',
                    rejectLabel: 'No',
                    accept: () => enviarFormulario()
                });
            } else {
                enviarFormulario();
            }
        }
    }

    const enviarFormulario = () => {
        if (typeof props.onEnviarFormulario === 'function') {
            props.onEnviarFormulario({
                referencia,
                nota
            });
        }
    }

    return (
        <NeoDialog
            visible={props.visible}
            onHide={props.onClickBtnCerrar}
            header={props.header ? props.header : 'Marcar como pagada'}
            footer={
                <NeoButtonMain label="Guardar" onClick={onEnviarFormulario} />
            }
        >
            <NeoInputText
                value={referencia}
                onChange={(e) => setReferencia(e.target.value)}
                label="Referencia"
                col="6"
                error={errores.referencia}
            />
            <NeoInputTextarea
                rows={4}
                label="Nota"
                md="12"
                value={nota}
                onChange={(e) => setNota(e.target.value)}
                error={errores.nota}
            />
        </NeoDialog>
    );
}

ModalPagoManual.defaultProps = {
    confirmDialog: true
}

export default ModalPagoManual;