import { useEffect } from "react";
import NeoButtonMain from "design/design_components/neo/button/NeoButtonMain.base";
import NeoDialogConfirmMethod from 'design/design_components/neo/overlay/NeoDialogConfirmMethod.base';
import customStorage from "consts/customStorage";
import useMensajeGlobal from "hooks/useMensajeGlobal";
import { useComponent } from "hooks";

const BotonEliminarArchivosStorage = (props) => {
    const componente = useComponent();
    const setMensajeGlobal = useMensajeGlobal();
    const [, _setEliminandoTodo] = componente.useCustomState(false);

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []);

    useEffect(() => {
        if (props.storageRef === undefined) {
            console.error('Referencia no recibida por props');
            return;
        }
    }, []);

    const setEliminandoTodo = (eliminando) => {
        if (typeof props.setAlEliminarTodo === 'function') {
            props.setAlEliminarTodo({
                eliminando,
                storageRef: props.storageRef
            });
        }
        _setEliminandoTodo(eliminando);
    }

    const eliminarTodoOnClick = () => {
        const eliminar = () => {
            setEliminandoTodo(true);
            const storageInstance = customStorage(props.storageRef);
            storageInstance.eliminar().then((resultado) => {
                if (typeof props?.onEliminarTodo === 'function') {
                    props.onEliminarTodo();
                }
            }).catch((error) => {
                setMensajeGlobal({
                    severity: 'warn',
                    detail: error.mensaje
                });
            }).finally(() => {
                setEliminandoTodo(false);
            });
        }

        if (props.confirmarEliminarTodo) {
            NeoDialogConfirmMethod({
                message: '¿Deseas eliminar todos los archivos?',
                header: 'Confirmación',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Si',
                rejectLabel: 'No',
                accept: () => eliminar()
            });
        } else {
            eliminar();
        }
    }

    return (
        <NeoButtonMain
            label='Eliminar archivos'
            onClick={eliminarTodoOnClick} />
    )
}

BotonEliminarArchivosStorage.defaultProps = {
    confirmarEliminarTodo: true
}

export default BotonEliminarArchivosStorage;