import { useEffect } from "react";
import { functions } from "config/firebase";
import { useHistory } from "react-router-dom";
import useProgressSpinner from "hooks/useProgressSpinner"
import useMensajeGlobal from "hooks/useMensajeGlobal";
import NeoTitleMain from "design/design_components/neo/title/NeoTitleMain.base";
import NeoGridContainer from "design/design_components/neo/layout/NeoGridContainer.base";
import NeoButtonSection from "design/design_components/neo/layout/NeoButtonSection.base";
import NeoButtonMain from "design/design_components/neo/button/NeoButtonMain.base";
import NeoTable from "design/design_components/neo/table/NeoTable.base";
import NeoTableColumn from 'design/design_components/neo/table/NeoTableColumn.base';
import NeoColumn from "design/design_components/neo/layout/NeoColumn.base";
import NeoFlexContainer from "design/design_components/neo/layout/NeoFlexContainer.base";
import NeoButtonOutlined from "design/design_components/neo/button/NeoButtonOutlined.base";
import { useComponent } from "hooks";
import FormularioAgregarProductoModal from "components/common/FormularioAgregarProductoModal";
import useCliente from 'hooks/useCliente';
import Icono from 'design/assets/img/mnv/icons/icon-validacion.svg';
import { capitalizar, convierteAMoneda, formateaANumeroLocal } from "consts/generales";
import NeoInputTextarea from "design/design_components/neo/form/NeoInputTextarea.base";
import NeoCard from "design/design_components/neo/panel/NeoCard.base";
import NeoInnerTitle from "design/design_components/neo/title/NeoInnerTitle.base";
import NeoDataSecond from "design/design_components/neo/data/NeoDataSecond.base";

const getTipoValidaciones = functions.httpsCallable('getTipoValidaciones');
const crearPaqueteValidaciones = functions.httpsCallable('ordenes-crear');

let nuevaValidacion = {
    tipoValidacion: '',
    cantidad: 1,
    precioUnitario: 0,
    precioTotal: 0,
};

const ClientesPaquetesEspeciales = (props) => {
    const componente = useComponent({
        data: {
            comentario: ''
        }
    });
    const cliente = useCliente();
    const [, setSpinnerCargando] = useProgressSpinner(true)
    const despliegaMensaje = useMensajeGlobal();
    const [tipoValidacion, setTiposValidacion] = componente.useCustomState([]);
    const [carrito, setCarrito] = componente.useCustomState([]);
    const [precioTotal, setPrecioTotal] = componente.useCustomState(0);
    const [producto, setProducto] = componente.useCustomState();
    const [muestraModal, setMuestraModal] = componente.useCustomState(false);
    let historial = useHistory();

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        obtieneTiposValidaciones();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    //Actualiza precio total
    useEffect(() => {
        let total = "-";
        if (carrito) {
            total = carrito.reduce((acumulado, nodo) => {
                return acumulado + nodo.cantidad * nodo.precioUnitario;
            }, 0)
        }

        setPrecioTotal(total);
    }, [carrito]) // eslint-disable-line react-hooks/exhaustive-deps

    const obtieneTiposValidaciones = async () => {
        try {
            const tipos = {};
            const tipoValidaciones = await getTipoValidaciones();
            tipoValidaciones.data.forEach((elemento, key) => {
                tipos[elemento.nombre] = {
                    id: elemento.id
                }
            })

            setTiposValidacion(tipos);
        } catch (error) {
            despliegaMensaje({
                severity: "error",
                summary: `Error:`,
                detail: error.message
            });
        } finally {
            setSpinnerCargando(false);
        }
    }

    const enviarFormulario = async () => {
        try {
            setSpinnerCargando(true);
            await crearPaqueteValidaciones({
                clienteId: cliente.id,
                productos: carrito,
                comentario: componente.data.comentario 
                    ? componente.data.comentario 
                    : null
            });
            despliegaMensaje({
                severity: 'success',
                summary: 'Bloque de validaciones creado'
            });
            historial.goBack();
        } catch (error) {
            despliegaMensaje({
                severity: "error",
                summary: `Error:`,
                detail: error.message
            });
        } finally {
            setSpinnerCargando(false);
        }
    }

    const cantidadBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Cantidad</span>
            {rowData.cantidad.toLocaleString("es-MX")}
        </>);
    }

    const tipoProductoBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Tipo de validación</span>
            Validaciones de {capitalizar(rowData.tipoValidacion)}
        </>);
    }

    const precioUnitarioBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Precio unitario</span>
            {convierteAMoneda(rowData.precioUnitario)}
        </>);
    }

    const totalBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Precio unitario</span>
            {convierteAMoneda(Number(rowData.precioUnitario) * Number(rowData.cantidad)) + " MXN"}
        </>);
    }

    const editaProducto = (producto) => {
        setProducto(producto);
        setMuestraModal(true);
    }

    const borraProducto = (producto) => {
        let productos = carrito.filter(e => e.tipoValidacion !== producto.tipoValidacion);
        setCarrito(productos);
    }

    const nuevoProducto = () => {
        setProducto(() => nuevaValidacion);
        setMuestraModal(true);
    };

    const agregaProducto = (datos) => {
        let productos = [...carrito]
        let producto = { ...datos }

        let indice = productos.findIndex(e => e.tipoValidacion === producto.tipoValidacion);

        if (indice >= 0) {
            productos[indice] = producto;
        } else {
            productos.push(producto);
        }

        setCarrito(productos);
    };

    const accionesBodyTemplate = (rowData) => {
        return (<>
            <NeoFlexContainer jc="end">
                <NeoButtonOutlined
                    icon="pi pi-pencil"
                    extra="p-button-rounded  p-mr-2"
                    alternative="secondary"

                    onClick={() => editaProducto(rowData)} />
                <NeoButtonOutlined
                    icon="pi pi-trash"
                    extra="p-button-rounded "
                    alternative="secondary"
                    onClick={() => borraProducto(rowData)} />

            </NeoFlexContainer>
        </>);
    }

    return (<>
        <NeoGridContainer>
            <NeoTitleMain icon={Icono} col="12" md="6"
                title="Crear bloque de validaciones" />

                <NeoButtonSection align="right" col="12" md="6"
                    extra="p-col-align-center p-flex-sm-row p-flex-column">
                    <NeoButtonMain
                        label="Agregar producto"
                        onClick={nuevoProducto}
                    />

                </NeoButtonSection>
        </NeoGridContainer>

        <NeoTable
            value={carrito}
            rows={10}
            emptyMessage="No hay validaciones dentro del bloque"
            emptyMessageSecondary="Agrega un producto al bloque de validaciones"
            extra="no-filters">
            <NeoTableColumn
                field="cantidad"
                header="Cantidad"
                body={cantidadBodyTemplate} />
            <NeoTableColumn
                field="tipo"
                header="Producto"
                body={tipoProductoBodyTemplate} />
            <NeoTableColumn
                field="precioUnitario"
                header="Precio unitario"
                body={precioUnitarioBodyTemplate} />
            <NeoTableColumn
                header="Total"
                body={totalBodyTemplate} />
            <NeoTableColumn
                body={accionesBodyTemplate} />
        </NeoTable>

        <NeoFlexContainer extra="p-pb-3  p-pt-5 " jc="end" >
            <NeoDataSecond extra="text-align-right"
                fact={precioTotal
                    ? `$${formateaANumeroLocal(precioTotal)} MXN`
                    : "-"
                }
                label="Precio total" />
        </NeoFlexContainer>

        <FormularioAgregarProductoModal
            mensaje="Agrega productos al bloque de validaciones"
            onSubmit={agregaProducto}
            muestraModal={muestraModal}
            setMuestraModal={setMuestraModal}
            preciosProductos={tipoValidacion}
            autoPrecio={false}
            datosIniciales={producto}
            carrito={carrito}

        />

        <NeoCard>
            <NeoColumn>
                <NeoInnerTitle extra="primero">
                        Comentario
                </NeoInnerTitle>
                <NeoGridContainer>
                    <NeoInputTextarea
                        rows={4}
                        label={"Comentario (Opcional)"}
                        md="12"
                        value={componente.data.comentario}
                        onChange={(e) => {
                            const value = e.target.value;

                            componente.data.comentario = value;
                        }}
                    />
                </NeoGridContainer>
            </NeoColumn>
        </NeoCard>

        {carrito.length > 0 && 
            <NeoGridContainer>
                <NeoButtonSection align="right" >
                    <NeoButtonMain label="Crear" onClick={enviarFormulario} />
                </NeoButtonSection>
            </NeoGridContainer>   
        }
    </>);
};


export default ClientesPaquetesEspeciales;