import { useEffect } from "react";
import { functions } from "config/firebase";
import useMensajeGlobal from "hooks/useMensajeGlobal";
import useProgressSpinner from "hooks/useProgressSpinner"
import NeoButtonSection from "design/design_components/neo/layout/NeoButtonSection.base";
import NeoGridContainer from "design/design_components/neo/layout/NeoGridContainer.base";
import NeoTitleSecond from "design/design_components/neo/title/NeoTitleSecond.base";
import TablaBitacora from "components/common/TablaBitacora"
import { useComponent } from "hooks";
import useCliente from "hooks/useCliente";

const Bitacora = (props) => {
    const componente = useComponent();
    const cliente = useCliente();
    const getBitacora = componente.useAsync(functions.httpsCallable("usuario-obtieneBitacora"));
    const getTiposEvento = componente.useAsync(functions.httpsCallable("usuario-bitacoraListarTiposEvento"));
    const despliegaMensaje = useMensajeGlobal();
    const [, setSpinnerCargando] = useProgressSpinner(false);
    const [datosBitacora, setDatosBitacora] = componente.useCustomState();
    const [eventosBitacora, setEventosBitacora] = componente.useCustomState();

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        obtieneEventosBitacora();
        obtieneTiposEventosBitacora();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const obtieneEventosBitacora = () => {
        getBitacora({ clienteId: cliente.id })
            .then((resp) => {
                setDatosBitacora(resp.data);
            }).catch((error) => {
                console.log("Error: ", error);
                despliegaMensaje({
                    severity: "error",
                    summary: "Error: ",
                    detail: error.message
                });
            }).finally(() => {
                setSpinnerCargando(false)
            });
    };

    const obtieneTiposEventosBitacora = () => {
        getTiposEvento()
            .then((resp) => {
                setEventosBitacora(resp.data);
            }).catch((error) => {
                console.log("Error: ", error);
                despliegaMensaje({
                    severity: "error",
                    summary: "Error: ",
                    detail: error.message
                });
            }).finally(() => {
                setSpinnerCargando(false)
            });
    };

    return (<>
        <NeoGridContainer >
            <NeoTitleSecond col="12" md="6"
                // icono="{Icono}"
                title="Bitácora de la cuenta" />

            <NeoButtonSection align="right" col="12" md="6"
                extra="p-col-align-center p-flex-sm-row p-flex-column">
            </NeoButtonSection>
        </NeoGridContainer>

        <TablaBitacora
            datosIniciales={datosBitacora}
            eventosBitacora={eventosBitacora}
            cargando={componente.isLoading}
        />
    </>);
};


export default Bitacora;
