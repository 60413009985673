import { useContext } from 'react';
import ClienteContext from 'components/context/ClienteContext';

const useCliente = () => {
    const {
        cliente,
        setClienteId,
        cargarDatosCliente,
        data
    } = useContext(ClienteContext);

    const hayCliente = () => {
        return Boolean(cliente);
    }

    const montar = (id) => {
        return setClienteId(id);
    }

    const recargar = async () => {
        return await cargarDatosCliente();
    }

    const desmontar = () => {
        return setClienteId();
    }

    return {
        hayCliente,
        montar,
        recargar,
        desmontar,
        data,
        ...cliente
    }
}

export default useCliente;