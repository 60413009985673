import React from "react";


const NeoDataMain = (props) => {
  return (<>
    <div className={`dato-principal ${props.extra ? props.extra : ""} `}>
      <div className="dato">{props.fact}</div>
      <div className="etiqueta">{props.label}</div>
    </div>
  </>);
};

export default NeoDataMain;