import React from "react";
import BaseCardMain from "../../sisu/panel/BaseCardMain.sisu";

const NeoCardPrincipal = (props) => {
  return (<>
    <BaseCardMain custom="card-principal p-field p-col" {...props}>
      {props.children}
    </BaseCardMain>
  </>);
};

export default NeoCardPrincipal;