import NeoCardMessage from "../panel/NeoCardMessage.base";


const NeoMessageLoading = (props) => {
    return (<>
        <NeoCardMessage
            {...props}
            title={props.title}
            message={props.message}
        />
    </>);
};

NeoMessageLoading.defaultProps = {
    title: "Cargando datos",
    message: "Favor de esperar un momento"
}

export default NeoMessageLoading;