import { createContext, useState, useEffect } from "react";
import firebase from "firebase/app";
import { functions } from "config/firebase";
import { useComponent } from "hooks";
import { cache } from 'consts/generales';
import { ROLE_USER } from "consts/neoadm";

const UsuarioContext = createContext({});

export const UsuarioProvider = (props) => {
    const componente = useComponent({
        data: {
            loading: true,
            loggins: 0,
            logouts: 0
        }
    });
    
    const auth = firebase.auth();
    const [persona, setPersona] = useState();
    const [usuarioAuth, setUsuarioAuth] = useState(() => {
        return auth.currentUser;
    });

    useEffect(() => {
        auth.onAuthStateChanged(usuarioAuth => {
            if(!usuarioAuth) {
                componente.data.loading = false;
            }

            setUsuarioAuth(usuarioAuth);
        });
    }, [])

    useEffect(() => {
        if(usuarioAuth) {
            if(cache.get('session')) {
                cache.clear('session');
            }
            
            componente.data.loggins++;
            cargarDatosPersona();
        } else {
            if(componente.data.loggins > 0) {
                componente.data.logouts++;
                setPersona();
            }
        }
    }, [usuarioAuth])

    const cargarDatosPersona = async () => {
        if(!usuarioAuth) {
            return null;
        }

        const datosPersona = await functions.httpsCallable("getDatosPersona")();

        const persona = {
            uid: datosPersona.data.uid,
            email: datosPersona.data.userDetail.email,
            emailVerified: usuarioAuth.emailVerified,
            isSubAccount:  datosPersona.data.isSubAccount,
            rol: datosPersona.data.roleUserId
        };

        // Persona es cliente
        if(persona.rol === ROLE_USER.CUSTOMER.ID) {
            try {
                const resp = await functions.httpsCallable("getDatosCliente")();
                persona.cliente = resp.data;
                if(resp.data !== null) {
                    persona.cliente.id = persona.cliente.clienteId;
                }
            } catch(error) {
                console.error("Error al obtener los datos como ciente");
            }

            if(persona.cliente !== null) {
                try {
                    const resp = await functions.httpsCallable("obtieneValidacionesPorCliente")();
                    persona.cliente.saldo = resp.data;
                } catch(error) {
                    console.error("Error al obtener los saldos como ciente");
                }
            }
        }

        setPersona(persona);

        componente.data.loading = false;

        return persona;
    }

    return (
        <UsuarioContext.Provider value={{
            data: componente.data,
            auth,
            usuarioAuth,
            persona,
            cargarDatosPersona
        }}>
            {props.children}
        </UsuarioContext.Provider>
    )
}

export default UsuarioContext;