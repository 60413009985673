import React from "react";
import BaseDialogConfirm from '../../sisu/overlay/BaseDialogConfirm.sisu'

const NeoDialogConfirm = (props) => {

  return (
    <BaseDialogConfirm acceptLabel="Si" rejectLabel="No" {...props}/>
  )
};


export default NeoDialogConfirm;