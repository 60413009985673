import React from "react";
import { ProgressSpinner } from 'primereact/progressspinner';

const BaseSpinner = (props) => {

  return (
    <ProgressSpinner {...props}/>
    );
};

export default BaseSpinner;