import { Accordion } from 'primereact/accordion';

const BaseAccordion = (props) => {
    return (
        <Accordion {...props} 
        className={`${props.custom ? props.custom : ""} ${props.extra ? props.extra : ""}`}>
            {props.children}
        </Accordion>
    )
}

export default BaseAccordion;