import React from "react";


const NeoDataThird = (props) => {
  return (
    <div className={`dato-terciario 
        ${props.extra ? props.extra : ""} ${props.md ? `p-md-${props.md}` : ""} ` }>
      {
        props.fact
          ? <div className="dato">{props.fact}</div>
          : null
      }
      {
        props.label
          ? <div className="label">{props.label}</div>
          : null
      }
    </div>
  );
};

export default NeoDataThird;