import NeoNavBarSecondaryClient from "design/design_components/neo/menu/NeoNavBarSecondaryClient.base";
import NeoNavBar from "design/design_components/neo/menu/NeoNavBar.base";
import NeoTooltip from "design/design_components/neo/overlay/NeoTooltip.base";
import NeoDataSecond from "design/design_components/neo/data/NeoDataSecond.base";
import { capitalizar, aDivisaLocal } from "consts/generales";
import RUTAS from "components/router/Rutas.json"
import NeoColumn from "design/design_components/neo/layout/NeoColumn.base";
import NeoAutoGrid from "design/design_components/neo/layout/NeoAutoGrid.base";
import useCliente from "hooks/useCliente";

const NavBarSecundarioAdmin = (props) => {
    const cliente = useCliente();
    
    const menu = [
        {
            label: 'Dashboard',
            redirect: RUTAS.admin.clientes.tablero
        },
        {
            label: 'Validaciones',
            redirect: RUTAS.admin.clientes.validaciones.lista
        },
        {
            label: 'Cuenta',
            items: [{
                label: "Cuenta",
                redirect: RUTAS.admin.clientes.editar
            }, {
                label: "Órdenes",
                redirect: RUTAS.admin.clientes.ordenes.lista
            }, {
                label: "Histórico de pagos",
                redirect: RUTAS.admin.clientes.pagos.historico
            }, {
                label: "Contratos",
                redirect: RUTAS.admin.clientes.contratos.lista
            }
            ]
        },
    ]


    return (
        <NeoNavBarSecondaryClient>
            <NeoAutoGrid>
                <NeoDataSecond
                    fact={`${cliente.nombre} ${cliente.apellido}`}
                    label="Nombre" />

                <NeoDataSecond
                    fact={capitalizar(cliente.plan.nombre)}
                    label="Tipo de plan" />

                <NeoDataSecond
                    fact={cliente?.activo
                            ? "Activa"
                            : "Inactiva"
                    }
                    label="Estatus" />

                <NeoDataSecond
                    fact={cliente.dn
                        && cliente.dn.nombre}
                    label="DN asignado" />

                <NeoDataSecond label="Validaciones disp.">
                    <NeoAutoGrid className="validacionesDisponibles">
                        {cliente?.saldo
                            && aDivisaLocal(cliente.saldo.reduce((total, saldo) => {
                                return total + (saldo.cantidad || 0);
                            }, 0))
                        }
                    </NeoAutoGrid>
                </NeoDataSecond>

                {cliente.plan.nombre === 'postpago' &&
                    <NeoDataSecond label="Sobregiro usado">
                        <NeoAutoGrid className="validacionesSobregiro">
                            {cliente?.saldo
                                && aDivisaLocal(cliente.saldo.reduce((total, saldo) => {
                                    return total + (saldo.sobregiroUsado || 0);
                                }, 0))
                            }
                        </NeoAutoGrid>
                    </NeoDataSecond>
                }

                <NeoNavBar md="3" lg="auto"
                    extra="navbar-secundario p-jc-between"
                    items={menu}
                    displayLogo={false}
                />

            </NeoAutoGrid>

            <NeoTooltip
                target=".validacionesDisponibles"
                mouseTrack
                position="bottom"
                mouseTrackTop={10}
            >
                {cliente?.saldo
                    && cliente.saldo.map((saldo) => {
                        return <>
                            <NeoColumn col="12" extra="p-text-center">
                                Validaciones de {capitalizar(saldo.tipoValidacion)}
                            </NeoColumn>
                            <NeoColumn col="12" extra="p-text-center">
                                {aDivisaLocal(saldo.cantidad || 0)}
                            </NeoColumn>
                            <hr/>
                        </>
                    }, 0)
                }
            </NeoTooltip>

            <NeoTooltip
                target=".validacionesSobregiro"
                mouseTrack
                position="bottom"
                mouseTrackTop={10}
            >
                {cliente?.saldo
                    && cliente.saldo.map((saldo) => {
                        return <>
                            <NeoColumn col="12">
                                Sobregiro de {capitalizar(saldo.tipoValidacion)}
                            </NeoColumn>
                            <NeoColumn col="12">
                                Permitido: {aDivisaLocal(saldo.sobregiroPermitido || 0)}
                            </NeoColumn>
                            <NeoColumn col="12">
                                Usado: {aDivisaLocal(saldo.sobregiroUsado || 0)}
                            </NeoColumn>
                            <NeoColumn col="12">
                                Disponible: {aDivisaLocal(saldo.sobregiroDisponible || 0)}
                            </NeoColumn>
                            <hr/>
                        </>
                    }, 0)
                }
            </NeoTooltip>

        </NeoNavBarSecondaryClient>
    )
};


export default NavBarSecundarioAdmin;