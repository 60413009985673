import { useRef, useEffect } from "react";
import { useComponent } from 'hooks';
import { useHistory } from "react-router-dom";
import NeoTable from "design/design_components/neo/table/NeoTable.base";
import NeoButtonMain from "design/design_components/neo/button/NeoButtonMain.base";
import NeoCalendar from "design/design_components/neo/form/NeoCalendar.base";
import { DateTime } from "luxon";
import StorageFileUpload from 'components/common/StorageFileUpload';
import Async from 'components/common/Async';
import { aDivisaLocal, aCadenaFechaConFormatoLocal } from "consts/generales";
import NeoTableColumn from 'design/design_components/neo/table/NeoTableColumn.base';
import ArchivosStorage from "./ArchivosStorage";
import customStorage from "consts/customStorage";
import BotonEliminarArchivosStorage from "./BotonEliminarArchivosStorage";

const TablaPagos = (props) => {
    const componente = useComponent();
    const history = useHistory();
    const dt = useRef(null);
    const [datos, setDatos] = componente.useCustomState([]);
    const [fechaSeleccionada, setFechaSeleccionada] = componente.useCustomState();
    const [subiendoArchivo, setSubiendoArchivo] = componente.useCustomState({
        state: false
    });
    const [alEliminarTodo, setAlEliminarTodo] = componente.useCustomState({
        eliminando: false
    });

    useEffect(() => {
        setDatos(props.datos);
        return () => { componente.desmontar(); };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(alEliminarTodo.eliminando === true) {
            setDatos([...props.datos]);
        }
    }, [alEliminarTodo])

    const ordenBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title"># Orden</span>
            {rowData.orden_id}
        </>);
    }

    const fechaBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Fecha</span>
            {aCadenaFechaConFormatoLocal(rowData.fecha)}
        </>);
    }

    const montoBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Monto</span>
            ${aDivisaLocal(rowData.monto)}
        </>);
    }

    const facturaBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Factura</span>
            {obtieneTipoFactura(rowData)}
        </>);
    }

    const obtieneTipoFactura = (rowData) => {
        if (props.storageRef) {
            const storageRef = props.storageRef.child(`${rowData.id}/facturas/`);
            const storageInstance = customStorage(storageRef);
            const promesa = storageInstance.obtenerDetalles();

            const alResolverPromesa = (resultado) => {
                switch (resultado.totalArchivos) {
                    case 0:
                        return !seEstanSubiendoArchivos(rowData.id) &&
                            <NeoButtonMain
                                label="Generar" onClick={() => generarFactura(rowData)} />;
                    default:
                        return !seEstanSubiendoArchivos(rowData.id) &&
                            <ArchivosStorage
                                storageRef={storageRef}
                                setAlEliminarTodo={setAlEliminarTodo} />
                }
            }

            return (
                <Async
                    promise={promesa}
                    then={alResolverPromesa} />
            );
        } else {
            return;
        }
    }


    const generarFactura = (rowData) => {
        if (typeof props.generarFactura === 'function') {
            if (props.generarFactura(rowData)) {
                console.log('Petición enviada');
            }
        }
    }

    const accionesBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Acciones</span>
            {obtieneAccionesConFactura(rowData)}
        </>);
    }

    const obtieneAccionesConFactura = (rowData) => {
        if (props.storageRef) {
            const maximoArchivos = 6;
            const storageRef = props.storageRef.child(`${rowData.id}/facturas/`);
            const storageInstance = customStorage(storageRef);
            const obtenerDetalles = storageInstance.obtenerDetalles();

            const alObtenerDetalles = (detalles) => {
                if (!seEstanEliminandoTodosLosArchivos(rowData.id)) {
                    const capacidadMaximaArchivo = 5000000; // 5 Megabytes

                    return <> 
                    {detalles.totalArchivos < maximoArchivos && 
                        <StorageFileUpload
                            storageRef={storageRef}
                            setSubiendo={setSubiendoArchivo}
                            alFinalizar={(resultados) => {
                                // Envia correo de factura manual subida
                                if (typeof props.alFinalizarSubidaFactura === 'function') {
                                    props.alFinalizarSubidaFactura(rowData, resultados);
                                }
                            }}
                            maximoArchivos={maximoArchivos}
                            extensionesPermitidas={["pdf", "xml"]}
                            capacidadMaximaArchivo={capacidadMaximaArchivo} />
                    }

                    {detalles.totalArchivos > 0 &&
                        <BotonEliminarArchivosStorage 
                            storageRef={storageRef}
                            setAlEliminarTodo={setAlEliminarTodo} />
                    }
                </>
                }
                return null;
            }

            return (
                <Async
                    promise={obtenerDetalles}
                    then={alObtenerDetalles} />
            );
        } else {
            return;
        }
    }

    const seEstanSubiendoArchivos = (facturaId) => {
        const storageRef = props.storageRef.child(`${facturaId}/facturas/`);

        return subiendoArchivo.subiendo === true
            && subiendoArchivo?.storageRef?.fullPath === storageRef.fullPath;
    }

    const seEstanEliminandoTodosLosArchivos = (facturaId) => {
        const storageRef = props.storageRef.child(`${facturaId}/facturas/`);

        return alEliminarTodo.eliminando === true
            && alEliminarTodo?.storageRef?.fullPath === storageRef.fullPath;
    }

    const onRowSelect = (event) => {
        if (event?.originalEvent?.target?.nodeName === 'TD') {
            if (props.enlaceAOrden) {
                history.push(props.enlaceAOrden, {
                    id: event.data.orden_id
                });
            }
        }
    }

    const onCambiaFecha = (e) => {
        let fecha;
        if (e.value) {
            fecha = DateTime.fromJSDate(e.value).toFormat('yyyy-LL-dd');
        }

        dt.current.filter(fecha, 'fecha', 'contains');
        setFechaSeleccionada(e.value);
    }

    const filtroFecha = <NeoCalendar
        value={fechaSeleccionada}
        onChange={onCambiaFecha}
        dateFormat="dd MM yy"
        placeholder="Fecha"
        mask="99/99/9999"
    />

    return (<>
        <NeoTable
            value={props.datos}
            paginator
            rows={10}
            removableSort
            ref={dt}
            emptyMessage="No hay pagos registrados"
            selectionMode="single"
            onRowSelect={onRowSelect}
        >

            <NeoTableColumn
                field="orden_id"
                header="# Orden"
                filter
                sortable
                body={ordenBodyTemplate}
                filterMatchMode="contains" />

            <NeoTableColumn
                field="fecha"
                header="Fecha"
                filter
                sortable
                filterElement={filtroFecha}
                body={fechaBodyTemplate}
            />

            <NeoTableColumn
                field="monto"
                header="Monto"
                filter
                sortable
                body={montoBodyTemplate}
                filterMatchMode="contains" />

            {/* <NeoTableColumn
                field="factura"
                header="Factura"
                body={facturaBodyTemplate} /> */}

            {
                props.verAcciones
                && <NeoTableColumn
                    field="acciones"
                    header="Acciones"
                    body={accionesBodyTemplate} />
            }
        </NeoTable>
    </>)
}


export default TablaPagos;