import { useEffect } from "react";
import { functions, storage } from "config/firebase";
import useProgressSpinner from "hooks/useProgressSpinner";
import useMensajeGlobal from "hooks/useMensajeGlobal";
import NeoTitleMain from "design/design_components/neo/title/NeoTitleMain.base";
import NeoMessageLoading from "design/design_components/neo/messages/NeoMessageLoading.base";
import NeoGridContainer from "design/design_components/neo/layout/NeoGridContainer.base";
import TablaPagos from "components/common/TablaPagos";
import NeoCardPrincipal from "design/design_components/neo/panel/NeoCardPrincipal.base";
import NeoInnerBlankCardMainText from "design/design_components/neo/table/NeoInnerBlankCardMainText.base";
import Icono from 'design/assets/img/mnv/icons/icon-contracts.svg';
import { useComponent } from "hooks";
import RUTAS from "components/router/Rutas.json";
import useUsuario from "hooks/useUsuario";
import useCliente from "hooks/useCliente";

const obtenerListaPagos = functions.httpsCallable('pago-listar');
const facturaEnviaCorreoSubidaManual = functions.httpsCallable('factura-enviaCorreoSubidaManual');
const facturaGenerar = functions.httpsCallable('factura-generar');


const ClientesPagosHistorico = () => {
    const componente = useComponent();
    const usuario = useUsuario();
    const persona = usuario.persona;
    const cliente = useCliente();
    const [cargando, setSpinnerCargando] = useProgressSpinner(false)
    const despliegaMensaje = useMensajeGlobal();
    const [datos, setDatos] = componente.useCustomState([]);
    const storageRef = storage.ref();
    const [verAcciones, setVerAcciones] = componente.useCustomState(false);
    let [dirFacturasRef, setDirFacturasRef] = componente.useCustomState();

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        obtieneDatos();
        setDirFacturasRef(storageRef.child(`clientes/${cliente.id}/pagos/`));
        
        switch(persona.rol) {
            case 'admin':
            case 'contador': {
                setVerAcciones(false);
                break;
            }
            default: 
                break;
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const obtieneDatos = async () => {
        setSpinnerCargando(true);
        obtenerListaPagos({
            clienteId: cliente.id
        }).then((respuesta) => {
            setDatos(respuesta.data);
        }).catch((error) => {
            despliegaMensaje({
                severity: "error",
                summary: `Error:`,
                detail: error.message
            });
        }).finally(() => {
            setSpinnerCargando(false);
        });
    }

    const alFinalizarSubidaFactura = (datos) => {
        facturaEnviaCorreoSubidaManual({
            clienteId: cliente.id,
            ordenId: datos.orden_id
        }).catch((error) => {
            despliegaMensaje({
                severity: "error",
                summary: `Error:`,
                detail: error.message
            });
        });
    }

    const generarFactura = (datos) => {
        facturaGenerar({
            clienteId: cliente.id,
            ordenId: datos.orden_id,
            pagoId: datos.id,
        }).catch((error) => {
            despliegaMensaje({
                severity: "error",
                summary: `Error:`,
                detail: error.message
            });
        });
    }

    return (<>
        <NeoGridContainer>
            <NeoTitleMain
                icon={Icono}
                title="Historial de pagos" />
        </NeoGridContainer>

        {
            cargando
                ? <NeoMessageLoading col={12} />
                : datos.length >= 1
                    ? <TablaPagos
                        datos={datos}
                        verAcciones={verAcciones}
                        storageRef={dirFacturasRef}
                        permitirEliminarTodo={verAcciones}
                        enlaceAOrden={RUTAS.admin.clientes.ordenes.detalle}
                        alFinalizarSubidaFactura={alFinalizarSubidaFactura}
                        generarFactura={generarFactura}
                    />
                    : <NeoCardPrincipal>
                        <NeoInnerBlankCardMainText>
                            Aún no tienes pagos registrados
                        </NeoInnerBlankCardMainText>
                    </NeoCardPrincipal>
        }
    </>);

};


export default ClientesPagosHistorico;