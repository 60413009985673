import { useEffect } from 'react';
import { BrowserRouter, Switch } from "react-router-dom";
import TagManager from 'react-gtm-module';

import RuterRender from './RuterRender';
import RUTAS from './Rutas.json';
import useUsuario from "hooks/useUsuario";
import { ROLE_USER } from "consts/neoadm";

// Publicas
import Acceso from "components/views/Acceso";

// Admin - DN
import { ClienteProvider } from "components/context/ClienteContext";
import RutasAdministracion from './RutasAdministracion';

// Cliente
import RutasCliente from './RutasClientes';

const Ruter = () => {
    const gtmId = process.env.REACT_APP_GOOGLE_TAG_MANAGER;
    const trackId = process.env.REACT_APP_GOOGLE_ANALYTICS;
    const usuario = useUsuario();

    useEffect(() => {
        if(process.env.NODE_ENV !== 'development') {
            TagManager.initialize({
                gtmId
            })
        }
    }, [])

    const loadGoogleAnalytics = (ruterProps) => {
        if(process.env.NODE_ENV !== 'development') {
            if (!window.gtag) return
            window.gtag('config', trackId, { send_page_view: true });
            window.gtag('set', 'page_path', ruterProps.location.pathname);
            window.gtag('event', 'page_view');

            TagManager.dataLayer({
                event: 'page_view',
                path: ruterProps.location.pathname
            })
        }
    }

    const unloadGoogleAnalytics = (ruterProps) => {
        if(process.env.NODE_ENV !== 'development') {
            if (!window.gtag) return
            window.gtag('config', trackId, { send_page_view: false });
        }
    }
    
    return (
        <BrowserRouter>
            <Switch>

                {/* Publicas */}
                <RuterRender path={RUTAS.login}>
                    <Acceso path={RUTAS.login}/>
                </RuterRender>

                <RuterRender path={RUTAS.registro}>
                    <Acceso path={RUTAS.registro}/>
                </RuterRender>

                {/* Admin */}
                <RuterRender path={RUTAS.admin.tablero} exact={false}
                    redirect={usuario.esEmpleado} redirectValue={false} 
                    onLoad={unloadGoogleAnalytics} >
                    <ClienteProvider>
                        <RutasAdministracion/>
                    </ClienteProvider>
                </RuterRender>

                {/* Cliente */}
                <RuterRender path={RUTAS.clientes.tablero} exact={false}
                    redirect={() => {
                        if(usuario.estaLogueado() && usuario.persona?.rol === ROLE_USER.CUSTOMER.ID) {
                            return true;
                        }

                        return false;
                    }}
                    redirectValue={false} 
                    onLoad={loadGoogleAnalytics}
                    component={RutasCliente}/>

                {/* Defecto */}
                <RuterRender path='/' 
                    onLoad={loadGoogleAnalytics}
                    component={Acceso}/>

            </Switch>
        </BrowserRouter>
    );
}

export default Ruter;
