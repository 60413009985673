/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useComponent } from 'hooks';

const Async = (props) => {
    const componente = useComponent();
    const [elemento, setElemento] = componente.useCustomState(props.child || null);

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []);

    useEffect(() => {
        if(typeof props?.promise?.then !== 'function') {
            console.error('No se esta recibiendo ninguna promesa a cumplir');
            return;
        }
    }, []);

    useEffect(() => {
        if(typeof props?.promise?.then === 'function') {
            (async () => {
                props.promise.then((resultado) => {
                    if(typeof props?.then === 'function') {
                        setElemento(props.then(resultado));
                    }
                }).catch((error) => {
                    if(typeof props?.catch === 'function') {
                        props.catch(error);
                    }
                }).finally(() => {
                    if(typeof props?.finally === 'function') {
                        props.finally();
                    }
                })
            })();
        }
    }, [props.promise])

    return elemento;
}

export default Async;