import React from "react";

const BaseNavBarSecondaryClient = (props) => {

  return (<div className={`${props.custom ? props.custom : ""}
          ${props.extra ? props.extra : ""}`}>
    {props.children}
  </div>)
};

export default BaseNavBarSecondaryClient;
