import BaseTabPanel from "../../sisu/panel/BaseTabPanel.sisu";

const NeoTabPanel = (props) => {
    return (
        <BaseTabPanel {...props}>
            {props.children}
        </BaseTabPanel>
    )
}

export default NeoTabPanel;