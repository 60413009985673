import { functions } from "config/firebase";
import NeoDialog from "design/design_components/neo/overlay/NeoDialog.base";
import NeoButtonMain from "design/design_components/neo/button/NeoButtonMain.base";
import { useComponent, useMensajeGlobal, useUsuario } from "hooks";

const ModalCrearCuenta = () => {
    const component = useComponent();
    const usuario = useUsuario();
    const despliegaMensaje = useMensajeGlobal();

    const fnRegistrar = component.useAsync(functions.httpsCallable("usuario-registrar"));

    const registrar = async () => {
        try {
            await fnRegistrar();
            window.location.reload();
        } catch(error) {
            despliegaMensaje({
                severity: "error",
                summary: `Error`,
                detail: 'Ocurrió un error al tratar de crear tu cuenta, inténtalo nuevamente'
            });
        }
    }

    return (
        <NeoDialog 
            header={"Cuenta no configurada"}
            visible={true}
            draggable={false}
            closable={false}
            footer={<>
                <NeoButtonMain
                    label="Registrarse"
                    autoFocus
                    onClick={registrar}
                    loading={component.isLoading}
                />
                {!component.isLoading &&
                    <NeoButtonMain
                        label="Cerrar sesión"
                        autoFocus
                        onClick={usuario.cerrarSesion}
                    />
                }
            </>}
        >
            Esta cuenta no está configurada, regístrate para tener acceso a NeoValidador.
        </NeoDialog>
    )
}

export default ModalCrearCuenta;