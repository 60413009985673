/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";
import { useComponent, useInput } from 'hooks';
import { capitalizar } from "consts/generales";
import NeoInnerTitle from "design/design_components/neo/title/NeoInnerTitle.base";
import NeoInputText from "design/design_components/neo/form/NeoInputText.base";
import NeoButtonMain from "design/design_components/neo/button/NeoButtonMain.base";
import NeoButtonSection from "design/design_components/neo/layout/NeoButtonSection.base";
import NeoCard from "design/design_components/neo/panel/NeoCard.base";
import NeoColumn from "design/design_components/neo/layout/NeoColumn.base";
import NeoDropdown from "design/design_components/neo/form/NeoDropdown.base";
import TablaPreciosGenerales from "components/common/TablaPreciosGenerales";


const FormularioAdministracionGeneral = (props) => {
    const componente = useComponent();
    const [validsCorreoRegalo] = useInput(0);
    const [validsTelefonoRegalo] = useInput(0);
    const [errores, setErrores] = componente.useCustomState({})
    const [validar, setValidar] = componente.useCustomState(false);
    const mensajeForm = useRef(null);
    const [preciosGenerales, setPreciosGenerales] = componente.useCustomState();
    const [validacionesRegalo, setValidacionesRegalo] = componente.useCustomState();
    const [listaDns, setListaDns] = componente.useCustomState([]);
    const [dnOmision, setDnOmision] = componente.useCustomState();

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []);

    useEffect(() => {
        props?.listaDns && setListaDns(props.listaDns);
        props?.dnOmision && setDnOmision(props.dnOmision.id);
    }, [props]);


    useEffect(() => {
        if (!props.preciosGenerales) {
            return;
        }

        let tablas = Object.keys(props.preciosGenerales).map((tipo, index) => {
            return <TablaPreciosGenerales
                key={index}
                titulo={capitalizar(tipo)}
                datos={props.preciosGenerales[tipo]}
            />
        });

        setPreciosGenerales(tablas);
    }, [props.preciosGenerales]);


    useEffect(() => {
        if (props?.validacionesGratis
            && props.validacionesGratis.length) {

            setValidacionesRegalo(props?.validacionesGratis);

        }
    }, [props?.validacionesGratis])


    useEffect(() => {
        if (validar) {
            validaFormulario();
        }

        mensajeForm.current && mensajeForm.current.clear()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validsCorreoRegalo, validsTelefonoRegalo]);

    const editaValidacionesRegalo = (evento, indice) => {
        // console.log(evento, indice)
        let nuevoValor = [...validacionesRegalo];
        nuevoValor[indice].cantidad = Number(evento.target.value);
        setValidacionesRegalo(nuevoValor);
    }


    const validaFormulario = () => {
        let esValido = true;
        const campoCantidadesGratuitas = /^([0-9]{0,3})$/;
        // const campoSoloNumero = /^[1-9][0-9]*$/
        let msgErrorValidCorreo = "";
        let msgErrorValidTelefono = "";
        let msgErrorPreciosGenerales = "";

        setValidar(true);

        if (!campoCantidadesGratuitas.test(validsCorreoRegalo)) {
            msgErrorValidCorreo = "Sólo se permiten (máximo 3) números enteros positivos";
            esValido = false;
        }
        if (!campoCantidadesGratuitas.test(validsTelefonoRegalo)) {
            msgErrorValidTelefono = "Sólo se permiten (máximo 3) números enteros positivos";
            esValido = false;
        }


        // if (Array.isArray(preciosGenerales)) {
        //     preciosGenerales.forEach((precio) => {
        //         if (!precio.precio) {
        //             msgErrorPreciosGenerales = "El precio de la validación no puede esta vacio";
        //             esValido = false;
        //         }
        //     })
        // }

        setErrores({
            regaloValidCorreo: msgErrorValidCorreo,
            regaloValidTelefono: msgErrorValidTelefono,
            preciosGenerales: msgErrorPreciosGenerales
        });

        return esValido;
    };

    const manejaGuardar = () => {
        setValidar(true);

        if (!validaFormulario()) {
            return false;
        }

        //Formulario válido, listo para enviar datos
        props
            && props.onSubmit
            && props.onSubmit({
                dnOmision
            });
        // window.scrollTo(0, 0);
    };


    return (<>
        <NeoCard>
            <NeoInnerTitle extra="primero">
                Validaciones de regalo para nuevos clientes
            </NeoInnerTitle>
            {
                validacionesRegalo && validacionesRegalo.length
                && validacionesRegalo.map((nodo, indice) => {
                    return (<NeoInputText
                        col="6"
                        key={indice}
                        maxLength="3"
                        value={validacionesRegalo[indice].cantidad}
                        onChange={e => editaValidacionesRegalo(e, indice)}
                        label={capitalizar(validacionesRegalo[indice].nombre)}
                    // error={errores.regaloValidCorreo}
                    />
                    )
                })

            }

            <NeoColumn>
                <NeoInnerTitle>DN por omisión</NeoInnerTitle>
                <NeoDropdown
                    value={dnOmision}
                    options={listaDns}
                    onChange={(e) => setDnOmision(e.value)}
                    placeholder=""
                    optionLabel="userDetail.fullName"
                    optionValue="id"
                    label="DN por omisión para nuevos clientes" />
            </NeoColumn>


            <NeoInnerTitle>
                Precio por omisión de validaciones
            </NeoInnerTitle>

            {preciosGenerales}

            {errores.preciosGenerales}

        </NeoCard>

        <NeoButtonSection align="right" >
            <NeoButtonMain 
                label="Guardar" 
                onClick={manejaGuardar} 
                loading={props.loading}
            />
        </NeoButtonSection>
    </>);
};


export default FormularioAdministracionGeneral;