import { useEffect } from "react";
import Loading from "./Loading";
import useUsuario from 'hooks/useUsuario';
import { generarID, cache } from "consts/generales";
import RUTAS from '../router/Rutas.json';

const Inicio = (_props) => {
    const usuario = useUsuario();
    const urlLogin = process.env.REACT_APP_URL_LOGIN;
    const urlLoginNeoAdm = process.env.REACT_APP_URL_LOGIN_ADMIN;
    const urlLogoutNeoAdm = process.env.REACT_APP_URL_LOGOUT_ADMIN;
    const urlRegisterNeoAdm = process.env.REACT_APP_URL_REGISTER_ADMIN;
    const PROJECT = 'NeoValidador';

    useEffect(() => {
        const process = async () => {
            if(usuario.data.logouts > 0) {
                const urlLogout = new URL(urlLogoutNeoAdm);
                urlLogout.searchParams.set('continue', urlLogin);
                window.location.replace(urlLogout);
            } else {
                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);
                const token = urlParams.get('token');
                if(token) {
                    const session = cache.get('session');
                    try {
                        await usuario.iniciaSesionConToken(token, session);
                    } catch(error) {
                        if(error?.code === 'permission-denied') {
                            window.location.href = RUTAS.login;
                        }
                    }
                } else {
                    const session = {
                        id: generarID()
                    }
            
                    cache.set('session', session);
            
                    const protocol = window.location.protocol;
                    const host = window.location.host;
                    const pathname = window.location.pathname !== '/' 
                        ? window.location.pathname
                        : '';
            
                    const currentUrl = `${protocol}//${host}/${pathname}`;

                    let urlToRedirect;
                    if(_props?.path === RUTAS.registro)  {
                        urlToRedirect = new URL(urlRegisterNeoAdm)
                    } else {
                        urlToRedirect = new URL(urlLoginNeoAdm)
                    }
                    
                    urlToRedirect.searchParams.set('continue', currentUrl);
                    urlToRedirect.searchParams.set('session', session.id);
                    urlToRedirect.searchParams.set('app', PROJECT);
                    window.location.replace(urlToRedirect);
                }
            }
        }

        process().catch(console.error);
    }, []);

    return (<Loading />);
};


export default Inicio;
