import { createContext, useEffect } from "react";
import web from 'core/scripts/web';
import { useComponent } from "hooks";

const DefaultValues = {
    cargando: false,
    procesosArchivos: []
};
const StateInit = web.app.state.get();
web.object.assign.recursive(StateInit, DefaultValues);

const EstadoGlobal = createContext([StateInit, () => {}, () => {}]);

const EstadoGlobalProvider = (props) => {
    const componente = useComponent();
    const [state, setState] = componente.useCustomState(StateInit);

    useEffect(() => {
        web.app.state.set(StateInit);
    }, [])

    const updateState = (propState) => {
        setState((prevState) => {
            const newState = { ...prevState };
            web.object.assign.recursive(newState, propState);
            web.app.state.set(newState);
            return newState;
        });
    }

    const resetState = () => {
        web.app.state.set(DefaultValues);
        setState(DefaultValues);
    }

    return (
        <EstadoGlobal.Provider value={[state, updateState, resetState]}>
            {props.children}
        </EstadoGlobal.Provider>
    );
}

export { EstadoGlobal, EstadoGlobalProvider };