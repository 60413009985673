import { useEffect } from "react";
import { functions } from "config/firebase";
import { useHistory } from "react-router-dom";
import useProgressSpinner from "hooks/useProgressSpinner"
import useMensajeGlobal from "hooks/useMensajeGlobal";
import NeoTitleSecond from "design/design_components/neo/title/NeoTitleSecond.base";
import NeoGridContainer from "design/design_components/neo/layout/NeoGridContainer.base";
import FormularioValidacionesModificar from "components/common/FormularioValidacionesModificar";
import StatusActualCliente from "components/common/StatusActualCliente";
import NeoMessageLoading from "design/design_components/neo/messages/NeoMessageLoading.base";
import { useComponent } from "hooks";
import useCliente from "hooks/useCliente";

const getTipoValidaciones = functions.httpsCallable('getTipoValidaciones');
const crearPaqueteValidaciones = functions.httpsCallable('ordenes-crear');


const ClientesValidacionesAjustar = (props) => {
    const componente = useComponent();
    const cliente = useCliente();
    const [cargando, setSpinnerCargando] = useProgressSpinner(true)
    const despliegaMensaje = useMensajeGlobal();
    const [tiposValidaciones, setTiposValidaciones] = componente.useCustomState([]);
    const [saldoValidaciones, setSaldoValidaciones] = componente.useCustomState();
    let historial = useHistory();

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        obtieneTiposValidaciones();
        setSaldoValidaciones(cliente.saldo);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const obtieneTiposValidaciones = () => {
        getTipoValidaciones()
            .then((respuesta) => {
                setTiposValidaciones(respuesta.data);
            }).catch((error) => {
                despliegaMensaje({
                    severity: "error",
                    summary: `Error:`,
                    detail: error.message
                });
            }).finally(() => {
                setSpinnerCargando(false);
            });
    };

    const enviarFormulario = async (formulario) => {
        setSpinnerCargando(true);
        crearPaqueteValidaciones({
            clienteId: cliente.id,
            tipoPaquete: "Ajuste manual",
            productos: [{
                cantidad: formulario.cantidad,
                tipoValidacion: formulario.tipoValidacion.nombre,
                tipoValidacionId: formulario.tipoValidacion.id,
                precioUnitario: 0
            }],
            comentario: formulario.comentario
        }).then((respuesta) => {
            // Se registra la información
            despliegaMensaje({
                severity: "success",
                summary: "",
                detail: "Ajuste realizado",
            });

            cliente.recargar();

            historial.goBack();
        }).catch((error) => {
            despliegaMensaje({
                severity: "error",
                summary: `Error:`,
                detail: error.message
            });
        }).finally(() => {
            setSpinnerCargando(false);
        });
    }

    return (<>
        <NeoGridContainer>
            <NeoTitleSecond
                col="12"
                title="Ajuste de validaciones" />
        </NeoGridContainer>

        {
            cargando
                ? <NeoMessageLoading />
                : (<>
                    <StatusActualCliente validaciones={saldoValidaciones} />

                    <FormularioValidacionesModificar
                        saldo={saldoValidaciones}
                        tipoValidaciones={tiposValidaciones}
                        onSubmit={enviarFormulario} />
                </>)
        }

    </>);
};


export default ClientesValidacionesAjustar;