import React, { useEffect } from "react";
import NeoInnerTitle from "design/design_components/neo/title/NeoInnerTitle.base";
import NeoCard from "design/design_components/neo/panel/NeoCard.base";
import NeoColumn from "design/design_components/neo/layout/NeoColumn.base";
import NeoGridContainer from "design/design_components/neo/layout/NeoGridContainer.base";
import NeoTitleChart from "design/design_components/neo/title/NeoTitleChart.base";
import NeoDataMain from "design/design_components/neo/data/NeoDataMain.base";
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
// import { COLORES } from "../../consts/generales";
import { capitalizar, aDivisaLocal } from "../../consts/generales";
import { useComponent } from 'hooks';

const GraficaResumenAnual = (props) => {
    const componente = useComponent();
    const [mostrarCantidad, setMostrarCantidad] = componente.useCustomState(false);

    useEffect(() => {
        if(Array.isArray(props.datosAnuales)) {
            if(props.datosAnuales.length > 0) {
                if(props.datosAnuales[0]?.cantidad) {
                    setMostrarCantidad(true);
                }
            }
        } else {
            setMostrarCantidad(false);
        }
    }, [props])

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip leyenda-gráfica-barras">
                    <p className="title">{label}</p>
                    {
                        payload.map((nodo, indice) => {
                            // en « nodo.fill » está el color de la etiqueta
                            return <div className={` leyenda-grafica-unidad `} key={indice}>
                                <span className="codigo-color" style={{ backgroundColor: nodo.fill }} ></span>
                                <div className="leyenda-container" >
                                    <span className="leyenda-dato" >
                                        {aDivisaLocal(nodo.value)}
                                    </span>
                                    <span className="leyenda-etiqueta">
                                        {`${capitalizar(nodo.dataKey)}`}
                                    </span>
                                </div>
                            </div>
                        })
                    }
                    {/* <p className="desc">{`${payload[0].dataKey} ${payload[0].value}`}</p> */}
                </div>
            );
        }
        return null;
    };

    return (<>
        <NeoCard>
            <NeoInnerTitle extra="primero">Estadísticas del último año</NeoInnerTitle>
            <NeoGridContainer col="12" extra="p-py-0 ">


                <NeoColumn md="6" sm="6" col="12" >

                    <NeoDataMain label="Promedio mensual"
                        extra="p-text-center"
                        fact={props && props.promedio
                            ? aDivisaLocal(props.promedio)
                            : "-"} />
                </NeoColumn>

                <NeoColumn md="6" sm="6" col="12" >
                    <NeoDataMain label="Validaciones totales "
                        extra="p-text-center"
                        fact={props && props.total
                            ? aDivisaLocal(props.total)
                            : "-"} />
                </NeoColumn>
            </NeoGridContainer>
            <NeoColumn extra="testlines p-pt-0">



                <ResponsiveContainer width="100%" height={248}>
                    <BarChart
                        width={200}
                        height={248}
                        barSize={30}
                        data={props.datosAnuales
                            ? props.datosAnuales
                            : []
                        }
                        stackOffset="sign"
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5
                        }} >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="nombre" />
                        <YAxis />
                        <Tooltip content={<CustomTooltip />} />
                        <Tooltip />
                        <Legend />

                        {mostrarCantidad
                            ? 
                                <Bar
                                    dataKey="cantidad"
                                    fill="#17468D"
                                    stackId="stack"
                                />
                            : <>
                                <Bar
                                    dataKey="válido"
                                    fill="#17468D"
                                    stackId="stack"
                                />
                                <Bar
                                    dataKey="inválido"
                                    fill="#FF9B35"
                                    stackId="stack"
                                />
                                <Bar
                                    dataKey="riesgoso"
                                    fill="#FDD850"
                                    stackId="stack"
                                />
                                <Bar
                                    dataKey="desconocido"
                                    fill="#BAC3C3"
                                    stackId="stack"
                                />
                            </>
                        }
                    </BarChart>

                </ResponsiveContainer>

            </NeoColumn>

        </NeoCard>
    </>);
};

export default GraficaResumenAnual;