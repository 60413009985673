/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";
import { useComponent } from 'hooks';
import NeoTable from "design/design_components/neo/table/NeoTable.base";
import NeoTableColumn from 'design/design_components/neo/table/NeoTableColumn.base';
import { capitalizar } from "consts/generales";
import { DateTime } from 'luxon';
import useUsuario from "hooks/useUsuario";
import NeoMultiSelect from "design/design_components/neo/form/NeoMultiSelect.base";
import ModalFiltroFechaValidacion from "./ModalFiltroFechaValidacion";
import NeoButtonOutlined from "design/design_components/neo/button/NeoButtonOutlined.base";

const TablaClientesListado = (props) => {
    const usuario = useUsuario();
    const componente = useComponent();
    const dt = useRef(null);
    const [planSeleccionado, setPlanSeleccionado] = componente.useCustomState(null);
    const [dnSeleccionado, setDnSeleccionado] = componente.useCustomState(null);
    const [listaClientes, setListaClientes] = componente.useCustomState();
    const [listaPlanes, setListaPlanes] = componente.useCustomState([]);
    const [listaDns, setListaDns] = componente.useCustomState([]);
    const [mostrarModalFiltroFecha, setMostrarModalFiltroFecha] = componente.useCustomState(false);
    const [filtroFecha, setFiltroFecha] = componente.useCustomState({
        inicio: null,
        fin: null
    });

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []);


    useEffect(() => {
        if (!props || !props.clientes) {
            return;
        }

        setListaClientes(props.clientes);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props && props.clientes])

    useEffect(() => {
        if (props.dns) {
            setListaDns(props.dns);
        }

        if (props.planes) {
            setListaPlanes(props.planes);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    const onCambioPlan = (e) => {
        dt.current.filter(e.value, 'plan', 'in');
        setPlanSeleccionado(e.value);
    }

    const onCambioDn = (e) => {
        dt.current.filter(e.value, 'dn.nombre', 'in');
        setDnSeleccionado(e.value);
    }

    const nombreBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Nombre</span>
            {rowData.nombre}
        </>);
    }

    const correoBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Correo</span>
            {rowData.correo}
        </>);
    }

    const empresaBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Empresa</span>
            {rowData.empresa}
        </>);
    }

    const fechaRegistroBodyTemplate = (rowData) => {
        let fecha_registro = '';

        if(typeof rowData.fecha_registro === 'string' && rowData.fecha_registro) {
            fecha_registro = DateTime.fromISO(rowData.fecha_registro).toFormat('dd/LL/yyyy', { locale: 'es-MX' });
        }

        return (<>
            <span className="p-column-title">Fecha de registro</span>
            {/* {fecha_registro} */}
            {fecha_registro}
        </>);
    }

    const planBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Plan</span>
            {capitalizar(rowData.plan)}
        </>);
    }

    const dnBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">DN asignado</span>
            {rowData.dn.nombre}
        </>);
    }

    const planesItemTemplate = (option) => {
        return (
            <div className="p-multiselect-representative-option">
                <span className="image-text">{option.nombre}</span>
            </div>
        );
    };

    const dnsItemTemplate = (option) => {
        return (
            <div className="p-multiselect-representative-option">
                <span className="image-text">{option.userDetail.fullName}</span>
            </div>
        );
    };

    const correosBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Correos</span>
            {
                rowData.validaciones.correo
                    ? rowData.validaciones.correo.toLocaleString('es-MX')
                    : "0"
            }
        </>);
    }
    const telefonosBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Teléfonos</span>
            {
                rowData.validaciones.telefono
                    ? rowData.validaciones.telefono.toLocaleString('es-MX')
                    : "0"
            }
        </>);
    }
    const totalBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Total validaciones</span>
            {
                rowData.totalValidaciones
                    ? rowData.totalValidaciones.toLocaleString('es-MX')
                    : "0"
            }
        </>);
    }

    const filtroPlan = <NeoMultiSelect value={planSeleccionado}
        options={listaPlanes} itemTemplate={planesItemTemplate}
        onChange={onCambioPlan} optionLabel="nombre"
        optionValue="nombre" placeholder="Todos"
        className="p-column-filter" />;

    const filtroDN = <NeoMultiSelect value={dnSeleccionado}
        options={listaDns} itemTemplate={dnsItemTemplate}
        onChange={onCambioDn} optionLabel="userDetail.fullName"
        optionValue="userDetail.fullName" placeholder="Todos"
        className="p-column-filter" />;

    const onRowSelect = (event) => {
        props.onSelect && props.onSelect(event.data.clienteId)
    }

    const fechaRegistroOnCambia = (fechaInicio, fechaFin) => {
        const fecha = {
            inicio: fechaInicio,
            fin: fechaFin
        }

        setFiltroFecha(fecha);
        dt.current.filter(fecha, 'fecha_registro', 'custom');
    }

    const filtroFechaRegistro = (fecha_registro, filter) => {
        let mostrar = false;

        if(filter.inicio === null && filter.fin === null) {
            mostrar = true;
        } else {
            if(typeof fecha_registro === 'string' && fecha_registro) {
                fecha_registro = DateTime.fromISO();
    
                if(fecha_registro.isValid) {
                    const fecha_inicio = DateTime.fromJSDate(filter.inicio);
                    const fecha_fin = DateTime.fromJSDate(filter.fin);

                    if(fecha_inicio <= fecha_registro
                        && fecha_registro <= fecha_fin
                    ) {
                        mostrar = true;
                    }
                }
            }
        }

        return mostrar;
    }

    return (<>
        <NeoTable selectionMode="single" value={listaClientes} paginator
            rows={10} removableSort ref={dt}
            onRowSelect={onRowSelect}
            loading={props.cargando}
            sortField="correo"
            emptyMessage="No tienes clientes asignados" >

            {/* <Column field="nombre" header="Nombre" filter sortable
                body={nombreBodyTemplate}
                filterMatchMode="contains" /> */}

            <NeoTableColumn field="correo" header="Correo" filter sortable
                body={correoBodyTemplate}
                filterMatchMode="contains" />

            <NeoTableColumn field="empresa" header="Empresa" filter sortable
                body={empresaBodyTemplate}
                filterMatchMode="contains" />

            <NeoTableColumn
                field="plan"
                header="Plan"
                filter
                sortable
                body={planBodyTemplate}
                filterElement={filtroPlan}
            />

            {/* <Column field="validaciones.correo" header="V.Correos" filter sortable
                body={correosBodyTemplate}
                filterMatchMode="contains" /> */}

            {/* <Column field="validaciones.telefono" header="V.Teléfonos" filter sortable
                body={telefonosBodyTemplate}
                filterMatchMode="contains" /> */}

            {/* <Column field="totalValidaciones" header="Total" filter sortable
                body={totalBodyTemplate}
                filterMatchMode="contains" /> */}

            {/* <Column field="estatus" header="Estatus" filter
                body={estatusBodyTemplate}
                filterMatchMode="contains" />

            <Column field="consumo" header="Consumo mensual" filter
                body={consumoBodyTemplate}
                filterMatchMode="contains" /> */}

            {usuario.esAdmin() &&
                <NeoTableColumn field="dn.nombre" header="DN asignado" sortable
                    body={dnBodyTemplate}
                    filter
                    filterElement={filtroDN}
                />
            }

            <NeoTableColumn 
                field="fecha_registro" 
                header="Fecha registro" 
                body={fechaRegistroBodyTemplate}
                sortable
                filter 
                filterElement={<NeoButtonOutlined
                    type="submit"
                    label={filtroFecha.inicio 
                        ? "Filtrado"
                        : "Todas"
                    }
                    icon={filtroFecha.inicio &&
                        "pi pi-filter"   
                    }
                    onClick={() => {setMostrarModalFiltroFecha(true)}}
                />}
                filterMatchMode="custom"
                filterFunction={filtroFechaRegistro}
            />
        </NeoTable>

        {mostrarModalFiltroFecha &&
            <ModalFiltroFechaValidacion
                fechaInicio={filtroFecha.inicio}
                fechaFin={filtroFecha.fin}
                visible={mostrarModalFiltroFecha}
                onHide={setMostrarModalFiltroFecha}
                btnReiniciar={() => {
                    fechaRegistroOnCambia(null, null);
                    setMostrarModalFiltroFecha(false);
                }}
                btnAplicar={(fechaInicio, fechaFin) => {
                    fechaRegistroOnCambia(fechaInicio, fechaFin);
                    setMostrarModalFiltroFecha(false)
                }}
            />
        }
    </>);

};


export default TablaClientesListado;