/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useComponent } from 'hooks';
import NeoInnerTitle from "design/design_components/neo/title/NeoInnerTitle.base";
import NeoButtonSection from "design/design_components/neo/layout/NeoButtonSection.base";
import NeoColumn from "design/design_components/neo/layout/NeoColumn.base";
import NeoGridContainer from "design/design_components/neo/layout/NeoGridContainer.base";
import NeoButtonMain from "design/design_components/neo/button/NeoButtonMain.base";
import NeoInputTextarea from "design/design_components/neo/form/NeoInputTextarea.base";
import NeoDropdown from "design/design_components/neo/form/NeoDropdown.base";
import NeoInputNumber from "design/design_components/neo/form/NeoInputNumber.base";
import NeoCard from "design/design_components/neo/panel/NeoCard.base";
import { capitalizar } from "consts/generales";


const FormularioValidacionesModificar = (props) => {
    const componente = useComponent();
    const [validar, setValidar] = componente.useCustomState(false);
    const [errores, setErrores] = componente.useCustomState({});
    const [tiposValidacion, setValidaciones] = componente.useCustomState([]);
    const valoresInicio = {};
    const [formulario, setFormulario] = componente.useCustomState({});
    const acciones = [{
        id: 1,
        label: 'Agregar'
    }, {
        id: 2,
        label: 'Descontar'
    }];

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []);

    useEffect(() => {
        if (Array.isArray(props.tipoValidaciones)) {
            const tipos = props.tipoValidaciones.map((tipo) => {
                return {
                    ...tipo,
                    nombre: capitalizar(tipo.nombre)
                }
            })
            setValidaciones(tipos)
        }
    }, [props.tipoValidaciones])

    useEffect(() => {
        if (validar) {
            validarFormulario();
        }
    }, [formulario.tipoValidacion, formulario.cantidad, formulario.comentario, formulario.accion])

    const actualizarValorFormulario = (propiedad, valor) => {
        setFormulario(prevState => ({
            ...prevState,
            [propiedad]: valor
        }));
    }

    const eventoCambia = (propiedad, valor) => {
        actualizarValorFormulario(propiedad, valor);
    }

    const validarFormulario = () => {
        const error = {
            tipoValidacion: "",
            cantidad: "",
            comentario: ""
        };
        let hayErrores = false;
        if (!formulario.tipoValidacion) {
            error.tipoValidacion = "Selecciona un tipo de validación";
            hayErrores = true;
        } if (!formulario.cantidad) {
            error.cantidad = "Ingresa una cantidad";
            hayErrores = true;
        } if (!formulario.comentario) {
            error.comentario = "Ingresa un comentario";
            hayErrores = true;
        } if (!formulario.accion) {
            error.accion = "Selecciona una acción";
            hayErrores = true;
        }

        if( Array.isArray(props.saldo) 
            && formulario.tipoValidacion
            && formulario.accion?.id === 2
        ) {
            const tipoValidacion = props.saldo.find(
                validacion => validacion.id === formulario.tipoValidacion?.id
            );

            if(tipoValidacion.cantidad < formulario.cantidad) {
                error.cantidad = "Las validaciones a descontar son mayores a las disponibles";
                hayErrores = true;
            }
        }

        setErrores(error);
        if (hayErrores) {
            return false;
        }
        return true;
    }

    const enviaFormulario = () => {
        setValidar(true);
        if (!validarFormulario()) {
            return false;
        }

        const cantidad = formulario.accion?.id === 2  
            ? formulario.cantidad * -1
            : formulario.cantidad;

        props.onSubmit({
            cantidad,
            comentario: formulario.comentario,
            tipoValidacion: formulario.tipoValidacion
        });
    }

    const cancelarFormulario = () => {
        setFormulario(valoresInicio)
    }

    return (<>
        <NeoCard>
            <NeoColumn>
                <NeoInnerTitle extra="primero">
                    Detalle de modificación
                </NeoInnerTitle>
                <NeoGridContainer>
                    <NeoDropdown value={formulario.tipoValidacion}
                        optionLabel="nombre"
                        options={tiposValidacion}
                        onChange={(e) => eventoCambia("tipoValidacion", e.value)}
                        label="Producto a modificar"
                        col="4"
                        error={errores.tipoValidacion}
                    />
                    <NeoInputNumber
                        value={formulario.cantidad}
                        onValueChange={(e) => eventoCambia("cantidad", e.value)}
                        label="Cantidad de validaciones"
                        min={0}
                        col="4"
                        error={errores.cantidad}
                    />
                    <NeoDropdown 
                        value={formulario.accion}
                        optionLabel="label"
                        options={acciones}
                        onChange={(e) => eventoCambia("accion", e.value)}
                        label="Acción"
                        col="4"
                        error={errores.accion}
                    />
                    <NeoInputTextarea
                        rows={4}
                        label="Comentario"
                        md="12"
                        value={formulario.comentario}
                        onChange={(e) => {
                            eventoCambia("comentario", e.target.value)
                        }}
                        error={errores.comentario}
                    />

                </NeoGridContainer>
            </NeoColumn>
        </NeoCard>
        <NeoButtonSection align="right">
            <NeoButtonMain label="Cancelar" onClick={cancelarFormulario} />
            <NeoButtonMain label="Guardar" onClick={enviaFormulario} />
        </NeoButtonSection>
    </>)
}

export default FormularioValidacionesModificar;