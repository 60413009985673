import { createContext, useState, useEffect } from "react";
import { functions } from "config/firebase";
import { 
    useComponent,
    useMensajeGlobal 
} from "hooks";

const ClienteContext = createContext({});

export const ClienteProvider = (props) => {
    const componente = useComponent({
        data: {
            loading: false
        }
    });
    const [cliente, setCliente] = useState();
    const [clienteId, setClienteId] = useState();
    const despliegaMensaje = useMensajeGlobal();

    useEffect(() => {
        if(clienteId) {
            cargarDatosCliente();
        } else {
            setCliente();
        }
    }, [clienteId])

    const cargarDatosCliente = async () => {
        if(clienteId) {
            try {
                componente.data.loading = true;

                const respCliente = await functions.httpsCallable("getDatosCliente")({
                    clienteId
                });

                const respSaldo = await functions.httpsCallable('clientes-obtieneSaldo')({
                    clienteId
                });

                const cliente = {
                    id: respCliente.data.clienteId,
                    ...respCliente.data,
                    saldo: respSaldo.data
                };

                setCliente(cliente);
            } catch(error) {
                if(error.code === 'permission-denied') {
                    despliegaMensaje({
                        severity: "error",
                        summary: "Error",
                        detail: 'No cuentas con los privilegios suficientes para realizar esta acción'
                    });
                } else {
                    console.error("Error al obtener los datos del ciente");
                }
            }

            componente.data.loading = false;

            return cliente;
        }

        return null;
    }

    return (
        <ClienteContext.Provider value={{
            cliente,
            setClienteId,
            cargarDatosCliente,
            data: componente.data,
        }}>
            {props.children}
        </ClienteContext.Provider>
    )
}

export default ClienteContext;