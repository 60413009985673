import { useContext } from 'react';
import UsuarioContext from 'components/context/UsuarioContext';
import { functions } from "config/firebase";
import RUTAS from 'components/router/Rutas.json';
import { ROLE_USER } from "consts/neoadm";

const useUsuario = () => {
    const {
        data,
        auth,
        usuarioAuth,
        persona,
        cargarDatosPersona
    } = useContext(UsuarioContext);

    const estaLogueado = () => {
        return Boolean(usuarioAuth !== null && persona);
    }

    const esAdmin = (tipoAComparar) => {
        if(estaLogueado()) {
            const rol = persona?.rol;

            if(tipoAComparar && typeof tipoAComparar === 'number') {
                return tipoAComparar === rol;
            } else {
                switch (rol) {
                    case ROLE_USER.ADMIN.ID:
                    case ROLE_USER.DN_MANAGER.ID:
                    case ROLE_USER.ACCOUNTANT.ID:
                    case ROLE_USER.SUPPORT.ID:
                        return true;
                    default:
                        return false;
                }
            }
        }
        return false;
    }

    const esDn = (tipoAComparar) => {
        if(estaLogueado()) {
            const rol = persona?.rol;

            if(tipoAComparar && typeof tipoAComparar === 'number') {
                return tipoAComparar === rol;
            } else {
                switch (rol) {
                    case ROLE_USER.DN.ID:
                    case ROLE_USER.DN_SALES.ID:
                        return true;
                    default:
                        return false;
                }
            }
        }
        return false;
    }

    const esEmpleado = () => {
        if(estaLogueado()) {
            if( esDn() || esAdmin() ) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    }

    const esCliente = () => {
        if(estaLogueado()) {
            const rol = persona?.rol;
            if(rol === ROLE_USER.CUSTOMER.ID) {
                if(persona.cliente) {
                    return true;
                }
            }
        }
        return false;
    }

    const esSubCuenta = () => {
        if(estaLogueado()) {
            if(persona.isSubAccount) {
                return true;
            }
        }
        return false;
    }

    const obtieneRutaDashboard = () => {
        if(estaLogueado()) {
            if(esEmpleado()) {
                return RUTAS.admin.tablero;
            } else if(esCliente()) {
                return RUTAS.clientes.tablero;
            }
            return null;
        }

        return '/';
    }

    const recargar = async () => {
        return cargarDatosPersona();
    }

    const iniciaSesion = async (params) => {
        try {
            await auth.signInWithEmailAndPassword(
                params.correo, params.contrasena
            );

            await functions.httpsCallable('usuario-firmarBitacoraLogin')();
        } catch(error) {
            throw error;
        }
    }

    const iniciaSesionConToken = async (token, session) => {
        try {
            const { data } = await functions.httpsCallable('usuario-iniciarSesionToken')({
                token,
                session
            });

            await auth.signInWithCustomToken(data.token_auth);

            await functions.httpsCallable('usuario-firmarBitacoraLogin')();
        } catch(error) {
            throw error;
        }
    }

    const cerrarSesion = async () => {
        try {
            await auth.signOut();
            return true;
        } catch(error) {
            throw error;
        }
    }

    return {
        estaLogueado,
        esAdmin,
        esDn,
        esEmpleado,
        esCliente,
        esSubCuenta,
        obtieneRutaDashboard,
        recargar,
        iniciaSesion,
        iniciaSesionConToken,
        cerrarSesion,
        persona,
        data
    }
}

export default useUsuario;