import RUTAS from "components/router/Rutas.json";

export const menuCliente = [
    {
        label: 'Dashboard',
        redirect: RUTAS.clientes.tablero
    },
    {
        label: 'Estadísticas',
        redirect: RUTAS.clientes.estadisticas
    },
    {
        label: 'Validaciones',
        redirect: RUTAS.clientes.validaciones,
    },
    {
        label: 'API',
        redirect: RUTAS.clientes.apikeys,
    },
    {
        label: 'Mi cuenta',
        items: [
            {
                label: 'Administrar cuenta',
                redirect: RUTAS.clientes.cuenta,
            },
            {
                label: 'Órdenes',
                redirect: RUTAS.clientes.ordenes.lista,
            },
            {
                label: 'Historial de pagos',
                redirect: RUTAS.clientes.pagos.historial,
            },
            {
                label: 'Contratos',
                redirect: RUTAS.clientes.contratos,
            }
        ]
    },
    {
        label: 'Ayuda',
        redirect: RUTAS.clientes.ayuda,
    },
    {
        icon: 'pi pi-fw pi-bell',
        className: 'notificaciones',
        items: []
    }
]

export const menuDn = [
    {
        label: 'Dashboard',
        redirect: RUTAS.admin.tablero,
    },
    {
        label: 'Clientes',
        redirect: RUTAS.admin.clientes.lista,
    }
]

export const menuAdmin = [
    {
        label: 'Dashboard',
        redirect: RUTAS.admin.tablero,
    },
    {
        label: 'Clientes',
        redirect: RUTAS.admin.clientes.lista,
    },
    {
        label: 'Validaciones',
        redirect: RUTAS.admin.validaciones,
    },
    {
        label: 'Administración general',
        redirect: RUTAS.admin.general,
    }
]