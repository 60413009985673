import React from "react";


const BaseMainContainer = (props) => {
  return (<>
    <div className={`${props.custom ? props.custom : ""}
            ${props.extra ? props.extra : ""}`} {...props}>
      {props.children}
    </div>
  </>);
};

export default BaseMainContainer;