import React from "react";


const NeoHeaderData = (props) => {
  return (
    <div className={`dato-header p-d-flex
            ${props.extra ? props.extra : ""}
            ${props.className}`}>
      {
        props.label
          ? <div className="dato">{props.label}</div>
          : null
      }
      {
        props.subLabel
          ? <div className="label">{props.subLabel}</div>
          : null
      }
    </div>
  );
};

export default NeoHeaderData;