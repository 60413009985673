/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useComponent } from 'hooks';
import NeoDialogConfirmMethod from 'design/design_components/neo/overlay/NeoDialogConfirmMethod.base';
import NeoButtonMain from "design/design_components/neo/button/NeoButtonMain.base";
import useMensajeGlobal from "hooks/useMensajeGlobal";
import customStorage from 'consts/customStorage';
import icono from 'consts/iconos';

const ArchivosStorage = (props) => {
    const componente = useComponent();
    const setMensajeGlobal = useMensajeGlobal();
    const [elementosWeb, setElementosWeb] = componente.useCustomState([]);
    const [, _setEliminandoTodo] = componente.useCustomState(false);
    const [estaCargando, setEstaCargando] = componente.useCustomState(true);

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []);

    useEffect(() => {
        if (props.storageRef === undefined) {
            console.error('Referencia no recibida por props');
            return;
        }

        listarArchivos(props.storageRef);
    }, []);

    const setEliminandoTodo = (eliminando) => {
        if (typeof props.setAlEliminarTodo === 'function') {
            props.setAlEliminarTodo({
                eliminando,
                storageRef: props.storageRef
            });
        }
        _setEliminandoTodo(eliminando);
    }

    const listarArchivos = (ref) => {
        if (ref !== undefined) {
            const storageInstance = customStorage(props.storageRef);
            storageInstance.listar().then((archivos) => {
                if (archivos.length === 0) {
                    setElementosWeb([]);
                } else {
                    archivos.forEach((archivo, index) => {
                        archivo.obtenerDetalles().then((datos) => {
                            const elementoWeb = (
                                <span
                                    key={index}
                                    onClick={() => archivoOnClick(archivo)}
                                    className='cursor-pointer p-p-1'
                                >
                                    {icono({
                                        size: props.size || '2em'
                                    }).porTipoMedio(datos.contentType)}
                                </span>
                            );
                            setElementosWeb(prevState => [...prevState, elementoWeb]);
                        });
                    });
                }
            }).catch((error) => {
                setMensajeGlobal({
                    severity: 'warn',
                    detail: error.mensaje
                });
            }).finally(() => {
                setEstaCargando(false);
            });
        }
    }

    const archivoOnClick = (archivo) => {
        archivo.descargar();
    }

    const eliminarTodoOnClick = () => {
        const eliminar = () => {
            const oldElementosWeb = elementosWeb;
            setElementosWeb([]);
            setEliminandoTodo(true);
            const storageInstance = customStorage(props.storageRef);
            storageInstance.eliminar().then((resultado) => {
                if (typeof props?.onEliminarTodo === 'function') {
                    props.onEliminarTodo();
                }
            }).catch((error) => {
                setMensajeGlobal({
                    severity: 'warn',
                    detail: error.mensaje
                });
                setElementosWeb(oldElementosWeb);
            }).finally(() => {
                setEliminandoTodo(false);
            });
        }

        if (props.confirmarEliminarTodo) {
            NeoDialogConfirmMethod({
                message: '¿Deseas eliminar todos los archivos?',
                header: 'Confirmación',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Si',
                rejectLabel: 'No',
                accept: () => eliminar()
            });
        } else {
            eliminar();
        }
    }

    const template = () => {
        return (<>
            {elementosWeb}
            {(elementosWeb.length !== 0 && props.btnEliminarTodo) &&
                <NeoButtonMain
                    label='Eliminar archivos'
                    onClick={eliminarTodoOnClick}
                />
            }
        </>);
    }

    return (<>
        {estaCargando ?
            <i className="pi pi-spin pi-spinner"></i>
            :
            template()
        }
    </>);
}

ArchivosStorage.defaultProps = {
    btnEliminarTodo: false,
    confirmarEliminarTodo: true
}

export default ArchivosStorage;