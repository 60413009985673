import { createContext, useState, useEffect } from "react";

const TriggerContext = createContext({});

export const TriggerProvider = (props) => {
    const [historial, setHistorial] = useState([]);
    const [evento, setEvento] = useState({});

    useEffect(() => {
        if(evento) {
            setHistorial((eventos) => [
                ...eventos,
                evento
            ])
        }
    }, [evento])

    const reset = () => {
        setHistorial([]);
        setEvento({});
    }

    return (
        <TriggerContext.Provider value={{
            historial,
            evento,
            setEvento,
            reset
        }}>
            {props.children}
        </TriggerContext.Provider>
    )
}

export default TriggerContext;