const isRichValue = (value) => {
    return Boolean(value && Array.isArray(value.richText));
}

const hasTextProperty = (value) => {
    return Boolean(value && typeof value === 'object' && value.text);
}

const richToString = (rich) => {
    return rich.richText.map(({ text }) => text).join('');
}

const richToHtml = (rich) => {
    let str = rich.richText.map(({ font = {}, text }) => {
        return [
            font.bold ? '<strong>' : '',
            font.italic ? '<em>' : '',
            text,
            font.italic ? '</em>' : '',
            font.bold ? '</strong>' : '',
        ].join('');
    }).join('');

    // simple tag combining where possible
    return str.replace(/<\/strong><strong>/g, '').replace(/<\/em><em>/g, '');
}

module.exports = {
    isRichValue,
    richToHtml,
    richToString,
    hasTextProperty
};