import Icono from 'design/assets/img/mnv/icons/icon-help.svg';
import NeoGridContainer from "design/design_components/neo/layout/NeoGridContainer.base";
import NeoTitleMain from "design/design_components/neo/title/NeoTitleMain.base";
import SeccionPreguntasFrecuentes from '../../common/SeccionPreguntasFrecuentes';

const PreguntasFrecuentes = (props) => {
    return (<>
        <NeoGridContainer>
            <NeoTitleMain col="12"
                title="Preguntas Frecuentes" icon={Icono} />
        </NeoGridContainer>

        <SeccionPreguntasFrecuentes />
    </>)
}

export default PreguntasFrecuentes;