import React from "react";
import BaseRadioButton from "../../sisu/form/BaseRadioButton.sisu";

const NeoRadioButton = (props) => {
  return (<>
    <div className={`p-field-radiobutton ${props.extra ? props.extra : ""}`}>
      <BaseRadioButton {...props} />
      <label htmlFor={props.inputId}>{props.label}</label>
    </div>
  </>);
};

export default NeoRadioButton;