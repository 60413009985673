export const VALIDACION_ESTADO = {
    PROCESANDO: 1,
    FINALIZADO: 2,
    CANCELADO: 3,
    EXPIRADO: 4
}

export const ORDEN = {
    TIPO: {
        CONTRATO: {
            ID: 1
        },
        VALIDACIONES_INICIALES: {
            ID: 2
        },
        PAQUETE_ESPECIAL: {
            ID: 3
        },
        PAQUETE_GENERAL: {
            ID: 4
        },
        VALIDACIONES_SOBREGIRO: {
            ID: 5
        },
        AJUSTE_MANUAL: {
            ID: 6
        }
    },
    PAGO: {
        PENDIENTE: {
            ID: 1
        },
        PAGADA: {
            ID: 2
        },
        CANCELADA: {
            ID: 3
        }
    },
    ESTADO: {
        PENDIENTE_ACTIVACION: {
            ID: 1
        },
        VIGENTE: {
            ID: 2
        },
        CANCELADO: {
            ID: 3
        },
        EXPIRADO: {
            ID: 4
        },
        FINALIZADO: {
            ID: 5
        }
    }
}

export const SOBREGIRO = {
    PROMEDIO: {
        ID: 1
    },
    FIJO :{
        ID: 2
    }
}