/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from "react";
import { useRouteMatch, useLocation, useHistory } from "react-router-dom";
import { functions } from "config/firebase";
import { useComponent, useTrigger } from 'hooks';
import useMensajeGlobal from "hooks/useMensajeGlobal";
import useProgressSpinner from "hooks/useProgressSpinner"
import { EstadoGlobal } from "components/context/EstadoGlobal";
import NeoCardPrincipal from "design/design_components/neo/panel/NeoCardPrincipal.base";
import NeoInnerBlankCardMainText from "design/design_components/neo/table/NeoInnerBlankCardMainText.base";
import NeoInnerBlankCardSecondText from "design/design_components/neo/table/NeoInnerBlankCardSecondText.base";
import TablaHistoricoValidacionesAdmin from "components/common/TablaHistoricoValidacionesAdmin";
import NeoMessageLoading from "design/design_components/neo/messages/NeoMessageLoading.base";


const HistoricoValidacionesAdmin = (props) => {
    const componente = useComponent();
    const trigger = useTrigger();
    // Peticiones a functions
    const getHistorial = componente.useAsync(functions.httpsCallable('validacion-historialValidaciones'), {
        name: 'getHistorial'
    });

    let { url } = useRouteMatch();
    const despliegaMensaje = useMensajeGlobal();
    const [cargando, setSpinnerCargando] = useProgressSpinner(true);
    const [historico, setHistorico] = componente.useCustomState([]);
    const [totalRegistros, setTotalRegistros] = componente.useCustomState();
    const [, setRecargaTablaValidaciones] = componente.useCustomState();
    const [lazyParams, setLazyParams] = componente.useCustomState();
    let location = useLocation();
    let historial = useHistory();


    useEffect(() => {
        location.state?.lazyParams
            ? setLazyParams(location.state.lazyParams)
            : setLazyParams({
                first: 0,
                rows: 8,
                page: 1,
                filters: {
                    metodo: { value: [ 'Web' ], matchMode: 'in' },
                    tipo_volumen: { value: [ 'archivo' ], matchMode: 'in' }
                }
            })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (componente.calls['getHistorial'].calls === 0
        ) {
            setSpinnerCargando(true);
        } else {
            setSpinnerCargando(false);
        }
    }, [historico])


    useEffect(() => {
        if (trigger.evento.nombre === 'nueva validacion archivo') {
            obtieneHistorialValidaciones();
        }
    }, [trigger.evento])


    useEffect(() => {
        if (lazyParams) {
            setRecargaTablaValidaciones(true);
            obtieneHistorialValidaciones();
            historial.replace(url, { lazyParams: lazyParams })
        }
    }, [lazyParams]) // eslint-disable-line react-hooks/exhaustive-deps



    const obtieneHistorialValidaciones = () => {
        getHistorial({
            lazyParams: JSON.stringify(lazyParams),
            clienteId: props.clienteId,
        }).then((resp) => {
            setHistorico(resp.data?.listaResultados)
            setTotalRegistros(resp.data?.totalRegistros)
        }).catch((error) => {
            console.log("Error: ", error);
            despliegaMensaje({
                severity: "error",
                summary: "Error:",
                detail: error.message
            });
        })
    };

    if (cargando) {
        return <NeoMessageLoading />
    }


    return (<>
        {(componente.isLoading
            && componente.calls['getHistorial'].calls === 1
            && historico.length === 0)
            ? <NeoCardPrincipal>
                <NeoInnerBlankCardMainText>
                    Cargando datos
                </NeoInnerBlankCardMainText>
                <NeoInnerBlankCardSecondText>
                    Favor de esperar un momento
                </NeoInnerBlankCardSecondText>
              </NeoCardPrincipal>
            : <>{(
                !componente.isLoading
                    && componente.calls['getHistorial'].calls === 1
                    && historico.length === 0) ?
                    <NeoCardPrincipal>
                        <NeoInnerBlankCardMainText>
                            Bienvenido a tu espacio de validaciones
                        </NeoInnerBlankCardMainText>
                        <NeoInnerBlankCardSecondText>
                            Empieza a validar tus listas de correos electrónicos o teléfonos por el método que mejor se ajuste a tus necesidades.
                        </NeoInnerBlankCardSecondText>
                    </NeoCardPrincipal>
                    : <TablaHistoricoValidacionesAdmin
                            totalRegistros={totalRegistros}
                            datos={historico}
                            loading={componente.isLoading}
                            lazyParams={lazyParams}
                            setLazyParams={setLazyParams}
                            // filtroFechas={filtroFechas}
                        />
                }
              </>
        }
    </>);
};


export default HistoricoValidacionesAdmin;