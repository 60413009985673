/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from 'react';
import { useComponent, useInput } from 'hooks';
import { validaCorreo, validaTelefono } from "consts/validacionDatosUsuario"
import { capitalizar } from "consts/generales"
import { functions } from "config/firebase";
import NeoInputText from "design/design_components/neo/form/NeoInputText.base";
import NeoButtonMain from "design/design_components/neo/button/NeoButtonMain.base";
import NeoMessages from "design/design_components/neo/messages/NeoMessages.base";
import { useTrigger } from 'hooks';

const FormularioValidacionUnitaria = (props) => {
    const componente = useComponent();
    const trigger = useTrigger();
    const [campoValidar, setCampoValidar] = useInput("");
    const [errores, setErrores] = componente.useCustomState({ "correo": null })
    const [evalTiempoRealForm, setEvalTiempoRealForm] = componente.useCustomState(false);
    const mensajeForm = useRef(null);
    const [validando, setValidando] = componente.useCustomState(false);

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []);

    // Ejecuta evaluación del formulario en tiempo real
    useEffect(() => {
        if (evalTiempoRealForm)
            evaluaFormulario();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campoValidar]);


    const evaluaFormulario = () => {
        let esValido = false;
        let mensaje = null;
        let tipo = null;

        const resultadoCorreo = validaCorreo(campoValidar);
        const resultadoTelefono = validaTelefono(campoValidar);

        if (resultadoCorreo.esValido) {
            tipo = "correo";
            esValido = true;
        } else if (resultadoTelefono.esValido) {
            tipo = "telefono";
            esValido = true;
        } else {
            mensaje = "No corresponde a un correo o teléfono"
        }

        setErrores({ campoValidar: mensaje });
        return [esValido, tipo];
    };

    const manejaValidacionUnitaria = () => {
        const valida = functions.httpsCallable('validacionWeb');
        setEvalTiempoRealForm(true);

        const [formatoValido, tipo] = evaluaFormulario()

        if (!formatoValido) {
            return false;
        }

        setValidando(true);

        // Datos preprocesados, la comparación de la lista de bloqueados se
        // hace en el backend.
        valida({ dato: campoValidar, tipo: tipo })
            .then((respuesta) => {
                let severity = ""
                switch (respuesta.data.resultado) {
                    case "valido":
                    case "válido":
                        severity = "success"
                        break
                    case "invalido":
                    case "inválido":
                    case "error":
                        severity = "error"
                        break
                    case "riesgoso":
                        severity = "warn"
                        break
                    case "desconocido":
                        severity = "info"
                        break;
                    default:
                }
                mensajeForm.current.show({
                    severity: severity,
                    summary: "",
                    detail: `${capitalizar(respuesta.data.resultado)}: ${respuesta.data.razon}`,
                    life: 3000
                });
            }).catch((error) => {
                console.log("Error: ", error);
                mensajeForm.current.show({
                    severity: "error",
                    summary: "",
                    detail: error.message
                });
            }).finally(() => {
                setValidando(false);
                setCampoValidar("");
                setEvalTiempoRealForm(false);
                setErrores({});
                trigger.nuevo('nueva validacion unitaria');
            });
    };

    const inputOnKeyDown = (event) => {
        if (event.key === "Enter") {
            manejaValidacionUnitaria()
        }
    }

    return (<>
        <div className="p-d-flex validacion-unitaria">
            <NeoInputText col="auto" id="correoValidar"
                label="Correo electrónico o teléfono a validar"
                error={errores.campoValidar}
                value={campoValidar}
                onChange={setCampoValidar}
                onKeyDown={inputOnKeyDown}
            />

            <NeoButtonMain extra="p-col-align-center" type="submit"
                label="Validación rápida"
                onClick={manejaValidacionUnitaria}
                icon={validando ? "pi pi-spin pi-spinner" : ""}
                disabled={validando}

            />

        </div>
        <NeoMessages ref={mensajeForm} />
    </>);

};

export default FormularioValidacionUnitaria;