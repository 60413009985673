import { useEffect } from "react";
import { functions } from "config/firebase";
import { useHistory } from "react-router-dom";
import useProgressSpinner from "hooks/useProgressSpinner";
import useMensajeGlobal from "hooks/useMensajeGlobal";
import NeoTitleMain from "design/design_components/neo/title/NeoTitleMain.base";
import NeoButtonSection from "design/design_components/neo/layout/NeoButtonSection.base";
import NeoButtonMain from "design/design_components/neo/button/NeoButtonMain.base";
import NeoGridContainer from "design/design_components/neo/layout/NeoGridContainer.base";
import TablaOrdenes from "components/common/TablaOrdenes";
import NeoCardPrincipal from "design/design_components/neo/panel/NeoCardPrincipal.base";
import NeoInnerBlankCardMainText from "design/design_components/neo/table/NeoInnerBlankCardMainText.base";
import NeoMessageLoading from "design/design_components/neo/messages/NeoMessageLoading.base";
import RUTAS from "components/router/Rutas.json";
import { useComponent } from 'hooks';
import useCliente from "hooks/useCliente";
import Icono from 'design/assets/img/mnv/icons/icon-contracts.svg';

const obtenerTipoOrdenes = functions.httpsCallable('ordenes-listarTipos');
const obtenerTipoPagoEstados = functions.httpsCallable('pago-listarEstados');
const obtenerTipoValidaciones = functions.httpsCallable('validacion-listarTipos');


const ClientesOrdenes = (props) => {
    const componente = useComponent();
    const cliente = useCliente();
    const obtenerListaOrdenes = componente.useAsync(functions.httpsCallable('ordenes-listar'));
    const [cargando, setSpinnerCargando] = useProgressSpinner(true)
    const despliegaMensaje = useMensajeGlobal();
    const [datos, setDatos] = componente.useCustomState([]);
    const [tiposOrdenes, setTiposOrdenes] = componente.useCustomState([]);
    const [tiposPagoEstado, setTiposPagoEstado] = componente.useCustomState([]);
    const [tiposValidaciones, setTiposValidaciones] = componente.useCustomState([]);
    let historial = useHistory();

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        obtieneOrdenes();
        obtieneTiposOrden();
        obtieneTiposEstado();
        obtieneTiposValidacion();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const obtieneOrdenes = async () => {
        obtenerListaOrdenes({
            clienteId: cliente.id
        }).then((respuesta) => {
            // setTiposValidaciones(respuesta.data);
            setDatos(respuesta.data);
        }).catch((error) => {
            despliegaMensaje({
                severity: "error",
                summary: `Error:`,
                detail: error.message
            });
        }).finally(() => {
            setSpinnerCargando(false);
        });
    }

    const obtieneTiposOrden = () => {
        obtenerTipoOrdenes()
            .then((respuesta) => {
                setTiposOrdenes(respuesta.data);
            }).catch((error) => {
                despliegaMensaje({
                    severity: "error",
                    summary: `Error:`,
                    detail: error.message
                });
            })
    }

    const obtieneTiposEstado = () => {
        obtenerTipoPagoEstados()
            .then((respuesta) => {
                setTiposPagoEstado(respuesta.data);
            }).catch((error) => {
                despliegaMensaje({
                    severity: "error",
                    summary: `Error:`,
                    detail: error.message
                });
            })
    }

    const obtieneTiposValidacion = () => {
        obtenerTipoValidaciones()
            .then((respuesta) => {
                setTiposValidaciones(respuesta.data);
            }).catch((error) => {
                despliegaMensaje({
                    severity: "error",
                    summary: `Error:`,
                    detail: error.message
                });
            })
    }

    return (<>
        <NeoGridContainer>
                <NeoTitleMain icon={Icono} title="Órdenes" col="12" md="6" />

            <NeoButtonSection
                align="right"
                col="12"
                md="6"
                extra="p-col-align-center p-flex-sm-row p-flex-column">

                <NeoButtonMain
                    label="Ajuste manual de validaciones"
                    onClick={() => historial.push(RUTAS.admin.clientes.validaciones.ajustar)} />
                <NeoButtonMain
                    label="Crear bloque de validaciones"
                    onClick={() => historial.push(RUTAS.admin.clientes.paquetes.especiales)} />

            </NeoButtonSection>
        </NeoGridContainer>

        <NeoGridContainer>
            {
                cargando
                    ? <NeoMessageLoading col={12} />
                    : datos.length >= 1
                        ? <TablaOrdenes
                            datos={datos}
                            tiposOrdenes={tiposOrdenes}
                            tiposPagoEstado={tiposPagoEstado}
                            tiposValidaciones={tiposValidaciones}
                        />
                        : <NeoCardPrincipal col={12}>
                            <NeoInnerBlankCardMainText>
                                El cliente no cuenta con ninguna orden
                            </NeoInnerBlankCardMainText>
                        </NeoCardPrincipal>
            }
        </NeoGridContainer>
    </>);
};


export default ClientesOrdenes;