/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";
import { useComponent, useInput } from 'hooks';
import NeoCard from "design/design_components/neo/panel/NeoCard.base";
import NeoInnerTitle from "design/design_components/neo/title/NeoInnerTitle.base";
import NeoButtonMain from "design/design_components/neo/button/NeoButtonMain.base";
import NeoButtonSection from "design/design_components/neo/layout/NeoButtonSection.base";
import { useHistory } from "react-router-dom";
import NeoCalendar from "design/design_components/neo/form/NeoCalendar.base";
import NeoInputNumber from "design/design_components/neo/form/NeoInputNumber.base";
import NeoColumn from "design/design_components/neo/layout/NeoColumn.base";
import NeoDropdown from "design/design_components/neo/form/NeoDropdown.base";
import { capitalizar } from "consts/generales";


const FormularioContrato = (props) => {
    const componente = useComponent();
    const [inicio, setInicio] = useInput();
    const [mesesVigencia, setMesesVigencia] = useInput();
    const [vigenciaSufijo, setVigenciaSufijo] = componente.useCustomState();
    const [errores, setErrores] = componente.useCustomState({})
    const [validar, setValidar] = componente.useCustomState(false);
    const mensajeForm = useRef(null);
    const historial = useHistory();

    const [producto, setProducto] = componente.useCustomState();
    const [autoRenovar, setAutoRenovar] = componente.useCustomState("false");
    const [listaProductos, setListaProductos] = componente.useCustomState();
    const [cantidad, setCantidad] = useInput();
    const [precio, setPrecio] = useInput();
    const esteAño = (new Date()).getFullYear();

    const listaAutoRenovar = [{
        nombre: "Desactivada",
        valor: "false"
    }, {
        nombre: "Activada",
        valor: "true"
    }];

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []);

    useEffect(() => {
        if (props.listaProductos) {
            setListaProductos(props.listaProductos.map((producto) => {
                return {
                    ...producto,
                    nombre: capitalizar(producto.nombre)
                }
            }))
        }

    }, [props.listaProductos]);

    useEffect(() => {
        if (validar)
            validaFormulario();

        mensajeForm.current && mensajeForm.current.clear()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inicio, mesesVigencia]);

    useEffect(() => {
        if (mesesVigencia === 1)
            setVigenciaSufijo(" mes")
        else
            setVigenciaSufijo(" meses");
    }, [mesesVigencia]);


    const validaInicio = (fecha) => {
        let esValido = true;
        let mensaje = null;

        if (!fecha) {
            mensaje = "Debes especificar una fecha de inicio"
            esValido = false
        }

        return { esValido: esValido, mensaje: mensaje }
    }
    const validaVigencia = (dato) => {
        let esValido = true;
        let mensaje = null;

        if (dato < 1 || dato > 12)
            mensaje = "El rango debe ser de 1 a 12 meses"

        return { esValido: esValido, mensaje: mensaje }
    }
    const validaProducto = (dato) => {
        let esValido = true;
        let mensaje = null;

        // console.log("Producto:", dato);
        if (!dato) {
            mensaje = "Debes seleccionar un producto"
            esValido = false
        }

        return { esValido: esValido, mensaje: mensaje }
    }
    const validaCantidad = (dato) => {
        let esValido = true;
        let mensaje = null;

        // console.log("Cantidad:", dato);
        if (!Number(dato)) {
            mensaje = "La cantidad no es válida"
            esValido = false
        }

        return { esValido: esValido, mensaje: mensaje }
    }
    const validaPrecio = (dato) => {
        let esValido = true;
        let mensaje = null;

        console.log("Precio:", dato);

        if (!Number(dato)) {
            mensaje = "Este precio no es válido"
            esValido = false
        }

        return { esValido: esValido, mensaje: mensaje }
    }

    const validaFormulario = () => {
        let esValido = true;
        setValidar(true);

        const resultadoInicio = validaInicio(inicio);
        const resultadoVigencia = validaVigencia(mesesVigencia);
        const resultadoProducto = validaProducto(producto);
        const resultadoCantidad = validaCantidad(cantidad);
        const resultadoPrecio = validaPrecio(precio);

        setErrores({
            inicio: resultadoInicio.mensaje,
            vigencia: resultadoVigencia.mensaje,
            producto: resultadoProducto.mensaje,
            cantidad: resultadoCantidad.mensaje,
            precio: resultadoPrecio.mensaje
        });

        if (resultadoInicio.esValido === false
            || resultadoVigencia.esValido === false
            || resultadoProducto.esValido === false
            || resultadoCantidad.esValido === false
            || resultadoPrecio.esValido === false) {
            esValido = false;
        }

        return esValido;
    };

    const manejaCancelar = () => {
        historial.goBack();
    };

    const manejaGuardar = () => {
        setValidar(true);
        if (!validaFormulario()) {
            return false;
        }

        // Formulario válido, listo para enviar datos
        props && props.onSubmit && props.onSubmit({
            fechaInicio: inicio.toISOString().slice(0, 7),
            vigencia: mesesVigencia,
            autoRenovar: (autoRenovar === "true" ? 1 : 0),
            producto: producto,
            cantidad: cantidad,
            precio: precio
        });
    };


    return (<>
        <NeoCard>
            <NeoInnerTitle extra="primero">
                Información general
            </NeoInnerTitle>

            <NeoCalendar md="4" value={inicio} readOnlyInput label="Fecha de inicio"
                onChange={setInicio} dateFormat="yy-mm" showButtonBar
                view="month" error={errores.inicio} minDate={new Date()}
                yearNavigator yearRange={`${esteAño}:${esteAño + 1}`} />
            <NeoInputNumber md="4" id="nombre" value={mesesVigencia}
                onValueChange={setMesesVigencia} label="Vigencia"
                error={errores.nombre} suffix={vigenciaSufijo}
                min={1} max={12} />
            <NeoColumn md="4">
                <NeoDropdown optionLabel="nombre" optionValue="valor"
                    value={autoRenovar} options={listaAutoRenovar}
                    onChange={(e) => setAutoRenovar(e.value)}
                    label="Auto renovación" error={errores.autoRenovar} />
            </NeoColumn>

            <NeoInnerTitle extra="primero">
                Consumos mínimos
            </NeoInnerTitle>

            <NeoColumn md="4">
                <NeoDropdown optionLabel="nombre" optionValue="id"
                    value={producto} options={listaProductos}
                    onChange={(e) => setProducto(e.value)}
                    label="Producto" error={errores.producto} />
            </NeoColumn>
            <NeoColumn md="4">
                <NeoInputNumber id="cantidad" value={cantidad}
                    onValueChange={setCantidad} error={errores.cantidad}
                    label="Validaciones mensuales" min={0} />
            </NeoColumn>
            <NeoColumn md="4">
                <NeoInputNumber id="cantidad" value={precio}
                    onValueChange={setPrecio} error={errores.precio}
                    label="Precio por validación" min={0} max={1000}
                    mode="currency" currency="MXN" locale="locale-user"
                    minFractionDigits={1} maxFractionDigits={4} />
            </NeoColumn>
        </NeoCard>

        <NeoButtonSection align="right" >
            <NeoButtonMain label="Cancelar" onClick={manejaCancelar} />
            <NeoButtonMain label="Guardar" onClick={manejaGuardar} />
        </NeoButtonSection>
    </>);
};


export default FormularioContrato;