export const generateID = () => {
    const ID_LENGTH = 36;
    const START_LETTERS_ASCII = 97;
    const ALPHABET_LENGTH = 26;

    return [...new Array(ID_LENGTH)]
    .map(() => String.fromCharCode(START_LETTERS_ASCII + Math.random() * ALPHABET_LENGTH))
    .join('');
}

/**
 * Clona una variable removiendo la referencia
 * @param  {any} element - variable a clonar
 * @return Valor sin referencia
 */
export const cloneDeep = (element) => {
    if(Array.isArray(element)) {
        return element.map(cloneDeep);
    } else if(typeof element === 'object') {
        const newObject = {};
        for (let key in element) {
            newObject[key] = cloneDeep(element[key]);
        }
        return newObject;
    } else {
        return element;
    }
}