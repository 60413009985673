import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as solid from "@fortawesome/free-solid-svg-icons";

const icono = (estilo) => {

    const render = (icono) => {
        return <i 
            className={icono}
            style={{fontSize: estilo.size}}
        />
    }

    const porTipoMedio = (tipo) => {
        switch (tipo) {
            case 'application/pdf':
                return render('fa fa-file-pdf');
            case 'text/xml':
                return render('fa fa-file-code');
            default:
                return render('fa fa-file-alt');
        }
    }

    const mostrar = (icon) => {
        return <FontAwesomeIcon icon={icon} />;
    }

    return {
        porTipoMedio,
        mostrar
    }
}

icono.defaultProps = {
    size: '2rem'
}

export default icono;