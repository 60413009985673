import { useEffect } from 'react';
import { functions } from "config/firebase";
import useMensajeGlobal from "hooks/useMensajeGlobal";
import { useHistory } from "react-router-dom";
import useProgressSpinner from "hooks/useProgressSpinner"
import NeoButtonSection from "design/design_components/neo/layout/NeoButtonSection.base";
import NeoGridContainer from "design/design_components/neo/layout/NeoGridContainer.base";
import NeoTitleMain from "design/design_components/neo/title/NeoTitleMain.base";
import Icono from 'design/assets/img/mnv/icons/icon-contracts.svg';
import NeoButtonMain from "design/design_components/neo/button/NeoButtonMain.base";
import TablaContratos from "components/common/TablaContratos";
import NeoCardPrincipal from "design/design_components/neo/panel/NeoCardPrincipal.base";
import NeoInnerBlankCardMainText from "design/design_components/neo/table/NeoInnerBlankCardMainText.base";
import NeoInnerBlankCardSecondText from "design/design_components/neo/table/NeoInnerBlankCardSecondText.base";
import NeoMessageLoading from "design/design_components/neo/messages/NeoMessageLoading.base";
import { useComponent } from 'hooks';
import useCliente from 'hooks/useCliente';
import RUTAS from "components/router/Rutas.json"

const getListaContratos = functions.httpsCallable("contratos-listaContratosConResumen");
const obtenerTipoValidaciones = functions.httpsCallable('validacion-listarTipos');
const obtenerEstados = functions.httpsCallable('contratos-listarEstados');


const ClientesContratos = () => {
    const componente = useComponent();
    const cliente = useCliente();
    const [cargando, setSpinnerCargando] = useProgressSpinner(true)
    const history = useHistory();
    const despliegaMensaje = useMensajeGlobal();
    const [listaContratos, setListaContratos] = componente.useCustomState();
    const [tiposValidaciones, setTiposValidaciones] = componente.useCustomState([]);
    const [estados, setEstados] = componente.useCustomState([]);

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        obtieneDatos();
        obtieneTiposValidacion();
        obtieneEstados();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const obtieneDatos = () => {
        getListaContratos({ clienteId: cliente.id }).then((resp) => {
            setListaContratos(resp.data);
        }).catch((error) => {
            despliegaMensaje({
                severity: "error",
                summary: `Error: ${error.code}`,
                detail: error.message
            });
        }).finally(() => {
            setSpinnerCargando(false);
        });
    }

    const obtieneTiposValidacion = () => {
        obtenerTipoValidaciones()
            .then((respuesta) => {
                setTiposValidaciones(respuesta.data);
            }).catch((error) => {
                despliegaMensaje({
                    severity: "error",
                    summary: `Error:`,
                    detail: error.message
                });
            })
    }

    const obtieneEstados = () => {
        obtenerEstados()
            .then((respuesta) => {
                setEstados(respuesta.data);
            }).catch((error) => {
                despliegaMensaje({
                    severity: "error",
                    summary: `Error:`,
                    detail: error.message
                });
            })
    }

    return (<>
        <NeoGridContainer>
                <NeoTitleMain icon={Icono} title="Lista de contratos" col="12" md="6" />
            <NeoButtonSection
                align="right"
                col="12"
                md="6"
                extra="p-col-align-center p-flex-sm-row p-flex-column">
                <NeoButtonMain
                    label="Crear nuevo contrato"
                    onClick={() => history.push(RUTAS.admin.clientes.contratos.nuevo)} />
            </NeoButtonSection>
        </NeoGridContainer>


        {
            cargando
                ? <NeoMessageLoading />
                : listaContratos
                    ? <TablaContratos
                        datos={listaContratos}
                        tiposValidaciones={tiposValidaciones}
                        estados={estados}
                        cargando={cargando} />
                    : <NeoCardPrincipal>
                        <NeoInnerBlankCardMainText>
                            El cliente no cuenta con ningún contrato
                        </NeoInnerBlankCardMainText>
                        <NeoInnerBlankCardSecondText>
                            Comunícate con tu cliente para ofertarle validaciones.
                        </NeoInnerBlankCardSecondText>
                    </NeoCardPrincipal>
        }
    </>);

};


export default ClientesContratos;