import React from "react";
import BaseButtonImage from "../../sisu/button/BaseButtonImage.sisu"


const NeoButtonImage = (props) => {
  return (
    <BaseButtonImage custom="button-img p-button-rounded p-button-outlined" {...props} />
  );
};

export default NeoButtonImage;