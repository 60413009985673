import { useContext } from 'react';
import TriggerContext from 'components/context/TriggerContext';

const useUsuario = () => {
    const {
        historial,
        evento,
        setEvento,
        reset
    } = useContext(TriggerContext);

    const nuevo = (nombre, params) => {
        const datetime = Date.now();
        setEvento({nombre, params, datetime});
    }

    return {
        historial,
        evento,
        nuevo,
        limpiar: reset
    }
}

export default useUsuario;