import BaseTabView from "../../sisu/panel/BaseTabView.sisu";

const NeoTabView = (props) => {
    return (
        <BaseTabView {...props}>
            {props.children}
        </BaseTabView>
    );
}

export default NeoTabView;