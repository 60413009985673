import { useEffect, useContext } from 'react';
import { functions, storage } from "config/firebase";
import useMensajeGlobal from "hooks/useMensajeGlobal";
import useProgressSpinner from "hooks/useProgressSpinner"
import NeoGridContainer from "design/design_components/neo/layout/NeoGridContainer.base";
import NeoTitleMain from "design/design_components/neo/title/NeoTitleMain.base";
import Icono from 'design/assets/img/mnv/icons/icon-contracts.svg';
import TablaPagos from "components/common/TablaPagos";
import NeoCardPrincipal from "design/design_components/neo/panel/NeoCardPrincipal.base";
import NeoInnerBlankCardMainText from "design/design_components/neo/table/NeoInnerBlankCardMainText.base";
import NeoMessageLoading from "design/design_components/neo/messages/NeoMessageLoading.base";
import { EstadoGlobal } from "components/context/EstadoGlobal";
import { useComponent } from 'hooks';
import RUTAS from "components/router/Rutas.json";
import useUsuario from 'hooks/useUsuario';

const PagosHistorial = () => {
    const usuario = useUsuario();
    const persona = usuario.persona;
    const componente = useComponent();
    const obtenerListaPagos = componente.useAsync(functions.httpsCallable('pago-listar'));
    const peticionGenerarFactura = componente.useAsync(functions.httpsCallable('factura-generar'));
    const [, setSpinnerCargando] = useProgressSpinner(false)
    const despliegaMensaje = useMensajeGlobal();
    const [datos, setDatos] = componente.useCustomState([]);
    const [estadoGlobal] = useContext(EstadoGlobal);
    const [dirFacturasRef, setDirFacturasRef] = componente.useCustomState();
    const storageRef = storage.ref();

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (persona?.cliente?.id) {
            setDirFacturasRef(
                storageRef.child(`clientes/${persona?.cliente?.id}/pagos/`
                ));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [persona?.cliente?.id]);

    useEffect(() => {
        if (dirFacturasRef) {
            obtieneDatos();
        }
    }, [dirFacturasRef]) // eslint-disable-line react-hooks/exhaustive-deps


    const obtieneDatos = async () => {
        setSpinnerCargando(true);
        obtenerListaPagos()
            .then((respuesta) => {
                setDatos(respuesta.data);
            }).catch((error) => {
                despliegaMensaje({
                    severity: "error",
                    summary: `Error:`,
                    detail: error.message
                });
            }).finally(() => {
                setSpinnerCargando(false);
            });
    }

    const generarFactura = async (rowData) => {
        setSpinnerCargando(true);
        peticionGenerarFactura({
            pagoId: rowData.id,
            ordenId: rowData.orden_id
        }).then((respuesta) => {
            console.log(respuesta);
        }).catch((error) => {
            despliegaMensaje({
                severity: "error",
                summary: `Error:`,
                detail: error.message
            });
        }).finally(() => {
            setSpinnerCargando(false);
        });
    }

    return (<>
        <NeoGridContainer>
            <NeoTitleMain
                icon={Icono}
                title="Historial de pagos" />
        </NeoGridContainer>

        {
            componente.isLoading
                ? <NeoMessageLoading col={12} />
                : datos.length >= 1
                    ? <TablaPagos
                        datos={datos}
                        storageRef={dirFacturasRef}
                        enlaceAOrden={RUTAS.clientes.ordenes.detalle}
                        generarFactura={generarFactura} />
                    : <NeoCardPrincipal>
                        <NeoInnerBlankCardMainText>
                            Aún no tienes pagos registrados
                        </NeoInnerBlankCardMainText>
                    </NeoCardPrincipal>
        }
    </>);

};


export default PagosHistorial;