/* eslint-disable react-hooks/exhaustive-deps */
import React, { lazy, useEffect, useRef } from "react";
import NeoTable from "design/design_components/neo/table/NeoTable.base";
import NeoTooltip from "design/design_components/neo/overlay/NeoTooltip.base";
import { capitalizar, aCadenaFechaConFormatoLocal }
    from "../../consts/generales";
import { DateTime } from "luxon";
import NeoTableColumn from 'design/design_components/neo/table/NeoTableColumn.base';

import NeoMultiSelect from "design/design_components/neo/form/NeoMultiSelect.base";
import NeoInputText from "design/design_components/neo/form/NeoInputText.base";
import NeoButtonOutlined from "design/design_components/neo/button/NeoButtonOutlined.base";
import ProgresoValidacionArchivo from "./ProgresoValidacionArchivo";
import { useComponent } from 'hooks';
import ModalFiltroFechaValidacion from "./ModalFiltroFechaValidacion";
import { useTrigger } from "hooks";
import web from 'core/scripts/web';

const TablaHistoricoValidaciones = (props) => {
    const componente = useComponent();

    const trigger = useTrigger();
    const {lazyParams, setLazyParams} = props;
    const [timer, setTimer] = componente.useCustomState(null);
    const [mostrarModalFiltroFecha, setMostrarModalFiltroFecha] = componente.useCustomState(false);
    const dt = useRef(null);
    
    // Valores de filtros seleccionados
    const [idSeleccionado, setIdSeleccionado] = componente.useCustomState("");
    const [metodoSeleccionado, setMetodoSeleccionado] = componente.useCustomState("");
    const [referenciaSeleccionada, setReferenciaSeleccionada] = componente.useCustomState("");
    const [cantidadSeleccionada, setCantidadSeleccionada] = componente.useCustomState("");
    const [estadoSeleccionado, setEstadoSeleccionado] = componente.useCustomState("");

    // Valores de filtros tipo dropdown
    const [listaMetodos,] = componente.useCustomState([{
            nombre: 'Api',
            valor: {metodo: 'Api', tipo_volumen: 'individual'}
        }, {
            nombre: 'Web',
            valor: {metodo: 'Web', tipo_volumen: 'individual'}
        }, {
            nombre: 'Web - Archivo',
            valor: {metodo: 'Web', tipo_volumen: 'archivo'}
        }, {
            nombre: 'SFTP',
            valor: {metodo: 'SFTP', tipo_volumen: 'archivo'}
        }
    ]);

    const [listaEstados,] = componente.useCustomState([
        {nombre: 'Procesando'},
        {nombre: 'Finalizado'},
        {nombre: 'Cancelado'}, 
        {nombre: 'Expirado'}
    ]);

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []);

    useEffect(() => {
        if (typeof lazyParams?.filters === 'object') {
            const filters = lazyParams.filters;

            if (typeof filters?.id === 'object') {
                setIdSeleccionado(filters.id.value);
            }

            if (typeof filters?.metodo === 'object'
                && typeof filters?.tipo_volumen === 'object') {
                const metodo = filters.metodo.value.map((elemento, index) => {
                    return {
                        metodo: elemento,
                        tipo_volumen: filters.tipo_volumen?.value[index]
                    }
                });
                setMetodoSeleccionado(metodo);
            }

            if (typeof filters?.referencia === 'object') {
                setReferenciaSeleccionada(filters.referencia.value);
            }

            if (typeof filters?.cantidad === 'object') {
                setCantidadSeleccionada(filters.cantidad.value);
            }

            if (typeof filters?.estado === 'object') {
                setEstadoSeleccionado(filters.estado.value);
            }

        }
    }, [])

    const onKeyPressTimeout = (fn, tiempo = 500) => {
        if (timer) {
            clearTimeout(timer);
            setTimer(null);
        }

        setTimer(
            setTimeout(fn, tiempo)
        );
    }

    const onPage = (event) => {
        let _lazyParams = { ...lazyParams, ...event };
        setLazyParams(_lazyParams);
    }

    const onSort = (event) => {
        let _lazyParams = { ...lazyParams, ...event };
        setLazyParams(_lazyParams);
    }

    const onFilter = (event) => {
        let _lazyParams = { ...lazyParams, ...event };
        _lazyParams['first'] = 0;
        setLazyParams(_lazyParams);
    }

    const onRowSelect = (event) => {
        props.enlaceADetalles(event.data.id)
    }


    const idBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Origen</span>
            {rowData.id}
        </>);
    }

    const idOnCambia = (value) => {
        if (value === '') 
            value = null;

        setIdSeleccionado(value);

        onKeyPressTimeout(() => {
            dt.current.filter(value, 'id', 'equals');
        })
    }

    const origenBodyTemplate = (rowData) => {
        const descripcion = () => {
            if (rowData.metodo === 'sftp') {
                return 'SFTP';
            }
            else if (rowData.tipo_volumen === 'archivo') {
                return 'Archivo'
            } else if (rowData.metodo === 'api') {
                return 'API';
            } else if (rowData.metodo === 'web') {
                return 'Web';
            }
            return rowData.metodo;
        }

        return (<>
            <span className="p-column-title">Metodo</span>
            <span className="p-d-block">{descripcion()}</span>
        </>);
    }

    const origenOnCambia = (e) => {
        let _lazyParams = { ...lazyParams };
        _lazyParams['first'] = 0;

        _lazyParams.filters = _lazyParams.filters || {};

        if (e.value.length === 0) {
            if (_lazyParams.filters?.metodo !== undefined) {
                delete _lazyParams.filters.metodo;
            }
            if (_lazyParams.filters?.tipo_volumen !== undefined) {
                delete _lazyParams.filters?.tipo_volumen;
            }
            setMetodoSeleccionado(null);
        } else {
            const metodos = e.value.map((valor) => valor.metodo);
            _lazyParams.filters.metodo = {
                value: metodos,
                matchMode: "in"
            }

            const tipos_volumen = e.value.map((valor) => valor.tipo_volumen);
            _lazyParams.filters.tipo_volumen = {
                value: tipos_volumen,
                matchMode: "in"
            }

            setMetodoSeleccionado(e.value);
        }

        setLazyParams(_lazyParams);
    }

    const referenciaBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Referencia</span>
            {rowData.api ?
                <span className="p-d-block">{rowData.api}</span>
                :
                <span className="p-d-block nombre-archivo" data-pr-tooltip={rowData.referencia}>{rowData.referencia}
                    <NeoTooltip
                        target=".nombre-archivo"
                        mouseTrack
                        mouseTrackLeft={10}
                    />
                </span>

            }

        </>);
    }

    const referenciaOnCambia = (value) => {
        if (value === '')
            value = null;

        setReferenciaSeleccionada(value);

        onKeyPressTimeout(() => {
            dt.current.filter(value, 'referencia', 'contains');
        })
    }

    const cantidadBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Cantidad</span>
            {
                rowData.cantidad
                    ? rowData.cantidad.toLocaleString('es-MX')
                    : 0
            }
        </>);
    }

    const cantidadOnCambia = (value) => {
        if (value === '')
            value = null;
        
        setCantidadSeleccionada(value);

        onKeyPressTimeout(() => {
            dt.current.filter(value, 'cantidad', 'contains');
        })
    }

    const fechaBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Fecha</span>
            {aCadenaFechaConFormatoLocal(rowData.fecha)}
        </>);
    }

    const fechaOnCambia = (fechaInicio, fechaFin) => {
        const formatDate = (fecha) => (DateTime.fromJSDate(fecha).toISODate());

        setLazyParams((lazyParams) => {
            const state = {...lazyParams};
            web.object.assign.recursive(state, {
                filters: {
                    rangoFechas: {
                        value: {
                            inicio: formatDate(fechaInicio),
                            fin: formatDate(fechaFin)
                        },
                        matchMode: 'custom'
                    }
                }
            });

            return state;
        })
    }

    const fechaParseLuxonDate = (fecha) => {
        if(fecha) {
            const fechaParseada = DateTime.fromISO(fecha).toJSDate();
            return fechaParseada;
        }

        return null;
    }

    const estadoBodyTemplate = (rowData) => {
        return (<>
            <span className="p-column-title">Estado</span>
            {
                // No se crea uno nuevo, solo se reutiliza
                rowData.tipo_volumen === 'archivo'
                    && rowData.estado === 'procesando'
                    ? <ProgresoValidacionArchivo
                        key={rowData.id}
                        dato={rowData}
                        onFinalize={() => {
                            trigger.nuevo("validacion archivo finalizada");
                        }}
                    />
                    : capitalizar(rowData.estado)
            }
        </>);
    }

    const estadoOnCambia = (e) => {
        if (e.value.length === 0)
            e.value = null;
        
        dt.current.filter(e.value, 'estado', 'in');
        setEstadoSeleccionado(e.value);
    }

    return (<>
        <NeoTable
            selectionMode="single"
            value={props.datos}
            paginator
            rows={8}
            removableSort
            ref={dt}
            onRowSelect={onRowSelect}
            totalRecords={props.totalRegistros}
            loading={props.loading}
            emptyMessage="No existen datos"
            lazy
            first={lazyParams?.first}
            onPage={onPage}
            onSort={onSort}
            onFilter={onFilter}
            filters={lazyParams?.filters}
            sortField={lazyParams?.sortField}
            sortOrder={lazyParams?.sortOrder}

        >

            <NeoTableColumn
                field="id"
                header="ID"
                filter
                sortable
                body={idBodyTemplate}
                filterElement={<NeoInputText
                    value={idSeleccionado}
                    onChange={(e) => idOnCambia(e.target.value)} 
                    />} 
                />

            <NeoTableColumn
                field="metodo"
                header="Origen"
                sortable
                filter
                body={origenBodyTemplate}
                filterElement={<NeoMultiSelect
                    value={metodoSeleccionado}
                    options={listaMetodos}
                    itemTemplate={(option) => (
                        <div className="p-multiselect-representative-option">
                            <span className="image-text">{option.nombre}</span>
                        </div>
                    )}
                    onChange={origenOnCambia}
                    optionLabel="nombre"
                    optionValue="valor"
                    placeholder="Todos"
                    className="p-column-filter" 
                />} 
            />

            <NeoTableColumn
                field="referencia"
                header="Referencia"
                filter
                sortable
                body={referenciaBodyTemplate}
                filterElement={<NeoInputText
                    value={referenciaSeleccionada}
                    onChange={(e) => referenciaOnCambia(e.target.value)} 
                />} 
            />

            <NeoTableColumn
                field="cantidad"
                header="Cantidad"
                filter
                sortable
                body={cantidadBodyTemplate}
                filterElement={<NeoInputText
                    value={cantidadSeleccionada}
                    onChange={(e) => cantidadOnCambia(e.target.value)} 
                />} 
            />

            <NeoTableColumn
                field="fecha"
                header="Fecha"
                body={fechaBodyTemplate}
                sortable
                filter
                filterElement={<NeoButtonOutlined
                    type="submit"
                    label={lazyParams?.filters?.rangoFechas?.value 
                        ? "Filtrado"
                        : "Todas"
                    }
                    icon={lazyParams?.filters?.rangoFechas?.value &&
                        "pi pi-filter"   
                    }
                    onClick={() => {setMostrarModalFiltroFecha(true)}}
                />}
            />

            <NeoTableColumn
                field="estado"
                header="Estado"
                sortable
                body={estadoBodyTemplate}
                filter
                filterElement={<NeoMultiSelect
                    value={estadoSeleccionado}
                    options={listaEstados}
                    itemTemplate={(option) => (
                        <div className="p-multiselect-representative-option">
                            <span className="image-text">{option.nombre}</span>
                        </div>
                    )}
                    onChange={estadoOnCambia}
                    optionLabel="nombre"
                    optionValue="nombre"
                    placeholder="Todos"
                    className="p-column-filter"
                    />}
                />

        </NeoTable>

        {/* Filtro fechas */}
        {mostrarModalFiltroFecha &&
            <ModalFiltroFechaValidacion
                fechaInicio={fechaParseLuxonDate(
                    lazyParams?.filters?.rangoFechas?.value?.inicio
                )}
                fechaFin={fechaParseLuxonDate(
                    lazyParams?.filters?.rangoFechas?.value?.fin
                )}
                visible={mostrarModalFiltroFecha}
                onHide={setMostrarModalFiltroFecha}
                btnReiniciar={() => {
                    setLazyParams((lazyParams) => {
                        let state = {...lazyParams};
                        if(state?.filters?.rangoFechas) {
                            delete state.filters.rangoFechas;
                        }
                        return state;
                    })
                    setMostrarModalFiltroFecha(false);
                }}
                btnAplicar={(fechaInicio, fechaFin) => {
                    fechaOnCambia(fechaInicio, fechaFin);
                    setMostrarModalFiltroFecha(false)
                }}
            />
        }
    </>)
}

export default TablaHistoricoValidaciones;