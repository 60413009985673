import { storage } from "config/firebase";

/**
 * Establece metodos para el uso de storage
 * @param  {storageRef} ref - Referencia de storage
 * @return Instancia de storage
 */
const customStorage = (ref) => {
    if(typeof ref === 'string') {
        ref = storage.ref().child(ref);
    }

    /**
     * Lista los archivos
     * @return Array con la lista de archivos
     */
    const listar = async () => {
        if(ref === undefined) {
            throw handleError('Ref no recibida');
        }

        return ref.listAll().then((res) => {
            if(Array.isArray(res.items)) {
                return res.items.map((archivo) => {
                    return operacionesConArchivos(archivo);
                });
            }
            return [];
        }).catch((error) => {
            throw handleError('Error al listar los archivos');
        });
    }

    /**
     * Elimina todos los archivos
     * @return true/false en caso de error
     */
    const eliminar = async () => {
        if(ref === undefined) {
            throw handleError('Ref no recibida');
        }

        try {
            const archivos = await listar();

            const promesas = [];
            archivos.forEach((archivo) => {
                promesas.push(archivo.eliminar());
            });

            await Promise.all(promesas);
            return true;
        } catch(error) {
            throw handleError('Error al eliminar los archivos');
        }
    }

    /**
     * Permite subir uno o varias archivos
     * @param  {archivos} array - Array con los archivos a subir
     * @param  {parametros} object - Objeto con las opciones de la petición
     * @param  {parametros.porArchivo} function - Función que se ejecutara cada cambio en el archivo
     * @param  {parametros.porEjecucion} function - Función que se ejecutara por ejecución de la función subir
     * @param  {parametros.alFinalizar} function - Función que se ejecutara al terminar todas las peticiones
     */
    const subir = async (archivos, parametros) => {
        if(ref === undefined) {
            throw handleError('Ref no recibida');
        } else if(archivos === undefined) {
            throw handleError('Archivo(s) no recibidos');
        } else if(!Array.isArray(archivos)) {
            archivos = [archivos];
        }
        
        if(parametros.maximoArchivos !== undefined) {
            const detallesDeLaRef = await obtenerDetalles();
            const totalArchivos = detallesDeLaRef.totalArchivos + archivos.length;
            if(totalArchivos > parametros.maximoArchivos) {
                throw handleError(`Solo se permite subir ${parametros.maximoArchivos} archivo(s)`);
            }
        }

        if(parametros.capacidadMaximaArchivo !== undefined) {
            archivos.forEach((archivo) => {
                if(archivo.size > parametros.capacidadMaximaArchivo) {
                    const Mb = Math.round((parametros.capacidadMaximaArchivo / 1000000) * 10) / 10;
                    throw handleError(`
                        El tamaño del archivo "${archivo.name}" 
                        supera el tamaño maximo de ${Mb} Mb permitido por archivo.
                    `);
                }
            });
        }

        if(Array.isArray(parametros.extensionesPermitidas)) {
            archivos.forEach((archivo) => {
                const extension = obtenerExtensionArchivo(archivo.name);
                if(!parametros.extensionesPermitidas.includes(extension)) {
                    throw handleError(`
                        El archivo "${archivo.name}" 
                        es un tipo de archivo no permitido.
                    `);
                }
            })
        }

        const statusArchivos = [];

        const promesas = archivos.map((archivo, key) => {
            let archivoRef = ref.child(archivo.name);
            const task = archivoRef.put(archivo);

            task.on('state_changed', (snapshot) => {
                const progreso = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                if(typeof parametros?.porArchivo === 'function') {
                    parametros.porArchivo({
                        progreso,
                        status: snapshot.state
                    });
                }

                if(typeof parametros?.porEjecucion === 'function') {
                    statusArchivos[key] = {
                        progreso
                    };
    
                    const progresoTotal = statusArchivos.reduce(
                        (progresoTotal, archivo) => ( progresoTotal + archivo.progreso )
                    , 0) / archivos.length;

                    parametros.porEjecucion({
                        progreso: Math.round(progresoTotal)
                    });
                }
            }, (error) => {
                if(typeof parametros?.porArchivo === 'function') {
                    parametros.porArchivo({
                        progreso: 100,
                        status: error.code,
                        subido: false
                    });
                }
            }, () => {
                if(typeof parametros?.porArchivo === 'function') {
                    parametros.porArchivo({
                        progreso: 100,
                        status: 'done',
                        subido: true,
                        archivo: operacionesConArchivos(task.snapshot.ref)
                    });
                }
                
            });

            return task;
        });

        Promise.allSettled(promesas).then((resultado) => {
            let correctos = 0;
            let erroneos = 0;

            resultado.forEach((result) => {
                if(result.status === 'fulfilled') {
                    correctos++;
                } else if(result.status === 'rejected') {
                    erroneos++;
                }
            });

            if(typeof parametros?.alFinalizar === 'function') {
                parametros.alFinalizar({
                    correctos,
                    erroneos
                });
            }
        });
    }

    /**
     * Obtiene los detalles del archivo
     * @return promise: al cumplirse retorna un objeto con los datos generales del archivo
     */
    const obtenerDetalles = async () => {
        try {
            const archivosEnStorage = await listar();
            const totalArchivos = archivosEnStorage.length;

            return {
                totalArchivos,
                archivos: archivosEnStorage
            }
        } catch(error) {
            throw handleError('Error al obtener los detalles del archivo');
        }
    }

    const buscar = async (archivo) => {
        if(ref === undefined) {
            throw handleError('Ref no recibida');
        }

        try {
            const file = await ref.child(archivo);
            return operacionesConArchivos(file);
        } catch(error) {
            return null;
        }
    }

    return {
        listar,
        eliminar,
        subir,
        obtenerDetalles,
        buscar
    }
}

/**
 * Establece metodos para el uso por archivo
 * @param  {archivo} archivo - Archivo a añadir dichos metodos
 * @return Instancia de archivo
 */
const operacionesConArchivos = (archivo) => {
    if(archivo === undefined) {
        throw handleError('Archivo no instanciado');
    }

    /**
     * Obtiene la url de descarga
     * @return promise: al cumplirse retorna un string con la url de descarga
     */
    const obtenerUrlDescarga = async () => {
        return archivo.getDownloadURL().then((urlDescarga) => {
            return urlDescarga;
        }).catch((error) => {
            switch (error.code) {
                case 'storage/object-not-found':
                    throw handleError('El archivo no existe');
            
                case 'storage/unauthorized':
                    throw handleError('No cuentas con permisos para acceder al archivo');
            
                case 'storage/canceled':
                    throw handleError('Operación cancelada');
            
                case 'storage/unknown':
                default:
                    throw handleError('Error inesperado');
            }
        });
    }

    /**
     * Obtiene los metadatos del archivo
     * @return promise: al cumplirse retorna un objeto con los metadatos
     */
    const obtenerMetadatos = async () => {
        return archivo.getMetadata().then((metadatos) => {
            return metadatos;
        }).catch((error) => {
            throw handleError('Error al obtener los metadatos del archivo');
        });
    }

    /**
     * Obtiene los detalles del archivo
     * @return promise: al cumplirse retorna un objeto con los datos generales del archivo
     */
    const obtenerDetalles = async () => {
        try {
            const metadatos = await obtenerMetadatos();

            return {
                nombre: archivo.name,
                contentType: metadatos.contentType,
                size: metadatos.size,
                extension: (/[.]/.exec(archivo.name)) ? /[^.]+$/.exec(archivo.name)[0] : undefined
            }
        } catch(error) {
            throw handleError('Error al obtener los detalles del archivo');
        }
    }

    /**
     * Permite descargar el archivo
     * @return promise: al cumplirse descarga el archivo
     */
    const descargar = async (url, nombre) => {
        try {
            if(url === undefined) {
                url = await obtenerUrlDescarga();
            }

            if(nombre === undefined) {
                const datos = await obtenerDetalles();
                nombre = datos.nombre;
            }

            const req = new XMLHttpRequest();
            req.open('GET', url, true);
            req.responseType = 'blob';
            req.onload = () => {
                const blob = new Blob([req.response], {
                    type: 'application/octetstream'
                });

                const isIE = document.documentMode;
                if (isIE) {
                    window.navigator.msSaveBlob(blob, nombre);
                } else {
                    const url = window.URL || window.webkitURL;
                    const link = url.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.setAttribute('href', link);
                    a.setAttribute('download', nombre);
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
            };
            req.send();
        } catch(error) {
            throw handleError('Error al descargar el archivo');
        }
    }

    /**
     * Permite abrir un archivo en el navegador
     * @return promise: al cumplirse retorna abre una pestaña del navegador para descargar el archivo
     */
    const abrir = async (url, nuevaPestana = true) => {
        try {
            if(url === undefined) {
                url = await obtenerUrlDescarga();
            }

            const a = document.createElement('a');
            a.setAttribute('href', url);
            if(nuevaPestana) {
                a.setAttribute('target', '_blank');
            }
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch(error) {
            throw handleError('Error al abrir el archivo');
        }
    }

    /**
     * Permite eliminar el archivo
     * @return promise: al cumplirse retorna true si el archivo fue eliminado
     */
    const eliminar = () => {
        return archivo.delete().then(() => {
            return true;
        }).catch((error) => {
            throw handleError('Error al eliminar el archivo');
        });
    }

    return {
        obtenerUrlDescarga,
        obtenerDetalles,
        descargar,
        abrir,
        eliminar
    }
}

/**
 * Establece el mensaje de error
 * @param  {error} String - Texto con el error
 * @return Instancia de handleError
 */
const handleError = (error) => {
    return {
        mensaje: error
    };
}

const obtenerExtensionArchivo = (nombreArchivo) => {
    const extension = nombreArchivo.slice((nombreArchivo.lastIndexOf(".")) + 1);
    return extension === nombreArchivo ? "" : extension;
}

export default customStorage;