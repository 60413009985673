export const ADMIN = {
    ID: 1,
    NAME: 'Admin'
};

export const DN = {
    ID: 2,
    NAME: 'DN',
};

export const DN_SALES = {
    ID: 3,
    NAME: 'DN Ventas'
};

export const DN_MANAGER = {
    ID: 4,
    NAME: 'Gerente de DNs'
};

export const ACCOUNTANT = {
    ID: 5,
    NAME: 'Contador'
};

export const SUPPORT = {
    ID: 6,
    NAME: 'Soporte'
};

export const CUSTOMER = {
    ID: 7,
    NAME: 'Cliente'
};