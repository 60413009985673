import { useEffect } from "react";
import { Switch, useHistory } from "react-router-dom";
import RuterRender from 'components/router/RuterRender';
import { useComponent } from "hooks";
import useCliente from 'hooks/useCliente';
import RUTAS from "components/router/Rutas.json";
import ClientesTablero from "components/views/administracion/ClientesTablero";
import ClientesEditar from "components/views/administracion/ClientesEditar";
import ClientesOrdenes from "components/views/administracion/ClientesOrdenes";
import ClientesOrdenDetalle from "components/views/administracion/ClientesOrdenDetalle";
import ClientesPaquetesEspeciales from "components/views/administracion/ClientesPaquetesEspeciales";
import ClientesBitacora from "components/views/administracion/ClientesBitacora";
import ClientesContratos from "components/views/administracion/ClientesContratos";
import ClientesPagosHistorico from "components/views/administracion/ClientesPagosHistorico";
import ClientesContratoNuevo from "components/views/administracion/ClientesContratoNuevo";
import ClientesContratosDetalle from "components/views/administracion/ClientesContratosDetalle";
import ClientesValidaciones from "components/views/administracion/ClientesValidaciones";
import ClientesValidacionesDetalle from "components/views/administracion/ClientesValidacionesDetalle";
import ClientesAyuda from "components/views/administracion/ClientesAyuda";
import ClientesValidacionesAjustar from "components/views/administracion/ClientesValidacionesAjustar";

const RutasAdministracionClientes = (props) => {
    const componente = useComponent();
    const cliente = useCliente();

    useEffect(() => {
        return () => {
            componente.desmontar();
            cliente.desmontar();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const contieneId = () => {
        if(props?.location?.state?.id) {
            return true;
        }

        return false;
    }

    return (
        <Switch>
            
            <RuterRender path={RUTAS.admin.clientes.tablero} 
                component={ClientesTablero} />

            <RuterRender path={RUTAS.admin.clientes.validaciones.lista}
                component={ClientesValidaciones} />

            <RuterRender path={RUTAS.admin.clientes.validaciones.detalle}
                redirect={contieneId} redirectValue={false} toPath={RUTAS.admin.clientes.validaciones.lista}
                component={ClientesValidacionesDetalle} />

            <RuterRender path={RUTAS.admin.clientes.validaciones.ajustar}
                component={ClientesValidacionesAjustar} />

            <RuterRender path={RUTAS.admin.clientes.editar}
                component={ClientesEditar} />

            <RuterRender path={RUTAS.admin.clientes.ordenes.lista}
                component={ClientesOrdenes} />

            <RuterRender path={RUTAS.admin.clientes.ordenes.detalle}
                redirect={contieneId} redirectValue={false} toPath={RUTAS.admin.clientes.ordenes.lista}
                component={ClientesOrdenDetalle} />

            <RuterRender path={RUTAS.admin.clientes.paquetes.especiales}
                component={ClientesPaquetesEspeciales} />

            <RuterRender path={RUTAS.admin.clientes.bitacora}
                component={ClientesBitacora} />

            <RuterRender path={RUTAS.admin.clientes.contratos.lista}
                component={ClientesContratos} />

            <RuterRender path={RUTAS.admin.clientes.contratos.detalle}
                redirect={contieneId} redirectValue={false} toPath={RUTAS.admin.clientes.contratos.lista}
                component={ClientesContratosDetalle} />

            <RuterRender path={RUTAS.admin.clientes.contratos.nuevo}
                component={ClientesContratoNuevo} />

            <RuterRender path={RUTAS.admin.clientes.ayuda}
                component={ClientesAyuda} />

            <RuterRender path={RUTAS.admin.clientes.pagos.historico}
                component={ClientesPagosHistorico} />

        </Switch>
    );
};


export default RutasAdministracionClientes;