import BaseDialogConfirmMethod from '../../sisu/overlay/BaseDialogConfirmMethod.sisu'

const NeoDialogConfirmMethod = (props) => {
    if(props.acceptLabel === undefined) 
        props.acceptLabel = 'Si';

        if(props.rejectLabel === undefined) 
        props.rejectLabel = 'No';

    return BaseDialogConfirmMethod(props);
}

export default NeoDialogConfirmMethod;