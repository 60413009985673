import React from 'react';
import { Dialog } from 'primereact/dialog';


const BaseDialog = (props) => {

  return (
    <Dialog {...props} 
    className={`${props.custom ? props.custom : ""}
    ${props.extra ? props.extra : ""}`}>
      {props.children}
    </Dialog>
  )
};


export default BaseDialog;