/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { functions } from "config/firebase";
import { DateTime } from 'luxon';
import { ProgressBar } from 'primereact/progressbar';
import { useComponent } from "hooks";


const colorAlFinalizar = '#2f9c0a';
// Tiempo que se añadira entre cada petición por cada error
const multiplicarTrasError = 1.33;


const ProgresoValidacionArchivo = (props) => {
    const componente = useComponent();
    const fnObtenerProgreso = componente.useAsync(functions.httpsCallable('validacionArchivo-obtieneAvance'));
    const [progreso, setProgreso] = componente.useCustomState(0);
    const [timer, setTimer] = componente.useCustomState(0);
    const [color, setColor] = componente.useCustomState();
    const [, setPeticionesConError] = componente.useCustomState(0);
    let timerMount = true;
    const [estadoTexto, setEstadoTexto] = componente.useCustomState();
    let tiempoEntrePeticiones = 10000;


    useEffect(() => {
        if(props?.dato) {
            if(props?.dato?.cantidad) {
                if(props?.dato?.cantidad > 500) {
                    tiempoEntrePeticiones = 30000;
                }
            }
    
            setTimer(setTimeout(obtenerProgreso, tiempoEntrePeticiones));
        }

        return () => {
            detenerTimer();
            componente.desmontar();
        }
    }, []);

    const detenerTimer = () => {
        timerMount = false;
        clearTimeout(timer);
        setTimer(null);
    }

    const obtenerProgreso = () => {        
        if(timerMount) {
            const horaInicioEstaPeticion = DateTime.now();
            fnObtenerProgreso({
                validacionId: props?.dato?.id
            }).then(respuesta => {
                setEstadoTexto(respuesta.data.estado);
                if(respuesta.data.porcentaje === 'No disponible') {
                    setPeticionesConError(vecesMarcoErrorAntes => {
                        const vecesMarcoError = vecesMarcoErrorAntes + 1;
                        tiempoEntrePeticiones = tiempoEntrePeticiones
                            * multiplicarTrasError;
                        estableceEnvioSiguientePeticion(horaInicioEstaPeticion);
                        return vecesMarcoError;
                    });
                } else {
                    setProgreso(respuesta.data.porcentaje);
                    if(respuesta.data.estado === 'Finalizado'
                            || respuesta.data.estado === 'Cancelado') {
                        setColor(colorAlFinalizar);
                        if(typeof props.onFinalize === 'function') {
                            props.onFinalize({
                                datoId: props?.dato?.id
                            });
                        }
                    } else {
                        estableceEnvioSiguientePeticion(horaInicioEstaPeticion);
                    }
                }
            }).catch(error => {
                setPeticionesConError(vecesMarcoErrorAntes => {
                    const vecesMarcoError = vecesMarcoErrorAntes + 1;
                    tiempoEntrePeticiones = tiempoEntrePeticiones
                        * multiplicarTrasError;
                    estableceEnvioSiguientePeticion(horaInicioEstaPeticion);
                    return vecesMarcoError;
                });
            });
        }
    }

    const estableceEnvioSiguientePeticion = (horaInicioUltimaPeticion) => {
        const horaFinPeticion = DateTime.now();
        const diferencia = horaFinPeticion.diff(horaInicioUltimaPeticion);
        const milisegTranscurridos = diferencia.toObject().milliseconds;

        let ejecutarEnEstosSegundos = 0;
        if(tiempoEntrePeticiones > milisegTranscurridos) {
            ejecutarEnEstosSegundos = tiempoEntrePeticiones - milisegTranscurridos;
        }

        setTimer(setTimeout(obtenerProgreso, ejecutarEnEstosSegundos));
    }

    return(<>
        { estadoTexto }
        <ProgressBar value={progreso} color={color} />
    </>);
}

export default ProgresoValidacionArchivo;