import BaseAccordion from '../../sisu/panel/BaseAccordion.sisu'

const NeoAccordion = (props) => {
    return (
        <BaseAccordion {...props} >
            {props.children}
        </BaseAccordion>
    )
}

export default NeoAccordion;