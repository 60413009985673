import { useEffect } from 'react';
import useProgressSpinner from "hooks/useProgressSpinner"
import { functions } from "config/firebase";
import useMensajeGlobal from "hooks/useMensajeGlobal";
import NeoGridContainer from "design/design_components/neo/layout/NeoGridContainer.base";
import NeoTitleSecond from "design/design_components/neo/title/NeoTitleSecond.base";
import NeoButtonSection from "design/design_components/neo/layout/NeoButtonSection.base";
import NeoButtonMain from "design/design_components/neo/button/NeoButtonMain.base";
import Icono from 'design/assets/img/mnv/icons/icon-contracts.svg';
import DetalleContrato from "components/common/DetalleContrato";
import NeoDialogConfirm from "design/design_components/neo/overlay/NeoDialogConfirm.base";
import { useComponent } from 'hooks';
import { useHistory } from "react-router-dom";


const ContratosDetalle = (props) => {
    const componente = useComponent();
    const obtieneDetalleContrato = componente.useAsync(functions.httpsCallable("contratos-obtieneDetalle"));
    const [, setSpinnerCargando] = useProgressSpinner(true)
    const despliegaMensaje = useMensajeGlobal();
    const [contratoId, setContratoId] = componente.useCustomState();
    const [contrato, setContrato] = componente.useCustomState({});
    const [esReactivable, setEsReactivable] = componente.useCustomState(false);
    const [muestraDialogoRenovacionActivar, setMuestraDialogoRenovacionActivar] = componente.useCustomState();
    const [muestraDialogoRenovacionCancelar, setMuestraDialogoRenovacionCancelar] = componente.useCustomState();
    const mensajeRenovacionActivar = "¿Deseas activar la renovación automática?"
    const mensajeRenovacionCancelar = "¿Deseas cancelar la renovación automática?"
    const historial = useHistory();

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        props.location.state
            && setContratoId(props.location.state.id);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        contratoId && obtieneDatos()

    }, [contratoId]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (contrato && contrato.vigente_hasta) {
            if (Number(new Date(contrato.vigente_hasta) - new Date()) > 0)
                setEsReactivable(true)
            else
                setEsReactivable(false)
        }
    }, [contrato]); // eslint-disable-line react-hooks/exhaustive-deps

    const obtieneDatos = async () => {
        obtieneDetalleContrato({
            contratoId: contratoId
        }).then((resultado) => {
            setContrato(resultado.data);
        }).catch((error) => {
            console.log("Error: ", error);
            despliegaMensaje({
                severity: "error",
                summary: "Error",
                detail: `${error.message} (${error.code})`
            });
        }).finally(() => {
            setSpinnerCargando(false);
        });
    }

    const pideActivarRenovacion = async () => {
        console.log("Pide activar renovación")
        const activaRenovacion = functions.httpsCallable("contratos-cambiaRenovacion");

        activaRenovacion({ contratoId: Number(contratoId), estado: 1 })
            .then(() => {
                despliegaMensaje({
                    severity: "success",
                    summary: "",
                    detail: "El contrato ha sido cancelado."
                });
                historial.goBack();
            }).catch((error) => {
                console.log("Error: ", error);
                despliegaMensaje({
                    severity: "error",
                    summary: `Error: ${error.code}`,
                    detail: error.message
                });
            });

    }

    const pideCancelarRenovacion = async () => {
        const cancelaRenovacion = functions.httpsCallable("contratos-cambiaRenovacion");

        cancelaRenovacion({ contratoId: Number(contratoId), estado: 0 })
            .then(() => {
                despliegaMensaje({
                    severity: "success", summary: "",
                    detail: "La renovación fue cancelada.", life: 3500
                });
                historial.goBack();
            }).catch((error) => {
                console.log("Error: ", error);
                despliegaMensaje({
                    severity: "error",
                    summary: `Error: ${error.code}`,
                    detail: error.message
                });
            });
    }


    return (<>
        <NeoGridContainer>
            <NeoTitleSecond icon={Icono} title="Detalle de contrato" />

            <NeoButtonSection align="right" col="12" md="6"
                extra="p-col-align-center p-flex-sm-row p-flex-column">
                {
                    contrato
                        && Boolean(contrato.renovacion_automatica) === false
                        && esReactivable &&
                        <NeoButtonMain
                            label="Activar renovación automática"
                            onClick={() => setMuestraDialogoRenovacionActivar(true)} />
                }
                {
                    contrato
                        && Boolean(contrato.renovacion_automatica) === true
                        && esReactivable &&
                        <NeoButtonMain
                            label="Desactivar renovación automática"
                            onClick={() => setMuestraDialogoRenovacionCancelar(true)} />
                }
            </NeoButtonSection>
        </NeoGridContainer>

        <DetalleContrato contrato={contrato} cargando={componente.isLoading} />

        <NeoDialogConfirm
            visible={muestraDialogoRenovacionCancelar}
            onHide={() => setMuestraDialogoRenovacionCancelar(false)}
            message={mensajeRenovacionCancelar}
            header="Confirmación"
            icon="pi pi-exclamation-triangle"
            accept={pideCancelarRenovacion} />
        <NeoDialogConfirm
            visible={muestraDialogoRenovacionActivar}
            onHide={() => setMuestraDialogoRenovacionActivar(false)}
            message={mensajeRenovacionActivar}
            header="Confirmación"
            icon="pi pi-exclamation-triangle"
            accept={pideActivarRenovacion} />
    </>);

};


export default ContratosDetalle;
