/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useComponent } from 'hooks';
import NeoCard from "design/design_components/neo/panel/NeoCard.base";
import NeoColumn from "design/design_components/neo/layout/NeoColumn.base";
import NeoInnerTitle from "design/design_components/neo/title/NeoInnerTitle.base";
import NeoGridContainer from "design/design_components/neo/layout/NeoGridContainer.base";
import NeoDataSecond from "design/design_components/neo/data/NeoDataSecond.base";
import { capitalizar, formateaANumeroLocal } from "consts/generales";


const StatusActualCliente = (props) => {
    const componente = useComponent();
    const [validaciones, setValidaciones] = componente.useCustomState([]);

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []);

    useEffect(() => {
        if (props.validaciones) {
            setValidaciones(props.validaciones)
        }
    }, [props.validaciones])

    return (<NeoCard>
        <NeoColumn >
            <NeoInnerTitle extra="primero">
                Validaciones disponibles del cliente
            </NeoInnerTitle>

            <NeoGridContainer>
                {
                    validaciones
                    && Array.isArray(validaciones)
                    && validaciones.map((elemento, indice) => {
                        return (<>
                            <NeoColumn >
                                <NeoDataSecond
                                    fact={formateaANumeroLocal(elemento.cantidad) || "0"}
                                    label={capitalizar(elemento.tipoValidacion)}
                                    key={indice} />
                            </NeoColumn>
                        </>);
                    })
                }
            </NeoGridContainer>
        </NeoColumn>
    </NeoCard>)
}

export default StatusActualCliente;