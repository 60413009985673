import { useEffect } from "react";
import { functions } from "config/firebase";
import { useHistory, useLocation } from "react-router-dom";
import useMensajeGlobal from "hooks/useMensajeGlobal";
import useProgressSpinner from "hooks/useProgressSpinner"
import NeoTitleSecond from "design/design_components/neo/title/NeoTitleSecond.base";
import NeoButtonSection from "design/design_components/neo/layout/NeoButtonSection.base";
import NeoButtonMain from "design/design_components/neo/button/NeoButtonMain.base";
import NeoGridContainer from "design/design_components/neo/layout/NeoGridContainer.base";
import Icono from 'design/assets/img/mnv/icons/icon-contracts.svg';
import DetalleContrato from "components/common/DetalleContrato";
import NeoDialogConfirm from "design/design_components/neo/overlay/NeoDialogConfirm.base";
import { useComponent } from "hooks";

const ClientesContratosDetalle = () => {
    const componente = useComponent();
    const obtieneDetalleContrato = componente.useAsync(functions.httpsCallable("contratos-obtieneDetalle"));
    const [, setSpinnerCargando] = useProgressSpinner(true)
    const despliegaMensaje = useMensajeGlobal();
    let location = useLocation();
    const [contratoId, setcontratoId] = componente.useCustomState();
    const [contrato, setContrato] = componente.useCustomState()
    let historial = useHistory();
    const [muestraDialogoCancela, setMuestraDialogoCancela] = componente.useCustomState();
    const [muestraDialogoReactiva, setMuestraDialogoReactiva] = componente.useCustomState();
    const [muestraDialogoRenovacionActivar, setMuestraDialogoRenovacionActivar] = componente.useCustomState();
    const [muestraDialogoRenovacionCancelar, setMuestraDialogoRenovacionCancelar] = componente.useCustomState();
    const mensajeCancelar = "¿Deseas proceder a cancelar este contrato?"
    const mensajeReactivar = "Al reactivar el contrato se volverán a hacer cargos al cliente, ¿deseas continuar?"
    const mensajeRenovacionActivar = "¿Deseas activar la renovación automática?"
    const mensajeRenovacionCancelar = "¿Deseas cancelar la renovación automática?"
    const [esReactivable, setEsReactivable] = componente.useCustomState(false);

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        location
            && location.state
            && location.state.id
            && setcontratoId(location.state.id);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.state]);

    useEffect(() => {
        contratoId && obtieneDatosGenerales();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contratoId]);

    useEffect(() => {
        if (contrato && contrato.vigente_hasta) {
            if (Number(new Date(contrato.vigente_hasta) - new Date()) > 0)
                setEsReactivable(true)
            else
                setEsReactivable(false)
        }
    }, [contrato]); // eslint-disable-line react-hooks/exhaustive-deps

    const obtieneDatosGenerales = async () => {
        obtieneDetalleContrato({
            contratoId: contratoId
        }).then((resultado) => {
            setContrato(resultado.data)
        }).catch((error) => {
            console.log("Error: ", error);
            despliegaMensaje({
                severity: "error",
                summary: "Error",
                detail: `${error.message} (${error.code})`
            });
        }).finally(() => {
            setSpinnerCargando(false);
        });
    }

    const pideActivarRenovacion = async () => {
        console.log("Pide activar renovación")
        const activaRenovacion = functions.httpsCallable("contratos-cambiaRenovacion");

        activaRenovacion({ contratoId: Number(contratoId), estado: 1 })
            .then(() => {
                despliegaMensaje({
                    severity: "success",
                    summary: "",
                    detail: "El contrato ha sido cancelado."
                });
                historial.goBack();
            }).catch((error) => {
                console.log("Error: ", error);
                despliegaMensaje({
                    severity: "error",
                    summary: `Error: ${error.code}`,
                    detail: error.message
                });
            });

    }

    const pideCancelarRenovacion = async () => {
        const cancelaRenovacion = functions.httpsCallable("contratos-cambiaRenovacion");

        cancelaRenovacion({ contratoId: Number(contratoId), estado: 0 })
            .then(() => {
                despliegaMensaje({
                    severity: "success", summary: "",
                    detail: "La renovación fue cancelada.", life: 3500
                });
                historial.goBack();
            }).catch((error) => {
                console.log("Error: ", error);
                despliegaMensaje({
                    severity: "error",
                    summary: `Error: ${error.code}`,
                    detail: error.message
                });
            });
    }

    const pideCancelarContrato = async () => {
        const cancelaContrato = functions.httpsCallable("cancelaContrato");

        cancelaContrato({ contratoId: Number(contratoId) })
            .then(() => {
                despliegaMensaje({
                    severity: "success",
                    summary: "",
                    detail: "El contrato ha sido cancelado."
                });
                historial.goBack();
            }).catch((error) => {
                console.log("Error: ", error);
                despliegaMensaje({
                    severity: "error",
                    summary: `Error: ${error.code}`,
                    detail: error.message
                });
            });

    }

    const pideReactivarContrato = async () => {
        console.log("Pide cancelar contrato")
        const reactivaContrato = functions.httpsCallable("reactivaContrato");

        reactivaContrato({ contratoId: Number(contratoId) })
            .then(() => {
                despliegaMensaje({
                    severity: "success", summary: "",
                    detail: "El contrato ha sido reactivado.", life: 3500
                });
                historial.goBack();
            }).catch((error) => {
                console.log("Error: ", error);
                despliegaMensaje({
                    severity: "error",
                    summary: `Error: ${error.code}`,
                    detail: error.message
                });
            });
    }


    return (<>
        <NeoGridContainer>
            <NeoTitleSecond
                col="12"
                md="6"
                icon={Icono}
                title="Contrato" />

            <NeoButtonSection align="right" col="12" md="6"
                extra="p-col-align-center p-flex-sm-row p-flex-column">

                {
                    contrato
                        && Boolean(contrato.renovacion_automatica) === false
                        && esReactivable &&
                        <NeoButtonMain
                            label="Activar renovación automática"
                            onClick={() => setMuestraDialogoRenovacionActivar(true)} />
                }
                {
                    contrato
                        && Boolean(contrato.renovacion_automatica) === true
                        && esReactivable &&
                        <NeoButtonMain
                            label="Desactivar renovación automática"
                            onClick={() => setMuestraDialogoRenovacionCancelar(true)} />
                }

                {
                    contrato
                        && Boolean(contrato.activo) === true
                        ? <NeoButtonMain
                            label="Cancelar contrato"
                            onClick={() => setMuestraDialogoCancela(true)} />
                        : null
                }
                {
                    contrato
                        && Boolean(contrato.activo) === false
                        && esReactivable
                        ? <NeoButtonMain
                            label="Reactivar contrato"
                            onClick={() => setMuestraDialogoReactiva(true)} />
                        : null
                }

            </NeoButtonSection>
        </NeoGridContainer>

        <DetalleContrato contrato={contrato} cargando={componente.isLoading} />

        <NeoDialogConfirm
            visible={muestraDialogoRenovacionCancelar}
            onHide={() => setMuestraDialogoRenovacionCancelar(false)}
            message={mensajeRenovacionCancelar}
            header="Confirmación"
            icon="pi pi-exclamation-triangle"
            accept={pideCancelarRenovacion} />
        <NeoDialogConfirm
            visible={muestraDialogoRenovacionActivar}
            onHide={() => setMuestraDialogoRenovacionActivar(false)}
            message={mensajeRenovacionActivar}
            header="Confirmación"
            icon="pi pi-exclamation-triangle"
            accept={pideActivarRenovacion} />

        <NeoDialogConfirm
            visible={muestraDialogoCancela}
            onHide={() => setMuestraDialogoCancela(false)}
            message={mensajeCancelar}
            header="Confirmación"
            icon="pi pi-exclamation-triangle"
            accept={pideCancelarContrato} />
        <NeoDialogConfirm
            visible={muestraDialogoReactiva}
            onHide={() => setMuestraDialogoReactiva(false)}
            message={mensajeReactivar}
            header="Confirmación"
            icon="pi pi-exclamation-triangle"
            accept={pideReactivarContrato} />
    </>);
};


export default ClientesContratosDetalle;