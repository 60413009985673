import { useHistory } from 'react-router-dom';
import useMensajeGlobal from "hooks/useMensajeGlobal";
import NeoDialog from "design/design_components/neo/overlay/NeoDialog.base";
import NeoButtonSection from "design/design_components/neo/layout/NeoButtonSection.base";
import NeoButtonMain from "design/design_components/neo/button/NeoButtonMain.base";
import NeoGridContainer from "design/design_components/neo/layout/NeoGridContainer.base";
import NeoColumn from "design/design_components/neo/layout/NeoColumn.base";
import { useComponent } from 'hooks';
import { aDivisaLocal } from "consts/generales";
import NeoNote from "design/design_components/neo/data/NeoNote.base";

const FormularioSeleccionarTipoPagoModal = (props) => {
    const componente = useComponent({
        data: {
            pagoProcesando: '',
            cuentaClabeSTP: ''
        }
    });
    const despliegaMensaje = useMensajeGlobal();
    const obtieneCuentaClabeSTP = props.obtieneCuentaClabeSTP;
    const checkoutTDC = props.checkoutTDC;
    const historial = useHistory();
    const orden = props.orden;

    const procesaPagoStripe = () => {
        componente.data.pagoProcesando = 'Stripe';
        checkoutTDC({
            ordenId: orden.id
        }).then((resp) => {
            console.log("Yendo a procesar pago")

            if (resp.data?.sessionUrl) {
                window.location.href = resp.data.sessionUrl;
            }
        }).catch((error) => {
            despliegaMensaje({
                severity: "error",
                summary: `Error:`,
                detail: error.message
            });
        });
    };

    const procesaPagoSTP = () => {
        componente.data.pagoProcesando = 'STP';
        obtieneCuentaClabeSTP()
        .then((resp) => {
            componente.data.cuentaClabeSTP = resp.data;
        }).catch((error) => {
            despliegaMensaje({
                severity: "error",
                summary: `Error:`,
                detail: error.message
            });
        });
    };

    const tipoPagos = [
        {
            id: 1,
            nombre: "Stripe",
            etiqueta: "Tarjeta de crédito/débito",
            click: () => procesaPagoStripe()
        },
        // {
        //     id: 2,
        //     nombre: "STP",
        //     etiqueta: "Transferencia bancaria",
        //     click: () => procesaPagoSTP()
        // }
    ];
    
    return (<>
        {(componente.data.pagoProcesando === '' || componente.data.pagoProcesando === 'STP')
            && componente.data.cuentaClabeSTP === '' &&
            <NeoDialog
                header="Selecciona el tipo de pago"
                visible={props.muestraModal}
                style={{ width: '30vw' }}
                onHide={() => props.setMuestraModal(false)}>

                <NeoGridContainer col="12">
                    <NeoButtonSection align="center">
                        {Array.isArray(tipoPagos) &&
                            tipoPagos.map((tipo, key) => {
                                return <NeoButtonMain key={key} label={tipo.etiqueta} onClick={tipo.click} loading={componente.data?.pagoProcesando === tipo.nombre} />
                            })
                        }
                    </NeoButtonSection>
                </NeoGridContainer>
            </NeoDialog>
        }

        {componente.data.pagoProcesando === 'STP' 
            && componente.data.cuentaClabeSTP !== '' &&
            <NeoDialog
                header="Transferencia por STP"
                visible={true}
                style={{ width: '30vw' }}
                onHide={() => {
                    props.setMuestraModal(false);
                    componente.data.pagoProcesando = '';
                    componente.data.cuentaClabeSTP = '';
                }}>

                <NeoGridContainer col="12">
                    <NeoColumn col="12" >
                        Cuenta CLABE: {componente.data.cuentaClabeSTP}
                    </NeoColumn>
                    <NeoColumn col="12" >
                        Total: ${aDivisaLocal(orden.precio_total)} MXN
                    </NeoColumn>

                </NeoGridContainer>
                <NeoGridContainer col="12">
                    <NeoColumn col="12" >
                        <NeoNote text="El monto a transferir tiene que ser exacto al total de la orden que se quiere liquidar, de lo contrario se retorna la transferencia." />
                    </NeoColumn>
                    <NeoColumn col="12" >
                        <NeoNote text="En caso de que existan 2 órdenes con el mismo total, se liquidará la orden más antigua." />
                    </NeoColumn>
                </NeoGridContainer>
            </NeoDialog>
        }
    </>)
}

export default FormularioSeleccionarTipoPagoModal;