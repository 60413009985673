import { DateTime } from 'luxon';
import { generarID } from 'consts/generales';
import { TIPO, NIVEL, ESTADO, ACCION } from './constantes';
import exception from './exception';

/** Clase que representa una notificación. */
class Notificacion {

    /**
     * Create una notificación
     * @param {object} params - Atributos de la notificación
     * @param {string} params.titulo - Descripción de la notificación
     * @param {number} [params.estado=PENDIENTE] - Estado de la notificación
     * @param {function} methods.actualizar - Función que actualiza el valor de las propiedades de la notificación
     * @param {function} methods.eliminar - Función que elimina la notificación
     */
    constructor(params, {actualizar, eliminar}) {
        if(!params.titulo)
            throw new exception({codigo: 1, mensaje: 'Titulo no definido'});

        // Herencia de metodos
        this.__actualizar__ = actualizar;
        this.__eliminar__ = eliminar;

        // Atributos
        this.__id__ = params.id;
        this.__titulo__ = params.titulo;
        this.__descripcion__ = params.descripcion;

        if(params.datos) {
            try {
                this.__datos__ = JSON.parse(params.datos);
            } catch(e) {
                console.error(`Error al parsear los datos "${params.datos}"`);
            }
        } else {
            this.__datos__ = null;
        }

        this.__codigo__ = params.codigo || generarID();
        
        this.__fechaCreacion__ = params.fechaCreacion
            ? DateTime.fromISO(params.fechaCreacion) 
            : DateTime.now();

        if(typeof params.tipo !== 'number') {
            throw new exception({codigo: 2, mensaje: `El tipo de notificación "${params.tipo}" no es un valor valido`});
        } else if (!Object.values(TIPO).includes(params.tipo)) {
            throw new exception({codigo: 2, mensaje: `No existe el tipo "${params.tipo}"`});
        } else {
            this.__tipo__ = params.tipo;
        }

        if(params.nivel === undefined) {
            this.__nivel__ = NIVEL.NO_URGENTE;
        } else if (!Object.values(NIVEL).includes(params.nivel)) {
            throw new exception({codigo: 2, mensaje: `No existe el nivel ${params.nivel}`});
        } else {
            this.__nivel__ = params.nivel;
        }
        
        if(params.estado === undefined || params.estado === null) {
            this.__estado__ = ESTADO.PENDIENTE;
        } else if (!Object.values(ESTADO).includes(params.estado)) {
            throw new exception({codigo: 2, mensaje: `No existe el estado ${params.estado}`});
        } else {
            this.__estado__ = params.estado;
        }

        if(params.accion === undefined || params.accion === null) {
            this.__accion__ = null;
        } else if (!Object.values(ACCION).includes(params.accion)) {
            throw new exception({codigo: 2, mensaje: `No existe la acción ${params.accion}`});
        } else {
            this.__accion__ = params.accion;
        }

        // Acción
        const _this = this;
        this.onClick = () => {
            _this.estadoAnterior = _this.estado;

            if(typeof params.onClick === 'function')
                params.onClick(_this);

            if(_this.estado !== ESTADO.VISTA)
                _this.estado = ESTADO.VISTA;
        };
    }

    get id() {
        return this.__id__;
    }

    set id(valor) {
        if(typeof valor !== 'number') {
            throw new exception({codigo: 3, mensaje: `El id no es un numero`});
        }

        const old = this.clone();

        this.__id__ = valor;

        this.actualizar('id', old);
    }

    get titulo() {
        return this.__titulo__;
    }

    set titulo(valor) {
        if(typeof valor !== 'string') {
            throw new exception({codigo: 3, mensaje: `El titulo no es un string`});
        }

        const old = this.clone();

        this.__titulo__ = valor;

        this.actualizar('titulo', old);
    }

    get descripcion() {
        return this.__descripcion__;
    }

    set descripcion(valor) {
        if(valor !== undefined && valor !== null) {
            if(typeof valor !== 'string') {
                throw new exception({codigo: 3, mensaje: `La descripción no es un string`});
            }
        }

        const old = this.clone();

        this.__descripcion__ = valor;

        this.actualizar('descripcion', old);
    }

    get datos() {
        return this.__datos__;
    }

    set datos(valor) {
        if(typeof valor !== 'object') {
            throw new exception({codigo: 3, mensaje: `El valor de datos no es un objecto`});
        }

        const old = this.clone();

        this.__datos__ = valor;

        this.actualizar('datos', old);
    }

    get codigo() {
        return this.__codigo__;
    }

    set codigo(valor) {
        if(typeof valor !== 'string') {
            throw new exception({codigo: 3, mensaje: `El codigo no es un string`});
        } else if(typeof this.__codigo__ === 'string') {
            throw new exception({codigo: 3, mensaje: `El codigo es un valor constante, por lo que no es posible modificar`});
        }

        const old = this.clone();

        this.__codigo__ = valor;

        this.actualizar('codigo', old);
    }

    get fechaCreacion() {
        return this.__fechaCreacion__;
    }

    set fechaCreacion(valor) {
        if(!(valor instanceof DateTime)) {
            throw new exception({codigo: 3, mensaje: `El atributo fechaCreacion no es un tipo DateTime`});
        } else if(!valor.isValid) {
            throw new exception({codigo: 3, mensaje: `El atributo fechaCreacion no es una fecha valida`});
        }

        const old = this.clone();

        this.__fechaCreacion__ = valor;

        this.actualizar('fechaCreacion', old);
    }

    get tipo() {
        return this.__tipo__;
    }

    set tipo(valor) {
        if (!Object.values(TIPO).includes(valor)) {
            throw new exception({codigo: 2, mensaje: `No existe el tipo ${valor}`});
        }

        const old = this.clone();

        this.__tipo__ = valor;

        this.actualizar('tipo', old);
    }

    get nivel() {
        return this.__nivel__;
    }

    set nivel(valor) {
        if (!Object.values(NIVEL).includes(valor)) {
            throw new exception({codigo: 2, mensaje: `No existe el nivel ${valor}`});
        }

        const old = this.clone();

        this.__nivel__ = valor;

        this.actualizar('nivel', old);
    }

    get estado() {
        return this.__estado__;
    }

    set estado(valor) {
        if (!Object.values(ESTADO).includes(valor)) {
            throw new exception({codigo: 2, mensaje: `No existe el estado ${valor}`});
        }

        const old = this.clone();

        this.__estado__ = valor;

        this.actualizar('estado', old);
    }

    get accion() {
        return this.__accion__;
    }

    set accion(valor) {
        if (!Object.values(ACCION).includes(valor)) {
            throw new exception({codigo: 2, mensaje: `No existe la acción ${valor}`});
        }

        const old = this.clone();

        this.__accion__ = valor;

        this.actualizar('accion', old);
    }

    actualizar (property, old) {
        this.__actualizar__(this, property, old);
    }

    eliminar () {
        this.__eliminar__(this);
    }

    clone () {
        return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
    }
}

export default Notificacion;