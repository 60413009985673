import React from 'react';
import { FileUpload } from 'primereact/fileupload';


const BaseInputFile = React.forwardRef((props, ref) => {
  return (
    <>
      <FileUpload
        {...props}
        ref={ref}
        className={`
                    ${props.custom ? props.custom : ""}
                    ${props.extra ? props.extra : ""} `}>
        {props.children}
      </FileUpload>
    </>);
});

export default BaseInputFile;
