import React from "react";
import BaseCard from "../../sisu/panel/BaseCard.sisu";
import NeoGridContainer from "../layout/NeoGridContainer.base";


const NeoCard = (props) => {
	return (<>

		{props.superTitle ?
			<p>{props.superTitle}</p> : null
		}
		<BaseCard {...props}>
			<NeoGridContainer subExtra={`p-grid ${props.valign ? props.valign : "p-ai-center"} `}>
				{props.children}
			</NeoGridContainer>
		</BaseCard>
	</>);
};

export default NeoCard;