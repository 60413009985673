import { useEffect } from "react";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { nightOwl } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { functions } from "config/firebase";
import useMensajeGlobal from "hooks/useMensajeGlobal";
import useProgressSpinner from "hooks/useProgressSpinner"
import { capitalizar } from "consts/generales";
import Icono from 'design/assets/img/mnv/icons/icon-apis.svg';
import NeoGridContainer from "design/design_components/neo/layout/NeoGridContainer.base";
import NeoTitleSecond from "design/design_components/neo/title/NeoTitleSecond.base";
import NeoInnerTitle from "design/design_components/neo/title/NeoInnerTitle.base";
import NeoColumn from "design/design_components/neo/layout/NeoColumn.base";
import NeoDataSecond from "design/design_components/neo/data/NeoDataSecond.base";
import NeoCard from "design/design_components/neo/panel/NeoCard.base";
import NeoTabView from "design/design_components/neo/panel/NeoTabView.base";
import NeoTabPanel from "design/design_components/neo/panel/NeoTabPanel.base";
import { cargarSnippets } from "consts/documentacionApiValida";
import { useComponent } from "hooks";

const getDetalleApiKey = functions.httpsCallable("getDetalleApiKey");

const ApiKeyDocumentacion = (props) => {
    const componente = useComponent();
    const despliegaMensaje = useMensajeGlobal();
    const [, setSpinnerCargando] = useProgressSpinner(true);
    const [snippetActive, setSnippetActive] = componente.useCustomState(0);

    const [apiKeyId, setApiKeyId] = componente.useCustomState();
    const [apiKeyDetalles, setApiKeyDetalles] = componente.useCustomState({
        llave: ""
    });
    const [snippets, setSnippets] = componente.useCustomState([]);
    const ejemploPeticion = {
        url: process.env.REACT_APP_ENDPOINT_VALIDA,
        contentType: "application/json",
        datos: {
            key: apiKeyDetalles.llave,
            dato: "correo@prueba.com",
            tipo: "correo"
        },
        respuesta: {
            valida: {
                resultado: "valido",
                razon: "Válido"
            },
            riesgoso: {
                resultado: "riesgoso",
                razon: "Baja calidad"
            },
            error: {
                error: "true",
                razon: "Saldo insuficiente"
            }
        }
    };

    useEffect(() => {
        return () => { componente.desmontar(); };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        props.location?.state && setApiKeyId(props.location.state?.id)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (apiKeyId === undefined)
            return;

        (async () => {
            try {
                const resultGetDetallesApiKey = await getDetalleApiKey({ apiKeyId });
                setApiKeyDetalles(resultGetDetallesApiKey.data)
            } catch (error) {
                despliegaMensaje({
                    severity: "error",
                    summary: `Error: ${error.code}`,
                    detail: error.message
                });
            } finally {
                setSpinnerCargando(false);
            }
        })()
    }, [apiKeyId]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setSnippets(cargarSnippets(ejemploPeticion));
    }, [apiKeyDetalles]) // eslint-disable-line react-hooks/exhaustive-deps

    const getOpcionesNeoTabPanel = () => {
        return Object.keys(snippets).map(key => {
            return (
                <NeoTabPanel key={key} header={key}>
                    <SyntaxHighlighter language={snippets[key].lenguaje} wrapLongLines showLineNumbers style={nightOwl}>
                        {snippets[key].snippet}
                    </SyntaxHighlighter>
                </NeoTabPanel>);
        })
    }

    return (<>
        <NeoGridContainer>
            <NeoTitleSecond col="12"
                title="Documentación API REST" icon={Icono} />
        </NeoGridContainer>
        <NeoCard>
            <NeoColumn md="7">
                <NeoDataSecond fact={apiKeyDetalles.llave}
                    label="Llave API" />
            </NeoColumn>
            <NeoColumn md="5">
                <NeoDataSecond fact={capitalizar(apiKeyDetalles.nombre)}
                    label="Nombre" />
            </NeoColumn>
            <NeoInnerTitle>
                Ejemplo de implementación
            </NeoInnerTitle>
            <NeoColumn md="12">
                <NeoTabView activeIndex={snippetActive} onTabChange={(e) => setSnippetActive(e.index)}>
                    {getOpcionesNeoTabPanel()}
                </NeoTabView>
            </NeoColumn>
        </NeoCard>
        <NeoCard>
            <NeoInnerTitle extra="primero">
                Datos para la petición
            </NeoInnerTitle>
            <NeoColumn md="6">
                <ul>
                    <li>
                        <strong>key</strong> - Identificador de la cuenta
                    </li>
                    <li>
                        <strong>dato</strong> - Dato a validar
                    </li>
                    <li>
                        <strong>tipo</strong> - Tipo de validación
                        <ul>
                            <li>opciones: <i>correo</i>, <i>telefono</i></li>
                        </ul>
                    </li>
                    <li>
                        <strong>datoAdicional</strong> - Dato adicional a almacenar con la validación (Opcional)
                    </li>
                </ul>
            </NeoColumn>
            <NeoColumn md="6">
                <SyntaxHighlighter language="json" style={nightOwl}>
                    {JSON.stringify(ejemploPeticion.datos, null, 2)}
                </SyntaxHighlighter>
            </NeoColumn>
        </NeoCard>

        <NeoCard>
            <NeoInnerTitle extra="primero">
                Datos en la respuesta
            </NeoInnerTitle>
            <NeoColumn md="6">
                <ul>
                    <li>
                        <strong>resultado</strong> - Resultado de la validación
                        <ul>
                            <li>opciones: <i>valido</i>, <i>invalido</i>, <i>riesgoso</i>, <i>desconocido</i></li>
                        </ul>
                    </li>
                    <li>
                        <strong>razon</strong>
                        - Breve explicación del resultado
                    </li>
                    <li>
                        <strong>error</strong>
                        - En caso que la petición no haya podido ser procesada correctamente
                        <ul>
                            <li>opciones: <i>true</i> ó no definido</li>
                        </ul>
                    </li>
                </ul>
            </NeoColumn>
            <NeoColumn md="6">
                <center>Ejemplo de resultado de validación:</center>
                <SyntaxHighlighter language="json" style={nightOwl}>
                    {JSON.stringify(ejemploPeticion.respuesta.valida, null, 2)}
                </SyntaxHighlighter>
                <SyntaxHighlighter language="json" style={nightOwl}>
                    {JSON.stringify(ejemploPeticion.respuesta.riesgoso, null, 2)}
                </SyntaxHighlighter>
                <center>Ejemplo de error:</center>
                <SyntaxHighlighter language="json" style={nightOwl}>
                    {JSON.stringify(ejemploPeticion.respuesta.error, null, 2)}
                </SyntaxHighlighter>
            </NeoColumn>
            <NeoColumn md="12">
                <NeoInnerTitle>
                    Respuestas de la validación
                </NeoInnerTitle>
                <ul>
                    <li><strong>valido</strong>
                        <ul>
                            <li><strong>Válido</strong> - El correo electrónico ha sido aceptado por el servidor SMTP.</li>
                        </ul>
                    </li>
                    <li><strong>invalido</strong>
                        <ul>
                            <li><strong>Rechazado</strong> - La dirección de correo fue rechazada por el servidor SMTP.</li>
                            <li><strong>Dominio inválido</strong> - El dominio para el correo no existe.</li>
                            <li><strong>Correo inválido</strong> - No es un correo válido.</li>
                            <li><strong>SMTP inválido</strong> - El servidor SMTP regresa una respuesta inválida o inesperada.</li>
                            <li><strong>Error en escritura</strong> - El correo no tiene la sintaxis apropiada.</li>
                            <li><strong>En lista de bloqueo</strong> - El correo está marcado como bloqueado y no fué validado.</li>
                            <li><strong>No acepta correos</strong> - Estos dominios solo envían correo y no lo aceptan.</li>
                            <li><strong>Sintaxis inválida</strong> - Correos electrónicos que fallan en los protocolos de sintaxis RFC.</li>
                            <li><strong>Ha superado la cuota de buzón</strong> - Estos correos electrónicos excedieron su cuota de espacio y no aceptan correos electrónicos.</li>
                            <li><strong>Dirección de correo no encontrada</strong> - Estas direcciones de correo electrónico son válidas en sintaxis, pero no existen.</li>
                            <li><strong>Sin entrada DNS</strong> - Estos correos electrónicos son válidos en sintaxis, pero el dominio no tiene ningún registro en DNS o tiene registros DNS incompletos.</li>
                            <li><strong>Posible error de escritura</strong> - Estos son correos electrónicos de dominios populares comúnmente mal escritos.</li>
                            <li><strong>Dirección IP no enrutable</strong> - Estos dominios de correo electrónico apuntan a una dirección IP no enrutable.</li>
                        </ul>
                    </li>
                    <li><strong>riesgoso</strong>
                        <ul>
                            <li><strong>Baja calidad</strong> - El correo tiene problemas de calidad, puede ser una dirección de bajo valor.</li>
                            <li><strong>Baja entregabilidad</strong> - El correo parece ser valido, pero la entregabilidad no está garantizada.</li>
                            <li><strong>En lista de bloqueo global</strong> - Estos correos electrónicos se encuentran en muchas listas populares de supresión global (GSL), consisten en denunciantes de ISP conocidos, denunciantes directos, direcciones compradas, dominios que no envían correo y litigantes conocidos.</li>
                            <li><strong>Posible trampa</strong> - El correo electrónico contienen palabras clave que pueden correlacionarse con posibles trampas de spam.</li>
                            <li><strong>Dirección de rol</strong> - Estos correos electrónicos pertenecen a un puesto o grupo de personas como ventas@, info@ y contacto@.</li>
                            <li><strong>Dirección de rol y acepta todo</strong> - Estos correos electrónicos se basan en roles y también pertenecen a un dominio "Atrapa todo".</li>
                            <li><strong>Dirección desechable</strong> - Correo electrónico temporal.</li>
                            <li><strong>Dirección tóxica</strong> - Correo electrónico de spam, abuso o creado por un bot.</li>
                        </ul>
                    </li>
                    <li><strong>desconocido</strong>
                        <ul>
                            <li><strong>Sin conexión</strong> - No se ha podido conectar con el servidor SMTP.</li>
                            <li><strong>No responde</strong> - La sesión con el servidor SMTP ha caducado.</li>
                            <li><strong>SMTP no disponible</strong> - El servidor SMTP no estaba disponible para procesar la petición.</li>
                            <li><strong>Error inesperado</strong> - Ha ocurrido un error inesperado.</li>
                            <li><strong>Temporalmente fuera de servicio</strong> - Ha existido un error en el servicio y el correo no ha sido validado.</li>
                            <li><strong>Filtro anti-spam activado</strong> - Estos correos electrónicos tienen implementados sistemas anti-spam que impide validarlos.</li>
                            <li><strong>Ocurrió una excepcion</strong> - Estos correos electrónicos provocaron una excepción al validar.</li>
                            <li><strong>Falló la conexión SMTP</strong> - Estos correos electrónicos pertenecen a un servidor de correo que no permite una conexión SMTP.</li>
                            <li><strong>Desconexión del servidor</strong> - Estos correos electrónicos pertenecen a un servidor de correo que se desconecta inmediatamente al conectarse.</li>
                            <li><strong>Temporalmente no validable</strong> - Correos electrónicos en los que temporalmente no se pueden validar.</li>
                            <li><strong>El servidor SMTP no responde</strong> - Estos correos electrónicos pertenecen a un servidor de correo que no responde a los comandos de correo.</li>
                            <li><strong>El servidor SMTP no está disponible</strong> - Estos correos electrónicos pertenecen a un servidor de correo que devuelve un error temporal.</li>
                            <li><strong>Tiempo de espera excedido</strong> - Estos correos electrónicos pertenecen a un servidor de correo que responde extremadamente lento.</li>
                        </ul>
                    </li>
                </ul>
            </NeoColumn>
            <NeoColumn md="12">
                <NeoInnerTitle>
                    Respuestas de error
                </NeoInnerTitle>
                <ul>
                    <li><strong>Error</strong>
                        <ul>
                            <li>
                                <strong>La API key no es válida</strong> -
                                La llave API no existe o está deshabilitada.
                            </li>
                            <li>
                                <strong>Saldo insuficiente</strong> -
                                No cuentas con saldo para realizar la validación, contácta a tu DN asignado.
                            </li>
                            <li>
                                <strong>Es necesario incluir la API Key</strong> -
                                No existe este dato en la petición.
                            </li>
                            <li>
                                <strong>No hay campo a validar</strong>
                                No existe este dato en la petición.
                            </li>
                            <li>
                                <strong>Sólo es permitido POST</strong> -
                                La petición se envió usando otro método diferente.
                            </li>
                            <li>
                                <strong>El IP esta fuera de rango del filtro</strong> -
                                Está establecido el filtro por IP para peticiones y la IP del cliente no tiene acceso.
                            </li>
                        </ul>
                    </li>
                </ul>
            </NeoColumn>
        </NeoCard>
    </>)
}

export default ApiKeyDocumentacion;