import React from "react";
import { ToggleButton } from "primereact/togglebutton";


const BaseButtonToggle = (props) => {
    return (<>
        <ToggleButton {...props}
            className={`
             ${props.custom ? props.custom : ""}
             ${props.extra ? props.extra : ""}`} />
    </>);
};

export default BaseButtonToggle;