import { TabView } from 'primereact/tabview';

const BaseTabView = (props) => {
    return (
        <TabView {...props}
        className={`${props.custom ? props.custom : ""} ${props.extra ? props.extra : ""}`}>
            {props.children}
        </TabView>
    );
}

export default BaseTabView;