import React from "react";
import { Switch } from "react-router-dom";
import RuterRender from './RuterRender';
import PaginaBase from "../layouts/PaginaBase";
import Tablero from "../views/administracion/Tablero";
import AdministracionGeneral from "../views/administracion/AdministracionGeneral";
import Clientes from "../views/administracion/Clientes";
import Validaciones from "components/views/administracion/Validaciones";
import RutasAdministracionClientes from "./RutasAdministracionClientes";
import RUTAS from "./Rutas.json"
import { useCliente } from "hooks";
import useUsuario from "hooks/useUsuario";

const RutasAdministracion = () => {
    const cliente = useCliente();
    const usuario = useUsuario();

    return (
        <PaginaBase>
            <Switch>

                <RuterRender
                    path={RUTAS.admin.tablero}
                    component={Tablero} />

                <RuterRender
                    path={RUTAS.admin.clientes.lista}
                    component={Clientes} />

                <RuterRender
                    path={RUTAS.admin.validaciones}
                    component={Validaciones} />

                <RuterRender
                    exact={false}
                    path={RUTAS.admin.clientes.base}
                    redirect={cliente.hayCliente}
                    redirectValue={false}
                    toPath={RUTAS.admin.clientes.lista}
                    component={RutasAdministracionClientes} />

                <RuterRender
                    path={RUTAS.admin.general}
                    component={AdministracionGeneral}
                    redirect={usuario.esAdmin}
                    redirectValue={false}
                    toPath={RUTAS.admin.tablero} />

            </Switch>
        </PaginaBase>
    );
};

export default RutasAdministracion;