import React, { useEffect, useState } from "react";
import NeoTitleChart from "../title/NeoTitleChart.base";
import NeoColumn from "../layout/NeoColumn.base";
import NeoDataThird from "./NeoDataThird.base";

const NeoDataSection = (props) => {
    const [data, setData] = useState();
    const [title, setTitle] = useState();

    useEffect(() => {
        props.data && setData(props.data);
        props.title && setTitle(props.title);
    }, [props])

    return (<>
        <NeoTitleChart extra="p-text-left">{title}</NeoTitleChart>
        <NeoColumn extra="p-d-flex p-jc-center p-flex-column">
            <NeoColumn md="12">
                {
                    data && data.map((e, index) => (
                        <NeoDataThird
                            label={e[props.label]}
                            fact={e[props.fact]}
                            key={index} />
                    ))
                }
            </NeoColumn>
        </NeoColumn>
    </>);
}

export default NeoDataSection;